import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { showMessage } from "./common.slice";
import axios from "axios";
import { getSessionData } from "utils/common";
import { exportToExcel, generatePDF } from "utils/reportGenerator";

const initialState = {
  isTradeEntryUpdated: false,
  // tradeEntryList: new Map(),
  tradeEntryList: [],
  tradeEntryList2: new Map(),
  tradeEntryLoading: false,
  selectedUserAccountInTradeEntry: {},
  totalRecordTradeEntry: 0,
  startApiCallTradeEntry: true,
  testGenerateBillData: [],
  currentPageTradeEntry: 1,
  isBillCreationUpdated: false,
  isBillCreationLoading: false,
  isBhavCopyUpdatLoading: false,
  isLtpUpdatLoading: false,
  filterTradeEntryObj: {
    clientCode: null,
    clientName: null,
    segment: null,
    script: null,
    rate: null,
    orderType: null,
    nbc: null,
    date: moment().format('YYYY-MM-DD'),
  },
  isTradeUtilityUpdated: false,
  isTradeEntryLoading: false,
  selectedTradeEntryDate: new Date(),
  addBhavCopyDataUpdated: false,
  bhavCopyDataLoading: false,
  totalRecordBhavCopyData: 0,
  currentPageBhavCopy: 1,
  bhavCopySearchText: '',
  bhavCopyData: [],
  tradeInspectionData: [],
  tradeInspectionLoading: false,
  searchTextTradeInspection: '',
  currentPageTradeInspection: 1,
  totalRecordTradeInspection: 0,
};

export const addTransactionTradeEntry = createAsyncThunk(
  "/transaction/tradeEntry/add",
  ({ data, liveData }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, userIpFinal } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.id = data.id != undefined ? data.id : 0;
      newFinalObject.userId =
        data.client && Object.keys(data.client).length > 0
          ? Number(data.client.value)
          : 0;
      newFinalObject.userIp = userIpFinal;

      newFinalObject.segmentId = data.segment.value;
      newFinalObject.orderType = data.buySell == false ? 1 : 2;
      newFinalObject.script =
        data.script && Object.keys(data.script).length > 0
          ? data.script.label
          : 0;
      newFinalObject.valanId =
        data.valan && Object.keys(data.valan).length > 0
          ? data.valan.value.id
          : 0;
      newFinalObject.displayName = liveData.displayName;

      newFinalObject.subBrokerId = data.subBrokerId
        ? data.subBrokerId.value
        : null;
      newFinalObject.tradeOption =
        data.tradeType == "NRM" ? 1 : data.tradeType == "CF" ? 2 : 3;
      newFinalObject.tradeType =
        data.tradeType == "NRM" ? 1 : data.tradeType == "CF" ? 2 : 3;
      newFinalObject.isCheckHL = data.isCheckH;
      newFinalObject.date = moment(data.date).format();
      newFinalObject.deviceType = 1;
      newFinalObject.lot = data.lot === "" ? 0 : Number(data.lot);
      newFinalObject.quantity = data.qty === "" ? 0 : Number(data.qty);
      newFinalObject.rate = data.rate === "" ? 0 : data.rate;
      newFinalObject.lowPrice = liveData.low;
      newFinalObject.mediumPrice = liveData.lastTradedPrice;
      newFinalObject.ltp = data.ltp ? data.ltp : 0;
      newFinalObject.bid = data.bid ? data.bid : 0;
      newFinalObject.ask = data.ask ? data.ask : 0;
      newFinalObject.highPrice = liveData.high;
      newFinalObject.symbol = liveData.symbol;
      newFinalObject.executionType = 6;
      axios
        .post("trade/trade-entry", newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const addTransactionTradeUtility = createAsyncThunk(
  "/transaction/trade-utility/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.segmentId =
        data.segment && data.segment.value ? data.segment.value : 0;
      newFinalObject.valanId =
        data.valan && data.valan.value ? data.valan.value : 0;
      newFinalObject.startDate = data.startDate ? data.startDate : "";
      newFinalObject.endDate = data.endDate ? data.endDate : "";
      if (data.utilityOption == "1") {
        newFinalObject.option = data.utilityOption
          ? Number(data.utilityOption)
          : 0;
        newFinalObject.exDate = data.expiryDate ? moment(data.expiryDate).format('YYYY-MM-DD HH:mm:ss'): "";
        newFinalObject.sourceRate = data.sourceRate
          ? Number(data.sourceRate)
          : 0;
        newFinalObject.userId = userId;
      }
      if (data.utilityOption == "2") {
        newFinalObject.option = data.utilityOption
          ? Number(data.utilityOption)
          : 0;
        newFinalObject.exDate = null;
        newFinalObject.sourceRate = 0;
        newFinalObject.userId = data.userId.value;
      }
      axios
        .post("trade/trade-utility", newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const updloadBhavCopy = createAsyncThunk(
  "/transaction/upload/doc",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let newData = new FormData();
      newData.append("files", data.file);
      axios
        .post(
          `reports/import-bhavCopy-CSV-File?segmentId=${data.segmentId.value}`,
          newData
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const addBhavCopyData = createAsyncThunk(
  "/transaction/add-bhav-copy-data",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const obj = {
        id: data.id,
        exchange: data.segment.label,
        segmentid: data.segment.value,
        expirY_DT: data?.expirY_DT ? moment(data.expirY_DT).format() : moment(data.expiryDate).format(),
        date: data.date,
        closerate: data.closerate,
        symbol: data.symbol,
        script: data.script,
      }
      axios
        .post('script/bhav-copy/save-bhav-copy', obj)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const deleteBhavCopyData = createAsyncThunk(
  "/transaction/add-bhav-copy-data",
  (id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('script/bhav-copy/delete-bhav-copy-data', id)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const updloadBhavCopyLtp = createAsyncThunk(
  "/transaction/upload/ltp",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `reports/get-offline-ltpdata-from-bhavCopy-CSV-File/${data.segmentId.value}`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getBhavCopyData = createAsyncThunk(
  "/transaction/get-bhav-copy-data",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { segmentId, pageNo, pageSize, appendData } = data;
      axios
        .get(`script/bhav-copy/get-bhav-copy-data/${segmentId}/${pageNo}/${pageSize}`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getTradeInspectionData = createAsyncThunk(
  "/inspect/get-trade-inspection-data",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const { pageNo, pageSize, searchText } = data
      let clientId = data?.userId?.value ? Number(data?.userId?.value) : userId
      let segmentId = data?.segmentId?.value ? Number(data?.segmentId?.value) : ''
      let symbol = data?.symbol?.value ? data?.symbol?.value : ''
      let startDate = data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : ''
      let endDate = data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : ''
      let amount = data.amount ? Number(data.amount) : ''
      let appendData = data?.appendData
      let param = ''
      if (segmentId && symbol && searchText) {
        param = `?segmentId=${segmentId}&Symbol=${symbol}&searchText=${searchText}`
      } else if (segmentId && symbol) {
        param = `?segmentId=${segmentId}&Symbol=${symbol}`
      } else if (segmentId && searchText) {
        param = `?segmentId=${segmentId}&searchText=${searchText}`
      } else if (symbol && searchText) {
        param = `?Symbol=${symbol}&searchText=${searchText}`
      } else if (segmentId) {
        param = `?segmentId=${segmentId}`
      } else if (symbol) {
        param = `?Symbol=${symbol}`
      } else if (searchText) {
        param = `?searchText=${searchText}`
      }
      axios
        .get(`/trade/get-trade-inspection-data/${clientId}/${pageNo}/${pageSize}/${amount}/${startDate}/${endDate}${param}`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const exportTradeInspectionReport = createAsyncThunk(
  "/inspect/export-trade-inspection-report",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const { pageNo, pageSize, searchText, isSubmit } = data
      let clientId = data?.userId?.value ? Number(data?.userId?.value) : userId
      let segmentId = data?.segmentId?.value ? Number(data?.segmentId?.value) : ''
      let symbol = data?.symbol?.value ? data?.symbol?.value : ''
      let startDate = data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : ''
      let endDate = data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : ''
      let amount = data.amount ? Number(data.amount) : ''
      let param = ''
      if (segmentId && symbol && searchText) {
        param = `?segmentId=${segmentId}&Symbol=${symbol}&searchText=${searchText}`
      } else if (segmentId && symbol) {
        param = `?segmentId=${segmentId}&Symbol=${symbol}`
      } else if (segmentId && searchText) {
        param = `?segmentId=${segmentId}&searchText=${searchText}`
      } else if (symbol && searchText) {
        param = `?Symbol=${symbol}&searchText=${searchText}`
      } else if (segmentId) {
        param = `?segmentId=${segmentId}`
      } else if (symbol) {
        param = `?Symbol=${symbol}`
      } else if (searchText) {
        param = `?searchText=${searchText}`
      }
      axios
        .get(`/trade/get-trade-inspection-data/${clientId}/${pageNo}/${pageSize}/${amount}/${startDate}/${endDate}${param}`)
        .then(({ data }) => {
          if (data.status == 200) {
            if (isSubmit == "PDF" && data.data.records.length > 0) {
              let aligh = { halign: "right", fontStyle: "bold" };
              let bgGray = { fillColor: [132, 132, 132], textColor: [255, 255, 255] };
              const calumnData = [
                {
                  title: "Trade No",
                  dataKey: "tradeNumber",
                },
                {
                  title: "Device",
                  dataKey: "deviceTypeName",
                  styles: aligh,
                },
                {
                  title: "O/T",
                  dataKey: "tradeTypeName",
                  styles: aligh,
                },
                {
                  title: "Trade Date",
                  dataKey: "tradeDate",
                  styles: aligh,
                },
                {
                  title: "Client",
                  dataKey: "client",
                  styles: aligh,
                },
                {
                  title: "Authority",
                  dataKey: "subBroker",
                  styles: aligh,
                },
                {
                  title: "Script",
                  dataKey: "script",
                  styles: aligh,
                },
                {
                  title: "Type",
                  dataKey: "orderTypeName",
                  styles: aligh,
                },
                {
                  title: "Lot",
                  dataKey: "lot",
                  styles: aligh,
                },
                {
                  title: "Qty",
                  dataKey: "quantity",
                  styles: aligh,
                },
                {
                  title: "BID/ASK",
                  dataKey: "biD_ASK",
                  styles: aligh,
                },
                {
                  title: "Rate",
                  dataKey: "rate",
                  styles: aligh,
                },
                {
                  title: "Trade Type",
                  dataKey: "tradeOptionName",
                  styles: aligh,
                },
                {
                  title: "User",
                  dataKey: "accountTypeName",
                  styles: aligh,
                },
                {
                  title: "User Ip",
                  dataKey: "userIp",
                  styles: aligh,
                },
                {
                  title: "Add Time",
                  dataKey: "addTime",
                  styles: aligh,
                },
              ];
              let exportData = {
                title: "Trade Inspection",
                FileName: "Trade Inspection",
                exportColumn: calumnData,
                arrayName: data.data.records,
                theme: "grid",
                headStyles: {
                  bgGray,
                },
                date: moment().format("DD-MM-YYYY"),
                reportType: "TradeInspection",
              };
              generatePDF(exportData);
            }
            else if (isSubmit == "EXCEL" && data.data.records.length > 0) {
              let finalData =
                data.data &&
                data.data.records.map(
                  (item) => {
                    return {
                      order_no: item.id,
                      trade_no: item.tradeNumber,
                      segment: item.segmentName,
                      device_type: item.deviceTypeName,
                      client_code: item.client.split("-")[0],
                      client_name: item.client.split("-")[1],
                      authority: item.subBroker,
                      script_name: item.script,
                      ex_date: moment(item.expiryDate).format("DD-MMM-YYYY"),
                      buysell: item.orderTypeName,
                      lot: item.lot,
                      qty: item.quantity,
                      bid_ask: item.biD_ASK,
                      rate: item.rate,
                      trade_date: moment(item.tradeDate).format("DD-MMM-YYYY"),
                      trade_time: item.tradeTime,
                      userIp: item.userIp,
                      trade_type_name: item.tradeTypeName,
                      trade_type: item.tradeOptionName,
                      user: item.accountTypeName
                    };
                  }
                );
              exportToExcel({
                apiData: finalData,
                fileName: "TradeInspectionReport",
              });
              resolve({ data: data.data });
            }

            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getTransactionTradeEntryList = createAsyncThunk(
  "/transaction/tradeEntry",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { filterTradeEntryObj, pageNo, pageSize, appendData } = data;
      let { userId } = getSessionData();
      axios
        .post(`trade/get-trades-by-user`, {
          userId,
          pageNo,
          pageSize,
          ...filterTradeEntryObj,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const billCreation = createAsyncThunk(
  "bill-create",
  ({ data, submitType }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let newFinalObject = {};

      let { userId } = getSessionData();
      newFinalObject.createdBy = userId;
      newFinalObject.segmentId =
        data.segmentId && data.segmentId.value !== "ALL"
          ? data.segmentId.value
          : null;
      newFinalObject.cfValanId = data.cfValan ? data.cfValan.value : null;
      newFinalObject.bfValanId = data.bfValan ? data.bfValan.value : null;
      newFinalObject.cfDate = moment(data.cfDate).format('YYYY-MM-DD HH:mm:ss');
      newFinalObject.bfDate = moment(data.bfDate).format('YYYY-MM-DD HH:mm:ss');
      newFinalObject.sourceRate = data.sourceRate;
      newFinalObject.refreshBrkndShr = data.isRefreshBroAndSharing;
      axios
        .post(
          `${submitType == 1 ? "trade/test-weekly-bill-creation" : submitType == 2 ? "trade/weekly-bill-creation"
            : submitType == 3 ? "trade/weekly-revert-bill"
              : "trade/weekly-move-trade-to-oldtrade"
          }`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            if (submitType == 1 && data?.data?.length > 0) {
              resolve({ submitType: 1, data: data?.data });
            } else {
              resolve(data);
            }
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
          } else {
            dispatch(showMessage({ message: data.message }));
            reject();
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deletTradeEntry = createAsyncThunk(
  "/brokerage/deletTradeEntry",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      //user info
      let { id } = data;
      axios
        .delete("trade/delete-trade", { data: data })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setStartPageTradeEntry: (state, action) => {
      state.currentPageTradeEntry = action.payload;
    },
    setIsBillCreationUpdated: (state, action) => {
      state.isBillCreationUpdated = action.payload;
    },
    setFilterTradeEntryObj: (state, action) => {
      state.filterTradeEntryObj = action.payload;
    },
    setTradeEntryList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list.forEach((x) => map.set(x?.id, x));
      state.tradeEntryList2 = map;
      // state.tradeEntryList = action.payload;
    },
    setIsTradeUtilityUpdated: (state, action) => {
      state.isTradeUtilityUpdated = action.payload;
    },
    setSelectedTradeEntryDate: (state, action) => {
      state.selectedTradeEntryDate = action.payload;
    },
    setIsTradeEntryUpdated: (state, action) => {
      state.isTradeEntryUpdated = action.payload;
    },
    setTestGenerateBillData: (state, action) => {
      state.testGenerateBillData = action.payload;
    },
    setSelectedUserAccountIntradeEntry: (state, action) => {
      state.selectedUserAccountInTradeEntry = action.payload;
    },
    setAddBhavCopyDataUpdated: (state, action) => {
      state.addBhavCopyDataUpdated = action.payload;
    },
    setBhavCopyDataLoading: (state, action) => {
      state.bhavCopyDataLoading = action.payload;
    },
    setTotalRecordBhavCopyData: (state, action) => {
      state.totalRecordBhavCopyData = action.payload;
    },
    setCurrentPageBhavCopy: (state, action) => {
      state.currentPageBhavCopy = action.payload;
    },
    setBhavCopySearchText: (state, action) => {
      state.bhavCopySearchText = action.payload;
    },
    setBhavCopyData: (state, action) => {
      state.bhavCopyData = action.payload;
    },
    setTradeInspectionLoading: (state, action) => {
      state.tradeInspectionLoading = action.payload;
    },
    setSearchTextTradeInspection: (state, action) => {
      state.searchTextTradeInspection = action.payload;
    },
    setCurrentPageTradeInspection: (state, action) => {
      state.currentPageTradeInspection = action.payload;
    },
    setTotalRecordTradeInspection: (state, action) => {
      state.totalRecordTradeInspection = action.payload;
    },
    setTradeInspectionData: (state, action) => {
      state.tradeInspectionData = action.payload;
    },
  },
  extraReducers: {
    [getTransactionTradeEntryList.pending]: (state, action) => {
      state.tradeEntryLoading = true;
    },
    [getTransactionTradeEntryList.rejected]: (state, action) => {
      state.tradeEntryList2 = new Map();
      state.tradeEntryLoading = false;
    },
    [getTransactionTradeEntryList.fulfilled]: (state, action) => {
      let map = new Map();
      let list = []
      if (action.payload.appendData) {
        list = [...state.tradeEntryList2, ...action.payload.data.records] || [];
      } else {
        list = [...action.payload.data.records] || [];
      }
      list = _.map(list, (o) =>
        _.extend(
          { isDelete: o.isDelete ? o.isDelete : false },
          o
        ))
      list.forEach((x) => map.set(x?.id, x))
      state.tradeEntryList2 = map;
     /*  let tradeEntryListData = [];
      if (action.payload.appendData) {
        tradeEntryListData = [
          ...state.tradeEntryList,
          ...action.payload.data.records,
        ];
      } else {
        tradeEntryListData = action.payload.data.records;
      }
      state.tradeEntryList = tradeEntryListData.map((item) => {
        return { ...item, isDelete: false };
      }); */
      state.totalRecordTradeEntry = action.payload.data.totalRecords;
      state.startApiCallTradeEntry = false;

      state.tradeEntryLoading = false;
    },
    [getBhavCopyData.pending]: (state, action) => {
      state.bhavCopyDataLoading = true;

    },
    [getBhavCopyData.rejected]: (state, action) => {
      state.bhavCopyDataLoading = false;
    },
    [getBhavCopyData.fulfilled]: (state, action) => {
      let bhavCopyListData = [];
      if (action.payload.appendData) {
        bhavCopyListData = [
          ...state.bhavCopyData,
          ...action.payload.data.records,
        ];
      } else {
        bhavCopyListData = action.payload.data.records;
      }
      state.bhavCopyData = bhavCopyListData
      state.totalRecordBhavCopyData = action.payload.data.totalRecords;
      state.bhavCopyDataLoading = false;
    },
    [getTradeInspectionData.pending]: (state, action) => {
      state.tradeInspectionLoading = true;

    },
    [getTradeInspectionData.rejected]: (state, action) => {
      state.tradeInspectionLoading = false;
    },
    [getTradeInspectionData.fulfilled]: (state, action) => {
      let tradeInspectionList = [];
      if (action.payload.appendData) {
        tradeInspectionList = [
          ...state.tradeInspectionData,
          ...action.payload.data.records,
        ];
      } else {
        tradeInspectionList = action.payload.data.records;
      }
      state.tradeInspectionData = tradeInspectionList
      state.totalRecordTradeInspection = action.payload.data.totalRecords;
      state.tradeInspectionLoading = false;
    },

    [addTransactionTradeEntry.pending]: (state, action) => {
      state.isTradeEntryUpdated = false;
      state.isTradeEntryLoading = true;
    },
    [addTransactionTradeEntry.rejected]: (state, action) => {
      state.isTradeEntryUpdated = false;
      state.isTradeEntryLoading = false;
    },
    [addTransactionTradeEntry.fulfilled]: (state, action) => {
      state.isTradeEntryUpdated = true;
      state.isTradeEntryLoading = false;
    },

    [billCreation.pending]: (state, action) => {
      state.isBillCreationUpdated = false;
      state.isBillCreationLoading = true;
    },
    [billCreation.rejected]: (state, action) => {
      state.isBillCreationUpdated = false;
      state.isBillCreationLoading = false;
    },
    [billCreation.fulfilled]: (state, action) => {
      if (action?.payload?.submitType) {
        state.testGenerateBillData = action?.payload?.data?.length > 0 ? action.payload.data : []
      }
      state.isBillCreationUpdated = true;
      state.isBillCreationLoading = false;
      // if (action.payload.appendData) {
      //   state.tradeEntryList = [
      //     ...state.tradeEntryList,
      //     ...action.payload.data.records,
      //   ];
      // } else {
      //   state.tradeEntryList = action.payload.data.records;
      // }
      // state.totalRecordTradeEntry = action.payload.data.totalRecords;
      // state.startApiCallTradeEntry = false;

      // state.tradeEntryLoading = false;
    },
    [updloadBhavCopy.pending]: (state, action) => {
      state.isBhavCopyUpdatLoading = true;
    },
    [updloadBhavCopy.rejected]: (state, action) => {
      state.isBhavCopyUpdatLoading = false;
    },
    [updloadBhavCopy.fulfilled]: (state, action) => {
      state.isBhavCopyUpdatLoading = false;
    },
    [addBhavCopyData.pending]: (state, action) => {
      state.addBhavCopyDataUpdated = false
      state.bhavCopyDataLoading = false;
    },
    [addBhavCopyData.rejected]: (state, action) => {
      state.addBhavCopyDataUpdated = false
      state.bhavCopyDataLoading = false;
    },
    [addBhavCopyData.fulfilled]: (state, action) => {
      state.addBhavCopyDataUpdated = true
      state.bhavCopyDataLoading = true;
    },
    [deleteBhavCopyData.pending]: (state, action) => {
      state.addBhavCopyDataUpdated = false
      state.bhavCopyDataLoading = false;
    },
    [deleteBhavCopyData.rejected]: (state, action) => {
      state.addBhavCopyDataUpdated = false
      state.bhavCopyDataLoading = false;
    },
    [deleteBhavCopyData.fulfilled]: (state, action) => {
      state.addBhavCopyDataUpdated = true
      state.bhavCopyDataLoading = true;
    },
    [updloadBhavCopyLtp.pending]: (state, action) => {
      state.isLtpUpdatLoading = true;
    },
    [updloadBhavCopyLtp.rejected]: (state, action) => {
      state.isLtpUpdatLoading = false;
    },
    [updloadBhavCopyLtp.fulfilled]: (state, action) => {
      state.isLtpUpdatLoading = false;
    },

    [addTransactionTradeUtility.pending]: (state, action) => {
      state.isTradeUtilityUpdated = false;
    },
    [addTransactionTradeUtility.rejected]: (state, action) => {
      state.isTradeUtilityUpdated = false;
    },
    [addTransactionTradeUtility.fulfilled]: (state, action) => {
      state.isTradeUtilityUpdated = true;
    },

    [deletTradeEntry.pending]: (state, action) => {
      state.isTradeEntryUpdated = false;
    },
    [deletTradeEntry.rejected]: (state, action) => {
      state.isTradeEntryUpdated = false;
    },
    [deletTradeEntry.fulfilled]: (state, action) => {
      state.isTradeEntryUpdated = true;
    },
  },
});

export const {
  setStartPageTradeEntry,
  setIsBillCreationUpdated,
  setFilterTradeEntryObj,
  setTradeEntryList,
  setIsTradeUtilityUpdated,
  setSelectedTradeEntryDate,
  setIsTradeEntryUpdated,
  setTestGenerateBillData,
  setSelectedUserAccountIntradeEntry,
  setBhavCopyDataLoading,
  setAddBhavCopyDataUpdated,
  setTotalRecordBhavCopyData,
  setCurrentPageBhavCopy,
  setBhavCopySearchText,
  setBhavCopyData,
  setTradeInspectionLoading,
  setSearchTextTradeInspection,
  setCurrentPageTradeInspection,
  setTotalRecordTradeInspection,
  setTradeInspectionData
} = transactionSlice.actions;

export default transactionSlice.reducer;
