import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import _ from "lodash";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { mathRound } from "./common";

const totalCount = (data, key) => {
  return data
    .reduce((sum, cuurent) => {
      if (Object.keys(cuurent).includes(key)) {
        return sum + Number(cuurent[key].content);
      } else {
        return sum;
      }
    }, 0)
    .toFixed(2);
};
const totalCountForBro = (data, key) => {
  return Math.round( data
    .reduce((sum, cuurent) => {
      if (Object.keys(cuurent).includes(key)) {
        return sum + Number(cuurent[key].content);
      } else {
        return sum;
      }
    }, 0))
};

const totalCountNottoFixed = (data, key) => {
  return data.reduce((sum, cuurent) => {
    if (Object.keys(cuurent).includes(key)) {
      return sum + Number(cuurent[key].content);
    } else {
      return sum;
    }
  }, 0);
};
export const billSimpleData = ["23", "29", "210", "211", "212"];

export const generatePDF = (dataMain) => {
  let sizePage = "p";
  if (
    dataMain.reportType == "22" ||
    dataMain.reportType == "24" ||
    dataMain.reportType == "31" ||
    dataMain.reportType == "312" ||
    dataMain.reportType == "32" ||
    dataMain.reportType == "323" ||
    dataMain.reportType == "313" ||
    dataMain.reportType == "OrderBook" || 
    dataMain.reportType == "TradeInspection" ||
    dataMain.reportType == "Summary" ||
    dataMain.reportType == "SameIpTrade"
  ) {
    sizePage = "l";
  }
  let page_size="a4"; 
  if( dataMain.reportType == "Summary"){
    page_size="a2"; 
  }
  const doc = new jsPDF(sizePage, "pt", page_size);
  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  const align = { halign: "right" };
  const textBlue = { textColor: [62, 62, 180], ...align };
  const textRed = { textColor: [245, 54, 92], ...align };
  const col = dataMain.exportColumn;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const topHight = 25;
  doc.setFontSize(15);
  doc.setTextColor(45);
  doc.text("Golden Money", pageWidth / 2, topHight, { align: "center" });
  doc.line(35, topHight + 10, pageWidth - 35, topHight + 10);
  doc.setFontSize(10);
  doc.setTextColor(40);
  dataMain.title && doc.text(dataMain.title, 40, topHight + 25);
  dataMain.date && doc.text(dataMain.date, pageWidth / 2, topHight + 25);
  dataMain.fromTo && doc.text(dataMain.fromTo, pageWidth - 180, topHight + 25);
  (dataMain.title || dataMain.date || dataMain.fromTo) &&
    doc.line(35, topHight + 34, pageWidth - 35, topHight + 34);
  doc.autoTable({
    horizontalPageBreak: dataMain.rowPageBreak,
    theme: dataMain.theme,
    rowPageBreak: true,
    styles: { text: "white" },
    startY: topHight + 25,
    // showHead: dataMain.showHead,
    headStyles: dataMain.headStyles,
  });
  const ledgerBillColum = [
    {
      title: "Date",
      dataKey: "date",
    },
    {
      title: "Doc No",
      dataKey: "docNo",
      styles: align,
    },
    {
      title: "Particulars",
      dataKey: "particulars",
      styles: align,
    },
    {
      title: "Debit",
      dataKey: "debit",
      styles: align,
    },
    {
      title: "Credit",
      dataKey: "credit",
      styles: align,
    },
    {
      title: "Balance",
      dataKey: "balance",
      styles: align,
    },
    {
      title: "Description",
      dataKey: "description",
      styles: align,
    },
  ];
  let mtmtTotal = 0
  if (dataMain.reportType == "11" || dataMain.reportType == "13") {
    dataMain.arrayName.forEach((element) => {
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let tofixColm = ["buyAvgPrice", "netQty", "sellAvgPrice", "mtm", "ltp"];
        let data =
          dataMain.reportType == "11"
            ? _.omit(item, ["userId", "clientName"])
            : _.omit(item, ["userId", "script"]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (i !== 0) {
            newObj1.styles = align;
          }
          if (itemData[0] == "netQty" || itemData[0] == "mtm") {
            itemData[1] >= 0
              ? (newObj1.styles = textBlue)
              : (newObj1.styles = textRed);
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });
      let isScriptWise = dataMain.reportType == "11" ? "script" : "clientName";
      doc.autoTable({
        head: [
          {
            [isScriptWise]: {
              dataKey: dataMain.reportType == "11" ? "script" : "clientName",
              title: element.name,
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: totalCount(rowData, "totalBuyQty"),
              colSpan: 2,
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "totalSellQty"),
              colSpan: 2,
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "netQty"),
              colSpan: 2,
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "mtm"),
              colSpan: 2,
              styles:
                totalCount(rowData, "mtm") > 0
                  ? dataMain.footerColorBlue
                  : dataMain.footerColorRed,
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType === "12") {
    dataMain.arrayName.forEach((element, index) => {
      let tofixColm = ["beAvg", "mtm", "ltp"];
      let valueTotal = 0;
      let beAvgTotal = 0;
      let posQtyTotal = 0;
      let mtmTotal = 0;
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let netQty = 0;
        let beAvg = 0;
        let finalValue = 0;
        Object.entries(
          _.pick(item, ["script", "netQty", "beAvg", "ltp", "mtm"])
        ).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (i !== 0) {
            newObj1.styles = align;
          }
          if (itemData[0] === "netQty") {
            itemData[1] >= 0
              ? (newObj1.styles = textBlue)
              : (newObj1.styles = textRed);
            netQty = itemData[1];
          } else if (itemData[0] === "mtm") {
            itemData[1] >= 0
              ? (newObj1.styles = textBlue)
              : (newObj1.styles = textRed);
            mtmTotal = mtmTotal + itemData[1];
          } else if (itemData[0] === "beAvg") {
            beAvg = itemData[1];
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        finalValue = netQty * beAvg;
        if (finalValue > 0) {
          posQtyTotal = posQtyTotal + finalValue;
        }
        if (finalValue < 0) {
          beAvgTotal = beAvgTotal + finalValue;
        }
        valueTotal = valueTotal + finalValue;
        return {
          ...finalObj,
          value: {
            content: parseFloat(finalValue).toFixed(2),
            styles: {...align, fontStyle: "bold"},
          },
        };
      });
      doc.autoTable({
        head: [
          {
            script: {
              dataKey: "script",
              title: element.name,
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: "",
              styles: {
                fontStyle: "bold",
                textColor: [0, 0, 0],
                halign: "right",
                fillColor: [242, 243, 165],
              },
            },
            {
              content: posQtyTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  posQtyTotal > 0
                    ? [62, 62, 180]
                    : posQtyTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: beAvgTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  beAvgTotal > 0
                    ? [62, 62, 180]
                    : beAvgTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: (posQtyTotal - beAvgTotal).toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  posQtyTotal - beAvgTotal > 0
                    ? [62, 62, 180]
                    : posQtyTotal - beAvgTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: valueTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  valueTotal > 0
                    ? [62, 62, 180]
                    : valueTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: mtmTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  mtmTotal > 0
                    ? [62, 62, 180]
                    : mtmTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "14") {
    dataMain.arrayName.forEach((element, index) => {
      let tofixColm = ["totalBuy", "totalSell"];
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let data = _.omit(item, ["userId", "clientName"]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (tofixColm.includes(itemData[0])) {
            newObj1.styles = align;
          }
          if(itemData[0] == "totalSell"){
            newObj1.styles = textRed
          }else if(itemData[0] == "totalBuy"){
            newObj1.styles = textBlue
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });
      doc.autoTable({
        head: [
          {
            script: {
              dataKey: "clientName",
              title: element.name,
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: "Total :",
              styles: {
                fontStyle: "bold",
                fillColor: [146, 143, 143],
                textColor: [255, 255, 255],
              },
            },
            {
              content: totalCount(rowData, "totalBuy"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "totalSell"),
              styles: dataMain.footerStyle,
            },
          ],
          [
            {
              content: "",
            },
            {
              content:
                (Number(totalCount(rowData, "totalBuy")) +
                Number(totalCount(rowData, "totalSell")))?.toFixed(2),
              colSpan: 2,
              styles: {
                ...dataMain.footerStyle,
                textColor: [62, 62, 180],
                fillColor: [242, 243, 165],
              },
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "15") {
    let tofixColm = ["totalBuy", "totalSell"];
    let rowData = dataMain.arrayName.map((item) => {
      let finalObj = {};
      item.netHodiyu = (item.totalBuy - item.totalSell).toFixed(2)
      let data = _.omit(item, ["userId", "script"]);
      Object.entries(data).forEach((itemData, i) => {
        let newObj1 = {};
        newObj1.content = tofixColm.includes(itemData[0])
          ? itemData[1].toFixed(2)
          : itemData[1];
        if (tofixColm.includes(itemData[0])) {
          newObj1.styles = align;
        }
        if(itemData[0] == "totalSell"){
          newObj1.styles = textRed
        }else if(itemData[0] == "totalBuy"){
          newObj1.styles = textBlue
        }else if(itemData[0] == "netHodiyu"){
            itemData[1] >= 0
              ? (newObj1.styles = textBlue)
              : (newObj1.styles = textRed);
        }
        newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
        finalObj[itemData[0]] = newObj1;
      });
      return finalObj;
    });
    doc.autoTable({
      head: [
        {
          accountCode: {
            dataKey: "accountCode",
            title: "CLIENT",
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        col,
      ],
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...rowData,
        [
          {
            content: "Total :",
            colSpan: 2,
            styles: { fontStyle: "bold", fillColor: [146, 143, 143], textColor: [255, 255, 255], halign: "right" },
          },
          {
            content: totalCount(rowData, "totalBuy"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(rowData, "totalSell"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(rowData, "netHodiyu"),
            styles: {...dataMain.footerStyle, fillColor: [242, 243, 165], textColor:
              totalCount(rowData, "netHodiyu") > 0
                ? [62, 62, 180]
                : totalCount(rowData, "netHodiyu") < 0
                  ? [245, 54, 92]
                  : [0, 0, 0],},
          },
        ],

        [
          {
            content: "Total Hodiyu:",
            colSpan: 2,
            styles: { fontStyle: "bold", textColor: [80, 82, 95], halign: "right" }
          },
          {
            content:
              (Number(totalCount(rowData, "totalBuy")) +
              Number(totalCount(rowData, "totalSell")))?.toFixed(2),
            colSpan: 2,
            styles: {
              ...dataMain.footerStyle,
              textColor: [62, 62, 180],
              fillColor: [242, 243, 165],
            },
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } else if (dataMain.reportType == "21") {
    dataMain.arrayName.forEach((item1, index) => {
      let mtmTotal = 0;
      item1.data.forEach((element, i) => {
        let tofixColm = ["rate", "netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let data = _.omit(item, ["userId", "clientName"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            if (itemData[0] == "netAmount") {
              mtmTotal = mtmTotal + itemData[1];
            }
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
                 : itemData[0] == "tradeDate"
                ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                : itemData[1];
            if (itemData[0] == "tradeDate") {
              newObj1.styles = {};
            } else if (itemData[0] == "sellQuantity") {
              newObj1.styles = textRed;
            } else if (itemData[0] == "buyQuantity") {
              newObj1.styles = textBlue;
            } else {
              newObj1.styles = align;
            }
            if (itemData[0] == "tradeOptionName") {
              if (itemData[1] == "LIVE")
                newObj1.styles = {
                  fontStyle: "bolditalic",
                  fontSize: 10,
                  halign: "right",
                };
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          return finalObj;
        });
        let nameHeader = {};
        const clientName = {
          tradeDate: {
            dataKey: "tradeDate",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }
        const scriptName = {
          tradeDate: {
            dataKey: "tradeDate",
            title: element.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if( i === 0 ){
          nameHeader.head = [clientName, scriptName, col]
        }else{
          nameHeader.head = [scriptName, col]
        }
        let footerTotal = {};
        if (i == item1.data.length - 1) {
          mtmtTotal=mtmtTotal+mtmTotal;
          footerTotal.foot = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: `Total bill Amount : ${mtmTotal.toFixed(2)}`,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: mtmTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }
        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: "Script wise total :",
                colSpan: 3,
                styles: {
                  fillColor: [146, 143, 143],
                  textColor: [255, 255, 255],
                  fontStyle: "bold",
                },
              },
              {
                content: totalCount(rowData, "buyQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "sellQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netAmount"),
                colSpan: 3,
                styles:
                  totalCount(rowData, "netAmount") > 0
                    ? dataMain.footerColorBlue
                    : dataMain.footerColorRed,
              },
            ],
          ],
          ...footerTotal,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
    });
  if(dataMain.arrayName.length > 1){
    doc.autoTable({
      body: [
        [
          {
            content: `Grand Total Amount: ${mtmtTotal.toFixed(2)}`,
            colSpan: 8,
            styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: mtmtTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
            },
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } 
  } else if (dataMain.reportType == "22" || dataMain.reportType == "24") {
    dataMain.arrayName.forEach((item1, index) => {
      let mtmTotal = 0;
      let ledgerBalance = 0;
      item1.data.forEach((element, i) => {
        let netAmountTotal = 0;
        let tofixColm = ["rate", "netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          ledgerBalance = item.ledgerBalance;
          let data = _.omit(item, ["userId", "clientName"]);
          if (item.orderType == 1) {
            Object.entries(data).forEach((itemData, i) => {
              let newObj1 = {};
              if (itemData[0] == "netAmount") {
                mtmTotal = mtmTotal + itemData[1];
                netAmountTotal = netAmountTotal + itemData[1];
              }
              newObj1.content = tofixColm.includes(itemData[0])
                ? itemData[1].toFixed(2)
                : itemData[0] == "tradeDate"
                  ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                  : itemData[1];
              if (itemData[0] == "tradeDate") {
                newObj1.styles = { halign: "center" };
              } else if (itemData[0] == "sellQuantity") {
                newObj1.styles = textRed;
              } else if (itemData[0] == "buyQuantity") {
                newObj1.styles = textBlue;
              } else {
                newObj1.styles = align;
              }
              newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
              if (itemData[0] == "tradeDate") {
                finalObj["tradeDate"] = newObj1;
              } else {
                finalObj[itemData[0] + "_b"] = newObj1;
              }
            });
          } else {
            Object.entries(data).forEach((itemData, i) => {
              let newObj1 = {};
              if (itemData[0] == "netAmount") {
                mtmTotal = mtmTotal + itemData[1];
                netAmountTotal = netAmountTotal + itemData[1];
              }
              newObj1.content = tofixColm.includes(itemData[0])
                ? itemData[1].toFixed(2)
                : itemData[0] == "tradeDate"
                  ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                  : itemData[1];
              if (itemData[0] == "tradeDate") {
                newObj1.styles = { halign: "center" };
              } else if (itemData[0] == "sellQuantity") {
                newObj1.styles = textRed;
              } else if (itemData[0] == "buyQuantity") {
                newObj1.styles = textBlue;
              } else {
                newObj1.styles = align;
              }
              newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
              if (itemData[0] == "tradeDate") {
                finalObj["tradeDate"] = newObj1;
              } else {
                finalObj[itemData[0] + "_s"] = newObj1;
              }
            });
          }
          return finalObj;
        });
        let nameHeader = {};
        let buySellHeader = {
          tradeFrom_b: {
            dataKey: "tradeFrom_b",
            title: "Buy",
            colSpan: 6,
            styles: {
              fillColor: [255, 255, 255],
              halign: "center",
              fontStyle: "bold",
              // cellMargin: 20,
              textColor: [62, 62, 180],
            },
          },
          tradeDate: {
            dataKey: "tradeDate",
            title: "Date",
            styles: {
              fillColor: [255, 255, 255],
              halign: "center",
              fontStyle: "bold",
              textColor: [62, 62, 180],
            },
          },

          tradeFrom_s: {
            dataKey: "tradeFrom_s",
            title: "Sell",
            colSpan: 6,
            styles: {
              fillColor: [255, 255, 255],
              halign: "center",
              fontStyle: "bold",
              textColor: [245, 54, 92],
            },
          },
        };
        const clientName = {
          tradeFrom_b: {
            dataKey: "tradeFrom_b",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }
        const scriptName = {
          tradeFrom_b: {
            dataKey: "tradeFrom_b",
            title: element.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if( i === 0 ){
          nameHeader.head = [clientName, scriptName, buySellHeader, col]
        }else{
          nameHeader.head = [scriptName, buySellHeader, col]
        }

        let footerTotal = {};
        if (i == item1.data.length - 1) {
          footerTotal.foot = [
            {
              tradeFrom_b: {
                dataKey: "tradeFrom_b",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              tradeFrom_b: {
                dataKey: "tradeFrom_b",
                title: `Total bill Amount : ${mtmTotal.toFixed(2)}`,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: mtmTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }

        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: "",
                styles: dataMain.footerStyle,
              },
              {
                content: totalCountNottoFixed(rowData, "lot_b"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCountNottoFixed(rowData, "buyQuantity_b"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "rate_b"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netRate_b"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netAmount_b"),
                styles: dataMain.footerStyle,
              },
              {
                content: "",
                styles: { fillColor: [255, 255, 255] },
              },
              {
                content: "",
                styles: dataMain.footerStyle,
              },
              {
                content: totalCountNottoFixed(rowData, "lot_s"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCountNottoFixed(rowData, "sellQuantity_s"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "rate_s"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netRate_s"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netAmount_s"),
                styles: dataMain.footerStyle,
              },
            ],
            [
              {
                content: netAmountTotal.toFixed(2),
                styles: {
                  fontStyle: "bold",
                  fillColor: [242, 243, 165],
                  halign: "center",
                  textColor:
                    netAmountTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
                colSpan: col.length,
              },
            ],
          ],
          ...footerTotal,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
      if (dataMain.reportType == "24") {
        doc.autoTable({
          head: [
            {
              date: {
                dataKey: "date",
                title: item1.name,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [202, 226, 183],
                },
              },
            },
            {
              date: {
                dataKey: "date",
                title: "Ledger",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            ledgerBillColum,
          ],
          columns: ledgerBillColum,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            {
              date: { content: "" },
              docNo: { content: "" },
              particulars: { content: "Opening Balance", styles: align },
              debit: {
                content: 0 > ledgerBalance ? ledgerBalance : 0,
                styles: align,
              },
              credit: {
                content: 0 < ledgerBalance ? ledgerBalance : 0,
                styles: align,
              },
              balance: { content: ledgerBalance, styles: align },
              description: { content: "", styles: align },
            },
            [
              {
                content: "",
                colSpan: 3,
                styles: dataMain.footerStyle,
              },
              {
                content: 0 > ledgerBalance ? ledgerBalance : 0,
                styles: dataMain.footerStyle,
              },
              {
                content: 0 < ledgerBalance ? ledgerBalance : 0,
                styles: dataMain.footerStyle,
              },
              {
                content: ledgerBalance,
                styles:
                  ledgerBalance > 0
                    ? { ...dataMain.footerColorBlue, halign: "center" }
                    : { ...dataMain.footerColorRed, halign: "center" },
                colSpan: 3,
              },
            ],
          ],
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      }
    });
  } else if (dataMain.reportType == "25") {
    dataMain.arrayName.forEach((item1, index) => {
      let FinalMtmTotal = 0;
      let FinalNetRateTotal = 0;
      let FinalRateTotal = 0;
      item1.data.forEach((element, i) => {
        let mtmTotal = 0;
        let rateTotal = 0;
        let netRateTotal = 0;
        let totalBuyQty = 0;
        let totalSellQty = 0;
        let tofixColm = ["rate", "netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let netrate = 0;
          let rate = 0;
          let data = _.omit(item, ["userId", "clientName"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};

            if (itemData[0] == "netAmount") {
              mtmTotal = mtmTotal + itemData[1];
            }
            if (itemData[0] == "rate") {
              rate = itemData[1];
            }
            if (itemData[0] == "netRate") {
              netrate = itemData[1];
              if (item["buyQuantity"] !== 0) {
                totalBuyQty =
                  totalBuyQty + (netrate - rate) * item["buyQuantity"];
              }
              if (item["sellQuantity"] !== 0) {
                totalSellQty =
                  totalSellQty + (rate - netrate) * item["sellQuantity"];
              }
            }
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[0] == "tradeDate"
                ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                : itemData[1];
            if (itemData[0] == "tradeDate") {
              newObj1.styles = {};
            } else if (itemData[0] == "sellQuantity") {
              newObj1.styles = textRed;
            } else if (itemData[0] == "buyQuantity") {
              newObj1.styles = textBlue;
            } else {
              newObj1.styles = align;
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          netRateTotal = totalBuyQty + totalSellQty;
          rateTotal = netRateTotal + mtmTotal;
          return finalObj;
        });
        FinalRateTotal = FinalRateTotal + rateTotal;
        FinalNetRateTotal = FinalNetRateTotal + netRateTotal;
        FinalMtmTotal = FinalMtmTotal + mtmTotal;
        let nameHeader = {};
        const clientName = {
          tradeDate: {
            dataKey: "tradeDate",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }
        const scriptname = {
          tradeDate: {
            dataKey: "tradeDate",
            title: element.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if(i === 0){
          nameHeader.head = [clientName, scriptname, col]
        }else{
          nameHeader.head = [scriptname, col]
        }
        let footerTotal = {};
        if (i == item1.data.length - 1) {
          footerTotal.foot = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: `Total Gross : ${FinalRateTotal.toFixed(2)}`,
                colSpan: 3,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor:
                    FinalRateTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
              buyQuantity: {
                dataKey: "buyQuantity",
                title: `Total Brok. : ${FinalNetRateTotal.toFixed(2)}`,
                colSpan: 3,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor:
                    FinalNetRateTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
              netRate: {
                dataKey: "netRate",
                title: `Net Amount :${FinalMtmTotal.toFixed(2)}`,
                colSpan: 2,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: FinalMtmTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }
        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: "Script wise total :",
                colSpan: 3,
                styles: {
                  fillColor: [146, 143, 143],
                  textColor: [255, 255, 255],
                  fontStyle: "bold",
                },
              },
              {
                content: totalCount(rowData, "buyQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "sellQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: rateTotal.toFixed(2),
                styles: {
                  fillColor: [242, 243, 165],
                  halign: "right",
                  fontStyle: "bold",
                },
              },
              {
                content: netRateTotal.toFixed(2),
                styles: {
                  fillColor: [242, 243, 165],
                  halign: "right",
                  fontStyle: "bold",
                },
              },
              {
                content: mtmTotal.toFixed(2),
                styles:
                  mtmTotal.toFixed(2) > 0
                    ? dataMain.footerColorBlue
                    : dataMain.footerColorRed,
              },
            ],
          ],
          ...footerTotal,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
    });
  } else if (dataMain.reportType == "26") {
    dataMain.arrayName.forEach((item1, index) => {
      let mtmTotal = 0;
      item1.data.forEach((element, i) => {
        let tofixColm = ["netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let data = _.omit(item, ["userId", "clientName", "rate"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            if (itemData[0] == "netAmount") {
              mtmTotal = mtmTotal + itemData[1];
            }
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[0] == "tradeDate"
                ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                : itemData[1];
            if (itemData[0] == "tradeDate") {
              newObj1.styles = {};
            } else if (itemData[0] == "sellQuantity") {
              newObj1.styles = textRed;
            } else if (itemData[0] == "buyQuantity") {
              newObj1.styles = textBlue;
            } else {
              newObj1.styles = align;
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          return finalObj;
        });
        let nameHeader = {};
        if (i == 0) {
          nameHeader.head = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: item1.name,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [202, 226, 183],
                },
              },
            },
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: element.name,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            col,
          ];
        } else {
          nameHeader.head = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: element.name,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            col,
          ];
        }
        let footerTotal = {};
        if ( i == item1.data.length - 1 ) {
          footerTotal.foot = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: `Total bill Amount : ${mtmTotal.toFixed(2)}`,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: mtmTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }

        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: "Script wise total :",
                colSpan: 3,
                styles: {
                  fillColor: [146, 143, 143],
                  textColor: [255, 255, 255],
                  fontStyle: "bold",
                },
              },
              {
                content: totalCount(rowData, "buyQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "sellQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netAmount"),
                colSpan: 3,
                styles:
                  totalCount(rowData, "netAmount") > 0
                    ? dataMain.footerColorBlue
                    : dataMain.footerColorRed,
              },
            ],
          ],
          ...footerTotal,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
    });
  } else if (dataMain.reportType == "27" || dataMain.reportType == "28" || dataMain.reportType == "213") {
    let newData = [];
    if (dataMain.reportType == "27" || dataMain.reportType == "213") {
      dataMain.arrayName.forEach((item1) => {
        let rowData = item1.data.map((element, i) => {
          return {
            ...element,
            accountCode: { content: element.accountCode, styles: {align, fontStyle: "bold"} },
            clientName: { content: element.clientName, styles: {align, fontStyle: "bold"} },
            credit: { content: Math.round(element.credit), styles: {...align, fontStyle: "bold", ...textBlue} },
            debit: { content: Math.round(element.debit), styles: {...align, fontStyle: "bold", ...textRed} },
          };
        });
        newData.push(...rowData);
      });
      doc.autoTable({
        head: [
          {
            accountCode: {
              dataKey: "accountCode",
              title: "Customer",
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...newData,
          [
            {
              content: totalCountForBro(newData, "credit") - totalCountForBro(newData, "debit"),
              colSpan: 2,
              styles: (totalCountForBro(newData, "debit") - totalCountForBro(newData, "credit")) > 0
                  ? dataMain.footerColorRed
                  : dataMain.footerColorBlue,
            },
            {
              content: totalCountForBro(newData, "debit"),
              styles: dataMain.footerColorRed,
            },
            {
              content: totalCountForBro(newData, "credit"),
              styles: dataMain.footerColorBlue,
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    } else {
      dataMain.arrayName.forEach((item1, index) => {
        let creditValue = 0;
        let debitValue = 0;
        item1.data.forEach((item2) => {
          creditValue += item2.credit;
          debitValue += item2.debit;
        });
        newData.push({
          authorityName: item1.name,
          credit: { content: creditValue.toFixed(2), styles: align },
          debit: { content: debitValue.toFixed(2), styles: align },
        });
      });
      doc.autoTable({
        head: [
          {
            authorityName: {
              dataKey: "authorityName",
              title: "Authority",
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        pageBreak: "avoid",
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...newData,
          [
            {
              content: parseFloat(
                totalCount(newData, "credit") - totalCount(newData, "debit")
              ).toFixed(2),
              colSpan: 2,
              styles:
                parseFloat(
                  totalCount(newData, "credit") - totalCount(newData, "debit")
                ).toFixed(2) > 0
                  ? dataMain.footerColorBlue
                  : dataMain.footerColorRed,
            },
            {
              content: parseFloat(totalCount(newData, "debit")).toFixed(2),
              styles: dataMain.footerStyle,
            },
            {
              content: parseFloat(totalCount(newData, "credit")).toFixed(2),
              styles: dataMain.footerStyle,
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    }
  } else if (billSimpleData.includes(dataMain.reportType)) {
    dataMain.arrayName.forEach((item1, index) => {
      let mtmTotal = 0;
      let ledgerBalance = 0;
      item1.data.forEach((element, i) => {
        let tofixColm = ["rate", "netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          ledgerBalance = item.ledgerBalance;
          let data = _.omit(item, ["userId", "clientName"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            if (itemData[0] == "netAmount") {
              mtmTotal = mtmTotal + itemData[1];
            }
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[0] == "tradeDate"
                ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                : itemData[1];
            if (itemData[0] == "tradeDate") {
              newObj1.styles = {};
            } else if (itemData[0] == "sellQuantity") {
              newObj1.styles = textRed;
            } else if (itemData[0] == "buyQuantity") {
              newObj1.styles = textBlue;
            } else {
              newObj1.styles = align;
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            if (itemData[0] == "tradeDate") {
              finalObj["tradeDate"] = newObj1;
            } else {
              finalObj[itemData[0]] = newObj1;
            }
          });
          return finalObj;
        });
        let nameHeader = {};
        const clientName = {
          tradeDate: {
            dataKey: "tradeDate",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }
        const scriptName = {
          tradeDate: {
            dataKey: "tradeDate",
            title: element.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if(i === 0){
          nameHeader.head = [clientName, scriptName, col]
        }else{
          nameHeader.head = [scriptName, col]
        }
        let footerTotal = {};
        if (i == item1.data.length - 1 ) {
          footerTotal.foot = [
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              tradeDate: {
                dataKey: "tradeDate",
                title: `Total bill Amount : ${mtmTotal.toFixed(2)}`,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: mtmTotal >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }
        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: "Script wise total : ",
                styles: {
                  ...dataMain.footerStyle,
                  halign: "left",
                  fontStyle: "bold",
                },
                colSpan: 3,
              },
              {
                content: totalCountNottoFixed(rowData, "buyQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "sellQuantity"),
                styles: dataMain.footerStyle,
              },
              {
                content: totalCount(rowData, "netAmount"),
                styles:
                  totalCount(rowData, "netAmount") > 0
                    ? dataMain.footerColorBlue
                    : dataMain.footerColorRed,
                colSpan: 3,
              },
            ],
          ],
          ...footerTotal,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
      if (
        dataMain.reportType == "23" ||
        dataMain.reportType == "24" ||
        dataMain.reportType == "25" ||
        dataMain.reportType == "211" ||
        dataMain.reportType == "212"
      ) {
        let nameHeader = {}
        const clientName = {
          date: {
            dataKey: "date",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }
        const scriptName = {
          date: {
            dataKey: "date",
            title: "Ledger",
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if(index === 0){
          nameHeader.head = [clientName, scriptName, ledgerBillColum]
        }else{
          nameHeader.head = [scriptName, ledgerBillColum]
        }
        doc.autoTable({
          ...nameHeader,
          columns: ledgerBillColum,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            {
              date: { content: "" },
              docNo: { content: "" },
              particulars: { content: "Opening Balance", styles: align },
              debit: {
                content: 0 > ledgerBalance ? ledgerBalance : 0,
                styles: align,
              },
              credit: {
                content: 0 < ledgerBalance ? ledgerBalance : 0,
                styles: align,
              },
              balance: { content: ledgerBalance, styles: align },
              description: { content: "", styles: align },
            },
            [
              {
                content: "",
                colSpan: 3,
                styles: dataMain.footerStyle,
              },
              {
                content: 0 > ledgerBalance ? ledgerBalance : 0,
                styles: dataMain.footerStyle,
              },
              {
                content: 0 < ledgerBalance ? ledgerBalance : 0,
                styles: dataMain.footerStyle,
              },
              {
                content: ledgerBalance,
                styles:
                  ledgerBalance > 0
                    ? { ...dataMain.footerColorBlue, halign: "center" }
                    : { ...dataMain.footerColorRed, halign: "center" },
                colSpan: 3,
              },
            ],
          ],
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      }
    });
  } else if (
    dataMain.reportType == "31" ||
    dataMain.reportType == "313" ||
    dataMain.reportType == "312"
  ) {
    let newData = [];
    let grossAmt = 0;
    let clientBrok = 0;
    let billAmt = 0;
    let sbBrok = 0;
    let partnershipBrok = 0;
    let selfBrok = 0;
    let sbAmt = 0;
    let partnershipAmt = 0;
    let selfAmt = 0;
    let totalSelfAmt = 0;
    let newTitle =
      dataMain.reportType == "31"
        ? "Customer"
        : dataMain.reportType == "312"
          ? "Authority"
          : "Self";
    if (dataMain.reportType == "313") {
      dataMain.arrayName.map((element, index) => {
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let tofixColm = [
            "billAmt",
            "clientBrok",
            "grossAmt",
            "selfAmt",
            "selfBrok",
          ];
          const objData = {
            ...item,
            sbAmt: dataMain.userRole === "Partner" ? 0 : item.sbAmt,
            sbBrok: dataMain.userRole === "Partner" ? 0 : item.sbBrok,
            partnershipAmt:
              dataMain.userRole === "Sub Broker" ? 0 : item.partnershipAmt,
            partnershipBrok:
              dataMain.userRole === "Sub Broker" ? 0 : item.partnershipBrok,
          };
          grossAmt += item.grossAmt;
          clientBrok += item.clientBrok;
          billAmt += item.billAmt;
          sbBrok += objData.sbBrok;
          partnershipBrok += objData.partnershipBrok;
          selfBrok += item.selfBrok;
          sbAmt += objData.sbAmt;
          partnershipAmt += objData.partnershipAmt;
          selfAmt += item.selfAmt;
          let data = _.omit(objData, ["userId"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[1];
            if (i !== 0 && itemData[0] !== 'clientName' && itemData[0] !== 'authorityName') {
              newObj1.styles = align;
            }
            if(itemData[0] === 'grossAmt' || 
            itemData[0] === 'clientBrok' || 
            itemData[0] === 'billAmt' || 
            itemData[0] === 'sbBrok' || 
            itemData[0] === 'partnershipBrok' || 
            itemData[0] === 'selfBrok' || 
            itemData[0] === 'sbAmt' || 
            itemData[0] === 'partnershipAmt' ||
            itemData[0] === 'selfAmt'){
              newObj1.styles = itemData[1] > 0
                ? {...newObj1.styles, textColor:[62, 62, 180]}
                : itemData[1] < 0
                  ? {...newObj1.styles, textColor: [245, 54, 92]}
                  : {...newObj1.styles}
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          newData.push(finalObj);
          return finalObj;
        });
        totalSelfAmt += selfAmt;
        let footerTotal = {};
        if (index == dataMain.arrayName.length - 1) {
          footerTotal.foot = [
            {
              srNo: {
                dataKey: "srNo",
                title: "",
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  lineColor: "white",
                  fillColor: [255, 255, 255],
                },
              },
            },
            {
              srNo: {
                dataKey: "srNo",
                title: `Total bill Amount : ${totalSelfAmt.toFixed(2)}`,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  fillColor: [255, 255, 255],
                  textColor: totalSelfAmt >= 0 ? [62, 62, 180] : [245, 54, 92],
                },
              },
            },
          ];
        }
        let isScriptWise = "srNo";
        doc.autoTable({
          head: [
            {
              [isScriptWise]: {
                dataKey: "srNo",
                title: newTitle,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            col,
          ],
          showHead: "firstPage",
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: " ",
                styles: dataMain.footerStyle,
              },
              {
                content: " ",
                styles: dataMain.footerStyle,
              },
              {
                content: "Total:",
                styles: dataMain.footerStyle,
              },
              {
                content: grossAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: clientBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: billAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: sbBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: partnershipBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: selfBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: sbAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: partnershipAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: selfAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
            ],
          ],
          ...footerTotal,
          headStyles: dataMain.headStyles,
          theme: "grid",
        });
        if (index != dataMain.arrayName.length - 1) {
          doc.addPage(undefined, "l");
        }
        grossAmt = 0;
        clientBrok = 0;
        billAmt = 0;
        sbBrok = 0;
        partnershipBrok = 0;
        selfBrok = 0;
        sbAmt = 0;
        partnershipAmt = 0;
        selfAmt = 0;
      });
    } else if (dataMain.reportType == "312") {
      dataMain.arrayName.map((element, index) => {
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let tofixColm = [
            "billAmt",
            "clientBrok",
            "grossAmt",
            "selfAmt",
            "selfBrok",
          ];
          grossAmt += item.grossAmt;
          clientBrok += item.clientBrok;
          billAmt += item.billAmt;
          sbBrok += item.sbBrok;
          partnershipBrok += item.partnershipBrok;
          selfBrok += item.selfBrok;
          sbAmt += item.sbAmt;
          partnershipAmt += item.partnershipAmt;
          selfAmt += item.selfAmt;
          let data = _.omit(item, ["userId"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[1];
            if (i !== 0 && itemData[0] !== 'clientName' && itemData[0] !== 'authorityName') {
              newObj1.styles = align;
            }
            if(itemData[0] === 'grossAmt' || 
            itemData[0] === 'clientBrok' || 
            itemData[0] === 'billAmt' || 
            itemData[0] === 'sbBrok' || 
            itemData[0] === 'partnershipBrok' || 
            itemData[0] === 'selfBrok' || 
            itemData[0] === 'sbAmt' || 
            itemData[0] === 'partnershipAmt' ||
            itemData[0] === 'selfAmt'){
              newObj1.styles = itemData[1] > 0
                ? {...newObj1.styles, textColor:[62, 62, 180]}
                : itemData[1] < 0
                  ? {...newObj1.styles, textColor: [245, 54, 92]}
                  : {...newObj1.styles}
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          newData.push(finalObj);
          return finalObj;
        });
        // totalSelfAmt += selfAmt;
        // let footerTotal = {};
        // if (index == dataMain.arrayName.length - 1) {
        //   footerTotal.foot = [
        //     {
        //       authorityName: {
        //         dataKey: "authorityName",
        //         title: "",
        //         colSpan: col.length,
        //         styles: {
        //           halign: "center",
        //           fontStyle: "bold",
        //           lineColor: "white",
        //           fillColor: [255, 255, 255],
        //         },
        //       },
        //     },
        //     {
        //       authorityName: {
        //         dataKey: "authorityName",
        //         title: `Total bill Amount : ${totalSelfAmt.toFixed(2)}`,
        //         colSpan: col.length,
        //         styles: {
        //           halign: "center",
        //           fontStyle: "bold",
        //           fillColor: [255, 255, 255],
        //           textColor: totalSelfAmt >= 0 ? [62, 62, 180] : [245, 54, 92],
        //         },
        //       },
        //     },
        //   ];
        // }
        let isScriptWise = "srNo";
        doc.autoTable({
          head: [
            {
              [isScriptWise]: {
                dataKey: "srNo",
                title: newTitle,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            col,
          ],
          showHead: "firstPage",
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...rowData,
            [
              {
                content: " ",
                styles: dataMain.footerStyle,
              },
              {
                content: " ",
                styles: dataMain.footerStyle,
              },
              {
                content: "Total:",
                styles: dataMain.footerStyle,
              },
              {
                content: grossAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: clientBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: billAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: sbBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: partnershipBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: selfBrok.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: sbAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: partnershipAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
              {
                content: selfAmt.toFixed(2),
                styles: dataMain.footerStyle,
              },
            ],
          ],
          headStyles: dataMain.headStyles,
          theme: "grid",
        });
        if (index != dataMain.arrayName.length - 1) {
          doc.addPage(undefined, "l");
        }
        grossAmt = 0;
        clientBrok = 0;
        billAmt = 0;
        sbBrok = 0;
        partnershipBrok = 0;
        selfBrok = 0;
        sbAmt = 0;
        partnershipAmt = 0;
        selfAmt = 0;
      });
    } else {
      dataMain.arrayName.map((element) => {
        element.data.map((item) => {
          let finalObj = {};
          let tofixColm = [
            "billAmt",
            "clientBrok",
            "grossAmt",
            "selfAmt",
            "selfBrok",
          ];
          const objData = {
            ...item,
            sbAmt: dataMain.userRole === "Partner" ? 0 : item.sbAmt,
            sbBrok: dataMain.userRole === "Partner" ? 0 : item.sbBrok,
            partnershipAmt:
              dataMain.userRole === "Sub Broker" ? 0 : item.partnershipAmt,
            partnershipBrok:
              dataMain.userRole === "Sub Broker" ? 0 : item.partnershipBrok,
          };
          let data = _.omit(objData, ["userId"]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[1];
            if (i !== 0 && itemData[0] !== 'clientName' && itemData[0] !== 'authorityName') {
              newObj1.styles = align;
            }
            if(itemData[0] === 'grossAmt' || 
            itemData[0] === 'clientBrok' || 
            itemData[0] === 'billAmt' || 
            itemData[0] === 'sbBrok' || 
            itemData[0] === 'partnershipBrok' || 
            itemData[0] === 'selfBrok' || 
            itemData[0] === 'sbAmt' || 
            itemData[0] === 'partnershipAmt' ||
            itemData[0] === 'selfAmt'){
              newObj1.styles = itemData[1] > 0
                ? {...newObj1.styles, textColor:[62, 62, 180]}
                : itemData[1] < 0
                  ? {...newObj1.styles, textColor: [245, 54, 92]}
                  : {...newObj1.styles}
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          newData.push(finalObj);
          return finalObj;
        });
      });

      let isScriptWise = "srNo";
      doc.autoTable({
        head: [
          {
            [isScriptWise]: {
              dataKey: "srNo",
              title: newTitle,
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        showHead: "firstPage",
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...newData,
          [
            {
              content: " ",
              styles: dataMain.footerStyle,
            },
            {
              content: " ",
              styles: dataMain.footerStyle,
            },
            {
              content: "Total:",
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "grossAmt"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "clientBrok"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "billAmt"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "sbBrok"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "partnershipBrok"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "selfBrok"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "sbAmt"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "partnershipAmt"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(newData, "selfAmt"),
              styles: dataMain.footerStyle,
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    }
  } else if (dataMain.reportType == "32" || dataMain.reportType == "323") {
    let newData = [];
    let newTitle = dataMain.reportType == "32" ? "Customer" : "Self";
    dataMain.arrayName.map((element) => {
      element.data.map((item) => {
        let finalObj = {};
        let tofixColm = [
          "billAmt",
          "clientBrok",
          "grossAmt",
          "sbBrok",
          "sharingPercentage",
          "selfBrok",
          "selfAmt",
        ];
        let data = _.omit(item, ["userId"]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
          ? itemData[1].toFixed(2)
          : itemData[1];
          if (i !== 0 && itemData[0] !== 'clientName' && itemData[0] !== 'authorityName') {
            newObj1.styles = align;
          }
          if( itemData[0] === 'billAmt' || 
          itemData[0] === 'clientBrok' || 
          itemData[0] === 'grossAmt' || 
          itemData[0] === 'sbBrok' || 
          itemData[0] === 'sharingPercentage' || 
          itemData[0] === 'selfBrok' || 
          itemData[0] === 'selfAmt'){
            newObj1.styles = itemData[1] > 0
              ? {...newObj1.styles, textColor:[62, 62, 180]}
              : itemData[1] < 0
                ? {...newObj1.styles, textColor: [245, 54, 92]}
                : {...newObj1.styles}
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        newData.push(finalObj);
        return finalObj;
      });
    });
    let isScriptWise = "authorityName";
    doc.autoTable({
      head: [
        {
          [isScriptWise]: {
            dataKey: "authorityName",
            title: newTitle,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        col,
      ],
      showHead: "firstPage",
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...newData,
        [
          {
            content: " ",
            styles: dataMain.footerStyle,
          },
          {
            content: "Total:",
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "billAmt"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "clientBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "grossAmt"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "sbBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "sharingPercentage"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "selfBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCount(newData, "selfAmt"),
            styles: dataMain.footerStyle,
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } else if (dataMain.reportType == "41") {
    dataMain.arrayName.forEach((item1, index) => {
      item1.data.forEach((element, i) => {
        let tofixColm = ["netRate", "netAmount"];
        let rowData = element.data.map((item) => {
          let finalObj = {};
          let data = _.pick(item, [
            "script",
            "tradeTime",
            "lot",
            "buyQuantity",
            "sellQuantity",
            "netRate",
          ]);
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};
            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[0] == "tradeTime"
                ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
                : itemData[1];
            if (itemData[0] === "script" || itemData[0] === 'tradeTime') {
              newObj1.styles = {};
            } else if (itemData[0] == "sellQuantity") {
              newObj1.styles = textRed;
            } else if (itemData[0] == "buyQuantity") {
              newObj1.styles = textBlue;
            } else {
              newObj1.styles = align;
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          return finalObj;
        });
        let nameHeader = {};
        const clientName = {
          script: {
            dataKey: "script",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [202, 226, 183],
            },
          },
        }  
        const scriptName = {
          script: {
            dataKey: "script",
            title: moment(element.name).format("DD-MM-YYYY"),
            colSpan: col.length,
            styles: {
              tableLineWidth: 5,
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        }
        if(i === 0){
          nameHeader.head = [clientName, scriptName, col]
        }else{
          nameHeader.head = [scriptName, col]
        }
        doc.autoTable({
          ...nameHeader,
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: rowData,
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
      });
    });
  } else if (dataMain.reportType == "42") {
    dataMain.arrayName.forEach((element, i) => {
      let tofixColm = ["grossBuy", "grossSell", "netBuy", "netSell"];
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let data = _.pick(item, [
          "script",
          "grossBuy",
          "grossSell",
          "netBuy",
          "netSell",
        ]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[0] == "tradeTime"
              ? moment(itemData[1]).format("DD-MM-YYYY HH:mm:ss")
              : itemData[1];
          if (itemData[0] == "script") {
            newObj1.styles = {};
          } else {
            newObj1.styles = align;
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });
      doc.autoTable({
        head: [
          {
            script: {
              dataKey: "script",
              title: element.name,
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: "Total:",
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "grossBuy"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "grossSell"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "netBuy"),
              styles: dataMain.footerStyle,
            },
            {
              content: totalCount(rowData, "netSell"),
              styles: dataMain.footerStyle,
            },
          ],
          [
            {
              content: "",
              styles: { fillColor: [255, 255, 255] },
            },
            {
              content: totalCount(rowData, "grossBuy"),
              colSpan: 2,
              styles: {
                fontStyle: "bold",
                fillColor: [242, 243, 165],
                halign: "center",
                textColor: [0, 0, 0],
              },
            },
            {
              content: totalCount(rowData, "netBuy"),
              colSpan: 2,
              styles: {
                fontStyle: "bold",
                fillColor: [242, 243, 165],
                halign: "center",
                textColor: [0, 0, 0],
              },
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "43") {
    dataMain.arrayName.forEach((element, i) => {
      let tofixColm = ["grossBuy", "grossSell", "netBuy", "netSell"];
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let data = _.pick(item, [
          "clientName",
          "grossBuy",
          "grossSell",
          "netBuy",
          "netSell",
        ]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (itemData[0] == "clientName") {
            newObj1.styles = {};
          } else {
            newObj1.styles = align;
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });

      doc.autoTable({
        head: [
          {
            clientName: {
              dataKey: "clientName",
              title: "CLIENT",
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        pageBreak: "avoid",
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: "",
              styles: dataMain.footerStyle,
            },
            {
              content: "",
              styles: dataMain.footerStyle,
            },
            {
              content: "",
              styles: dataMain.footerStyle,
            },
            {
              content: "",
              styles: dataMain.footerStyle,
            },
            {
              content: "",
              styles: dataMain.footerStyle,
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "51" || dataMain.reportType == "52") {
    let newData = [];
    dataMain.arrayName.forEach((element) => {
      let rowData = element.data.map((item) => {
        let tofixColm = [];
        let finalObj = {};
        if (dataMain.reportType == "52") {
          tofixColm = ["clientBrok", "sbBrok", "finalBrok"]
        } else {
          tofixColm = ["clientBrok", "sbBrok"]
        }
        let data = _.pick(item, [
          "clientCode",
          "clientName",
          "clientBrok",
          "sbBrok",
          "finalBrok",
        ]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? Math.round(itemData[1])
            : itemData[1];
          if (i !== 0 && itemData[0] !== "clientName") {
            newObj1.styles = align;
          }
          // if (itemData[0] == "netQty" || itemData[0] == "mtm") {
          // 	itemData[1] >= 0
          // 		? (newObj1.styles = textBlue)
          // 		: (newObj1.styles = textRed);
          // }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        //   newData.push(finalObj);
        return finalObj;
      });
      newData.push(rowData[0]);
    });
    const totalFooter =  dataMain.reportType == "51" ? [
      {
        content: "",
        styles: dataMain.footerStyle,
      },
      {
        content: totalCountForBro(newData, "finalBrok"),
        styles:
        totalCountForBro(newData, "finalBrok") > 0
            ? dataMain.footerColorBlue
            : dataMain.footerColorRed,
      },
      {
        content: totalCountForBro(newData, "clientBrok"),
        styles:
        totalCountForBro(newData, "clientBrok") > 0
            ? dataMain.footerColorBlue
            : dataMain.footerColorRed,
      },
      {
        content: totalCountForBro(newData, "sbBrok"),
        styles:
        totalCountForBro(newData, "sbBrok") > 0
            ? dataMain.footerColorBlue
            : dataMain.footerColorRed,
      },
     
    ] : [
      {
        content: "Total :",
        colSpan:2,
        styles: dataMain.footerStyle,
      },
      {
        content: totalCountForBro(newData, "clientBrok"),
        styles:
          totalCountForBro(newData, "clientBrok") > 0
            ? dataMain.footerColorBlue
            : dataMain.footerColorRed,
      },
      {
        content: totalCountForBro(newData, "sbBrok"),
        styles:
          totalCountForBro(newData, "sbBrok") > 0
            ? dataMain.footerColorBlue
            : dataMain.footerColorRed,
      },
      {
        content: totalCountForBro(newData, "finalBrok"),
        styles:
        totalCountForBro(newData, "finalBrok") > 0
          ? dataMain.footerColorBlue
          : dataMain.footerColorRed,
      },
    ]
    doc.autoTable({
      head: [
        {
          clientCode: {
            dataKey: "clientCode",
            title: "Customer",
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        col
      ],
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...newData,
        [...totalFooter],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } else if (dataMain.reportType == "53") {
    dataMain.arrayName.forEach((item1, index) => {
      let rowData = item1.data.map((item) => {
        let finalObj = {};
        let tofixColm = ["sbBrok"];
        let data = _.pick(item, [
          "clientCode",
          "clientName",
          "sbBrok",
        ]);

        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? Math.round(itemData[1])
            : itemData[1];
          if (i !== 0) {
            newObj1.styles = align;
          }
          if(itemData[0] == "clientName"){
            newObj1.styles = {align: "left"};
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });
    doc.autoTable({
      head: [
        {
          clientCode: {
            dataKey: "clientCode",
            title: item1.name,
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        col,
      ],
      pageBreak: "avoid",
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...rowData,
        [
          {
            content: "Total :",
            colSpan:2,
            styles: dataMain.footerStyle,
          },
          {
            content: totalCountForBro(rowData, "sbBrok"),
            styles:
              totalCountForBro(rowData, "sbBrok") > 0
                ? dataMain.footerColorBlue
                : dataMain.footerColorRed,
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "54") {
    let newData = [];
    dataMain.arrayName.forEach((element) => {
      let rowData = element.data.map((item) => {
        let finalObj = {};
        let tofixColm = ["clientBrok", "sbBrok", "partnershipBrok", "selfBrok"];
        let data = _.pick(item, [
          "clientCode",
          "clientName",
          "clientBrok",
          "sbBrok",
          "partnershipBrok",
          "selfBrok",
        ]);

        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? Math.round(itemData[1])
            : itemData[1];
          if (i !== 0 && itemData[0] !== 'clientName') {
            newObj1.styles = align;
          }
          // if (itemData[0] == "netQty" || itemData[0] == "mtm") {
          // 	itemData[1] >= 0
          // 		? (newObj1.styles = textBlue)
          // 		: (newObj1.styles = textRed);
          // }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        //   newData.push(finalObj);
        return finalObj;
      });
      newData.push(rowData[0]);
    });
    doc.autoTable({
      head: [
        {
          clientCode: {
            dataKey: "clientCode",
            title: "Customer",
            colSpan: col.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        col,
      ],
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...newData,
        [
          {
            content: "Total :",
            colSpan:2,
            styles: dataMain.footerStyle,
          },
          {
            content: totalCountForBro(newData, "clientBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCountForBro(newData, "sbBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCountForBro(newData, "partnershipBrok"),
            styles: dataMain.footerStyle,
          },
          {
            content: totalCountForBro(newData, "selfBrok"),
            styles:
              totalCountForBro(newData, "selfBrok") > 0
                ? dataMain.footerColorBlue
                : dataMain.footerColorRed,
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } else if (dataMain.reportType == "61") {
    dataMain.arrayName.forEach((element, i) => {
      let rowData = element.data[0].data.map((item) => {
        let finalObj = {};
        let tofixColm = [
          "beAvg",
          "buyAvgPrice",
          "ledgerBalance",
          "sellAvgPrice",
          "ltp",
          "mtm",
        ];
        let data = _.omit(item, ["userId"]);
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (i !== 0) {
            newObj1.styles = align;
          }
          if (itemData[0] == "script") {
            newObj1.styles = {};
          }
          if (itemData[0] == "netQty" || itemData[0] == "mtm") {
            newObj1.styles =
              itemData[1] > 0
                ? { textColor: [62, 62, 180], halign: "right" }
                : itemData[1] < 0
                  ? { textColor: [245, 54, 92], halign: "right" }
                  : { halign: "right" };
            // newObj1.styles={itemData > 0 ? dataMain.footerColorBlue : dataMain.footerColorRed}
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });

      let valueLedger = element.data[0].name;
      let mtmTotal = totalCount(rowData, "mtm");
      let isScriptWise = "script";
      doc.autoTable({
        head: [
          {
            [isScriptWise]: {
              dataKey: "script",
              title: element.name,
              colSpan: col.length / 2,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
            sellAvgPrice: {
              dataKey: "sellAvgPrice",
              title: `Ledger Balance:  ${valueLedger}`,
              colSpan: col.length / 2,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...rowData,
          [
            {
              content: `${valueLedger > 0
                  ? (valueLedger + parseFloat(mtmTotal)).toFixed(2)
                  : mtmTotal < 0
                    ? ((Math.abs(valueLedger) + Math.abs(mtmTotal)) * -1).toFixed(2)
                    : ((Math.abs(valueLedger) - Math.abs(mtmTotal)) * -1).toFixed(2)
                }`,
              colSpan: 4,
              styles: {
                fontStyle: "bold",
                textColor: [0, 0, 0],
                halign: "center",
                fillColor: [242, 243, 165],
              },
            },
            {
              content: Number(mtmTotal).toFixed(2),
              colSpan: 4,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [255, 255, 255],
                fillColor: [132, 132, 132],
              },
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    });
  } else if (dataMain.reportType == "62" || dataMain.reportType == "623") {
    if (dataMain.reportType == "623") {
      let newData = [];
      let valueTotal = 0;
      let beAvgTotal = 0;
      let PosQtyTotal = 0;
      let mtmTotal = 0;
      let uniqueData = [];
      let dummyData = [];
      dataMain.arrayName.forEach((element, i) => {
        // else {
        dummyData = [...dummyData, ...element.data[0].data];
      });
      dummyData.map((item) => {
        if (uniqueData.length === 0) {
          uniqueData.push(item);
        } else {
          const index = uniqueData.findIndex(
            (item2) => item2.script === item.script
          );
          if (index !== -1) {
            uniqueData = uniqueData.map((record) => {
              if (record.script === item.script) {
                return {
                  ...record,
                  netQty: record.netQty + item.netQty,
                  beAvg: record.beAvg + item.beAvg,
                  value: record.value + item.value,
                  ltp: record.ltp + item.ltp,
                  mtm: record.mtm + item.mtm,
                  netLot: record.netLot + item.netLot,
                };
              }
              return record;
            });
          } else {
            uniqueData.push(item);
          }
        }
      });
      /* let rowData = element.data[0].data.map((item) => {
          let finalObj = {};
          let tofixColm = [
            "beAvg",
            "ledgerBalance",
            "sellAvgPrice",
            "ltp",
            "mtm",
          ];
          let data = _.omit(item, ["userId"]);
          let netQty = 0;
          let beAvg = 0;
          let finalValue = 0;
          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};

            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[1];
            if (i !== 0) {
              newObj1.styles = align;
            }
            if (itemData[0] == "script") {
              newObj1.styles = {};
            }
            finalObj[itemData[0]] = newObj1;
            if (itemData[0] == "netQty") {
              netQty = itemData[1];
            } else if (itemData[0] == "beAvg") {
              beAvg = itemData[1];
            }
            if (itemData[0] == "mtm") {
              newObj1.styles =
                itemData[1] > 0
                  ? {textColor: [62, 62, 180], halign: "right"}
                  : itemData[1] < 0
                  ? {textColor: [245, 54, 92], halign: "right"}
                  : {halign: "right"};
              mtmTotal = mtmTotal + itemData[1];
              // newObj1.styles={itemData > 0 ? dataMain.footerColorBlue : dataMain.footerColorRed}
            }
          });
          finalValue = netQty * beAvg;
          if (finalValue > 0) {
            PosQtyTotal = PosQtyTotal + finalValue;
          }
          if (finalValue < 0) {
            beAvgTotal = beAvgTotal + finalValue;
          }
          valueTotal = valueTotal + finalValue;
          return {
            ...finalObj,
            value: {content: parseFloat(finalValue).toFixed(2), styles: align},
          };
        }); */

      // newData.push(...rowData);
      // let valueTotal = totalCount(newData, "value");
      // let mtmTotal = totalCount(newData, "mtm");
      // }
      let rowData = uniqueData.map((item) => {
        let finalObj = {};
        let tofixColm = [
          "beAvg",
          "ledgerBalance",
          "sellAvgPrice",
          "ltp",
          "mtm",
        ];
        let data = _.omit(item, ["userId"]);
        let netQty = 0;
        let beAvg = 0;
        let finalValue = 0;
        Object.entries(data).forEach((itemData, i) => {
          let newObj1 = {};
          let netlot = 0;
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (i !== 0) {
            newObj1.styles = align;
          }
          if (itemData[0] == "script") {
            newObj1.styles = {};
          }
          // if (itemData[0] == "netLot") {
          //   netlot = itemData[1];
          // }
          if (itemData[0] == "netQty") {
            // newObj1.content = `${itemData[1]} (${netlot})`;
            newObj1.styles =
              itemData[1] > 0
                ? { textColor: [62, 62, 180], halign: "right" }
                : itemData[1] < 0
                  ? { textColor: [245, 54, 92], halign: "right" }
                  : { halign: "right" };
            netQty = itemData[1];
          } else if (itemData[0] == "beAvg") {
            beAvg = itemData[1];
          }
          if (itemData[0] == "mtm") {
            newObj1.styles =
              itemData[1] > 0
                ? { textColor: [62, 62, 180], halign: "right" }
                : itemData[1] < 0
                  ? { textColor: [245, 54, 92], halign: "right" }
                  : { halign: "right" };
            mtmTotal = mtmTotal + itemData[1];
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        finalValue = netQty * beAvg;
        if (finalValue > 0) {
          PosQtyTotal = PosQtyTotal + finalValue;
        }
        if (finalValue < 0) {
          beAvgTotal = beAvgTotal + finalValue;
        }
        valueTotal = valueTotal + finalValue;
        return {
          ...finalObj,
          value: { content: parseFloat(finalValue).toFixed(2), styles: {...align, fontStyle: "bold"} },
        };
      });
      newData.push(...rowData);
      doc.autoTable({
        head: [
          {
            script: {
              dataKey: "script",
              title: "self",
              colSpan: col.length,
              styles: {
                halign: "center",
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
              },
            },
          },
          col,
        ],
        columns: col,
        styles: { lineWidth: 0.3, lineColor: 10 },
        body: [
          ...newData,
          [
            {
              content: "",
              styles: {
                fontStyle: "bold",
                textColor: [0, 0, 0],
                halign: "right",
                fillColor: [242, 243, 165],
              },
            },
            {
              content: PosQtyTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  PosQtyTotal > 0
                    ? [62, 62, 180]
                    : PosQtyTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: beAvgTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  beAvgTotal > 0
                    ? [62, 62, 180]
                    : beAvgTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: (PosQtyTotal - beAvgTotal).toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  PosQtyTotal - beAvgTotal > 0
                    ? [62, 62, 180]
                    : PosQtyTotal - beAvgTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: valueTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  valueTotal > 0
                    ? [62, 62, 180]
                    : valueTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
            {
              content: mtmTotal.toFixed(2),
              styles: {
                fontStyle: "bold",
                halign: "right",
                fillColor: [242, 243, 165],
                textColor:
                  mtmTotal > 0
                    ? [62, 62, 180]
                    : mtmTotal < 0
                      ? [245, 54, 92]
                      : [0, 0, 0],
              },
            },
          ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
      });
    } else {
      dataMain.arrayName.forEach((element, i) => {
        let newData = [];
        let valueTotal = 0;
        let beAvgTotal = 0;
        let PosQtyTotal = 0;
        let mtmTotal = 0;
        let rowData = element.data[0].data.map((item) => {
          let finalObj = {};
          let tofixColm = [
            "beAvg",
            "ledgerBalance",
            "sellAvgPrice",
            "ltp",
            "mtm",
          ];
          let data = _.omit(item, ["userId"]);
          let netQty = 0;
          let beAvg = 0;
          let finalValue = 0;

          Object.entries(data).forEach((itemData, i) => {
            let newObj1 = {};

            newObj1.content = tofixColm.includes(itemData[0])
              ? itemData[1].toFixed(2)
              : itemData[1];
            if (i !== 0) {
              newObj1.styles = align;
            }
            if (itemData[0] == "script") {
              newObj1.styles = {};
            }
            if (itemData[0] == "netQty") {
              newObj1.styles =
                itemData[1] > 0
                  ? { textColor: [62, 62, 180], halign: "right" }
                  : itemData[1] < 0
                    ? { textColor: [245, 54, 92], halign: "right" }
                    : { halign: "right" };
              netQty = itemData[1];
            } else if (itemData[0] == "beAvg") {
              beAvg = itemData[1];
            }
            if (itemData[0] == "mtm") {
              newObj1.styles =
                itemData[1] > 0
                  ? { textColor: [62, 62, 180], halign: "right" }
                  : itemData[1] < 0
                    ? { textColor: [245, 54, 92], halign: "right" }
                    : { halign: "right" };
              mtmTotal = mtmTotal + itemData[1];
              // newObj1.styles={itemData > 0 ? dataMain.footerColorBlue : dataMain.footerColorRed}
            }
            newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
            finalObj[itemData[0]] = newObj1;
          });
          finalValue = netQty * beAvg;
          if (finalValue > 0) {
            PosQtyTotal = PosQtyTotal + finalValue;
          }
          if (finalValue < 0) {
            beAvgTotal = beAvgTotal + finalValue;
          }
          valueTotal = valueTotal + finalValue;
          return {
            ...finalObj,
            value: {
              content: parseFloat(finalValue).toFixed(2),
              styles: {...align, fontStyle: "bold"},
            },
          };
        });
        newData.push(...rowData);

        let isScriptWise = "script";

        doc.autoTable({
          head: [
            {
              [isScriptWise]: {
                dataKey: "script",
                title: element.name,
                colSpan: col.length,
                styles: {
                  halign: "center",
                  fontStyle: "bold",
                  textColor: [41, 39, 39],
                  fillColor: [168, 181, 255],
                },
              },
            },
            col,
          ],
          columns: col,
          styles: { lineWidth: 0.3, lineColor: 10 },
          body: [
            ...newData,
            [
              {
                content: "",
                styles: {
                  fontStyle: "bold",
                  textColor: [0, 0, 0],
                  halign: "right",
                  fillColor: [242, 243, 165],
                },
              },
              {
                content: PosQtyTotal.toFixed(2),
                styles: {
                  fontStyle: "bold",
                  halign: "right",
                  fillColor: [242, 243, 165],
                  textColor:
                    PosQtyTotal > 0
                      ? [62, 62, 180]
                      : PosQtyTotal < 0
                        ? [245, 54, 92]
                        : [0, 0, 0],
                },
              },
              {
                content: beAvgTotal.toFixed(2),
                styles: {
                  fontStyle: "bold",
                  halign: "right",
                  fillColor: [242, 243, 165],
                  textColor:
                    beAvgTotal > 0
                      ? [62, 62, 180]
                      : beAvgTotal < 0
                        ? [245, 54, 92]
                        : [0, 0, 0],
                },
              },
              {
                content: (PosQtyTotal - beAvgTotal).toFixed(2),
                styles: {
                  fontStyle: "bold",
                  halign: "right",
                  fillColor: [242, 243, 165],
                  textColor:
                    PosQtyTotal - beAvgTotal > 0
                      ? [62, 62, 180]
                      : PosQtyTotal - beAvgTotal < 0
                        ? [245, 54, 92]
                        : [0, 0, 0],
                },
              },
              {
                content: valueTotal.toFixed(2),
                styles: {
                  fontStyle: "bold",
                  halign: "right",
                  fillColor: [242, 243, 165],
                  textColor:
                    valueTotal > 0
                      ? [62, 62, 180]
                      : valueTotal < 0
                        ? [245, 54, 92]
                        : [0, 0, 0],
                },
              },
              {
                content: mtmTotal.toFixed(2),
                styles: {
                  fontStyle: "bold",
                  halign: "right",
                  fillColor: [242, 243, 165],
                  textColor:
                    mtmTotal > 0
                      ? [62, 62, 180]
                      : mtmTotal < 0
                        ? [245, 54, 92]
                        : [0, 0, 0],
                },
              },
            ],
          ],
          theme: "grid",
          headStyles: dataMain.headStyles,
        });
        if (i != dataMain.arrayName.length - 1) {
          doc.addPage(undefined, "p");
        }
      });
    }
  } else if (dataMain.reportType === "3110") {
    let rowData = dataMain.arrayName.map((item) => {
      let finalObj = {};
      let data = _.pick(item, [
        "userName",
        "authorityName",
        "segment",
        "symbol",
        "rateStart",
        "rateEnd",
        "positionLimit",
        "settingTypeName",
        "maxOrder",
        "minOrder",
      ]);
      Object.entries(data).forEach((itemData, i) => {
        let newObj1 = {};
        newObj1.content = itemData[1];
        finalObj[itemData[0]] = newObj1;
      });
      return finalObj;
    });
    doc.autoTable({
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: rowData,
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
  } else {
    // doc.autoTable({
    // horizontalPageBreak: dataMain.rowPageBreak,
    // theme: dataMain.theme,
    // rowPageBreak: true,
    // styles: {text: "white"},
    // startY: topHight + 55,
    // columns: dataMain.exportColumn,
    // body: dataMain.arrayName,
    // columnStyles: {text: {cellWidth: "auto"}},
    // headStyles: dataMain.headStyles,

    // dataMain.arrayName.forEach((element) => {
    // let rowData = element.data.map((item) => {
    // 	let finalObj = {};
    // 	// let tofixColm = ["buyAvgPrice", "netQty", "sellAvgPrice", "mtm", "ltp"];
    // 	let data =
    // 		dataMain.status == "Pending" ? _.omit(item, ["tradeDateTime"]) : "";
    // });
    doc.autoTable({
      head: [col],
      // pageBreak: "avoid",
      columns: col,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [...dataMain.arrayName],
      theme: "grid",
      headStyles: dataMain.headStyles,
    });
    // });
    // });
  }
  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i < pageCount + 1; i++) {
    let horizontalPos = pageWidth - 35;
    let verticalPos = pageHeight - 10;
    doc.line(30, pageHeight - 30, pageWidth - 35, pageHeight - 30);
    doc.setPage(i);
    doc.text("Page : " + String(i), 40, verticalPos, {
      align: "left",
    });
    doc.text(
      "Print Date : " + moment().format("dddd DD/MM/YYYY h:mm a"),
      horizontalPos,
      verticalPos,
      {
        align: "right",
      }
    );
  }
  doc.setProperties({
    title: "Golden Money",
  });
  // doc.save(`report_${dateStr}.pdf`);
  // doc.output("dataurlnewwindow", {
  // 	filename: `Golden Money`,
  // 	title: "Golden Money",
  // });
  // FileSaver.saveAs(doc.output("bloburl"), 'fileName + fileExtension.pdf');
  // doc.save("bloburl")
  window.open(doc.output("bloburl"));
};

function getUserWiseData(crData, type) {
  return crData.filter((item) => item.accountType == type);
}

export const generateTrialBalance = (dataMain) => {
  const doc = new jsPDF("p", "pt", "a4");
  const date = Date().split(" ");
  const align = { halign: "right" };
  const columnWithoutGroup = dataMain.column;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const topHight = 25;
  doc.setFontSize(15);
  doc.setTextColor(45);
  doc.text("Golden Money", pageWidth / 2, topHight, { align: "center" });
  doc.line(35, topHight + 10, 560, topHight + 10);
  doc.setFontSize(10);
  doc.setTextColor(40);
  dataMain.title && doc.text(dataMain.title, 40, topHight + 25);
  dataMain.title && doc.line(35, topHight + 34, 560, topHight + 34);
  if (dataMain.reportType === "1") {
    let tofixColm = ["balance"];
    let nameHeader = {};
    dataMain.dataArray.forEach((element, index) => {
      let a = element.data.map((item, i) => {
        let finalObj = {};
        let data = _.pick(item, ["userName", "balance", "userCode"]);
        Object.entries(data).forEach((itemData) => {
          let newObj1 = {};
          newObj1.content = tofixColm.includes(itemData[0])
            ? itemData[1].toFixed(2)
            : itemData[1];
          if (itemData[0] === "userName" || itemData[0] === "userCode") {
            newObj1.styles = {};
          } else {
            newObj1.styles = align;
          }
          if (itemData[0] === "balance" && (item.crDr == "Dr" || item.crDr == "Cr")) {
            finalObj["Cr"] = item.crDr === "Cr" ? { content: Math.round(itemData[1]), styles: { halign: "right", textColor: [62, 62, 180], fontStyle: "bold" } } : { content: 0, styles: { halign: "right", textColor: [62, 62, 180], fontStyle: "bold" } };
            finalObj["Dr"] = item.crDr === "Cr" ? { content: 0, styles: { halign: "right", textColor: [245, 54, 92], fontStyle: "bold" } } : { content: Math.round(itemData[1]), styles: { halign: "right", textColor: [245, 54, 92], fontStyle: "bold" } };
            finalObj["userName"] = {content:item["userName"], styles:{fontStyle: "bold" }};
          }
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        if(i === 0){
          nameHeader.head =  [{
            userCode: {
              content: element.name,
              colSpan: 4,
              styles: {
                fontStyle: "bold",
                textColor: [41, 39, 39],
                fillColor: [168, 181, 255],
                halign: "center",
              },
            },
          } ,columnWithoutGroup]
        }
        return finalObj;
      });
    let finalDebitDataRow = [...a];
    doc.autoTable({
      horizontalPageBreak: true,
      theme: dataMain.theme,
      rowPageBreak: true,
      styles: { text: "white" },
      startY: topHight + 25,
      headStyles: dataMain.headStyles,
    });
    doc.autoTable({
      ...nameHeader,
      columns: dataMain.column,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...finalDebitDataRow,
        [
          {
            content: `Total: ${finalDebitDataRow.length}`,
            colSpan: 2,
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              fillColor: [255, 255, 255],
            },
          },
          {
            content: totalCountForBro(finalDebitDataRow, "Dr"),
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              textColor: [245, 54, 92],
              fillColor: [255, 255, 255],
            },
          },
          {
            content: totalCountForBro(finalDebitDataRow, "Cr"),
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              textColor: [62, 62, 180],
              fillColor: [255, 255, 255],
            },
          }
        ],
        [{
          title: "",
          colSpan: 4,
          styles: {
            lineColor: [255, 255, 255],
            lineWidth: 0,
          }
        }],
        [
          {
            content: 'Debit',
            colSpan: 3,
            styles: {
              fillColor: [240, 240, 240],
              fontStyle: "bold",
              textColor: [245, 54, 92],
            },
          },
          {
            content: totalCountForBro(finalDebitDataRow, "Dr"),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [245, 54, 92],
            },
          },
        ],
        [
          {
            content: 'Credit',
            colSpan: 3,
            styles: {
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [62, 62, 180],
            },
          },
          {
            content: totalCountForBro(finalDebitDataRow, "Cr"),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [62, 62, 180],
            },
          },
        ],
        [
          {
            content: 'Difference',
            colSpan: 3,
            styles: {
              fontStyle: "bold",
              fillColor: [242, 243, 165],
              textColor: [0, 0, 0],
            },
          },
          {
            content: (totalCountForBro(finalDebitDataRow, "Cr") - totalCountForBro(finalDebitDataRow, "Dr")),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [242, 243, 165],
              textColor: (totalCountForBro(finalDebitDataRow, "Cr") - totalCountForBro(finalDebitDataRow, "Dr")) > 0 ? [62, 62, 180] : [245, 54, 92],
            },
          },
        ],
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
      showHead: "firstPage",
    });
    if (index != dataMain.dataArray.length - 1) {
      doc.addPage(undefined, "p");
    }
    })
  } else if (dataMain.reportType === "2") {
    let tofixColm = ["balance"];
    let nameHeader = {};
    dataMain.dataArray.forEach((element, index) => {
    let rowData = element.data.map((item, i) => {
      let finalObj = {};
      let data = _.pick(item, ["userName", "balance", "userCode"]);
      Object.entries(data).forEach((itemData) => {
        let newObj1 = {};
        newObj1.content = tofixColm.includes(itemData[0])
          ? itemData[1].toFixed(2)
          : itemData[1];
        if (itemData[0] === "userName" || itemData[0] === "userCode") {
          newObj1.styles = {};
        } else {
          newObj1.styles = align;
        }
        if (itemData[0] === "balance" && (item.crDr == "Dr" || item.crDr == "Cr")) {
          finalObj["Cr"] = item.crDr === "Cr" ? { content: Math.round(itemData[1]), styles: { halign: "right", textColor: [62, 62, 180], fontStyle: "bold" } } : { content: 0, styles: { halign: "right", textColor: [62, 62, 180], fontStyle: "bold" } };
          finalObj["Dr"] = item.crDr === "Cr" ? { content: 0, styles: { halign: "right", textColor: [245, 54, 92], fontStyle: "bold" } } : { content: Math.round(itemData[1]), styles: { halign: "right", textColor: [245, 54, 92], fontStyle: "bold" } };
          finalObj["userName"] = {content:item["userName"], styles:{fontStyle: "bold" }};
        }
        newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
        finalObj[itemData[0]] = newObj1;
      });
      if(i === 0){
        nameHeader.head =  [{
          userCode: {
            content: element.name,
            colSpan: 4,
            styles: {
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
              halign: "center",
            },
          },
        }, columnWithoutGroup]
      }
      return finalObj;
    });
    doc.autoTable({
      horizontalPageBreak: true,
      theme: dataMain.theme,
      rowPageBreak: true,
      styles: { text: "white" },
      // avoidPageSplit: true,
      startY: topHight + 25,
      headStyles: dataMain.headStyles,
    });
    doc.autoTable({
      ...nameHeader,
      columns: columnWithoutGroup,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...rowData,
        [
          {
            content: `Total: ${rowData.length}`,
            colSpan: 2,
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              fillColor: [255, 255, 255],
            },
          },
          {
            content: totalCountForBro(rowData, "Dr"),
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              textColor: [245, 54, 92],
              fillColor: [255, 255, 255],
            },
          },
          {
            content: totalCountForBro(rowData, "Cr"),
            styles: {
              lineWidth: 2,
              lineColor: "black",
              halign: "right",
              fontStyle: "bold",
              textColor: [62, 62, 180],
              fillColor: [255, 255, 255],
            },
          },
        ],
        [{
          title: "",
          colSpan: 4,
          styles: {
            lineColor: [255, 255, 255],
            lineWidth: 0,
          }
        }],
        [
          {
            content: 'Debit',
            colSpan: 3,
            styles: {
              fillColor: [240, 240, 240],
              fontStyle: "bold",
              textColor: [245, 54, 92],
            },
          },
          {
            content: totalCountForBro(rowData, "Dr"),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [245, 54, 92],
            },
          },
        ],
        [
          {
            content: 'Credit',
            colSpan: 3,
            styles: {
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [62, 62, 180],
            },
          },
          {
            content: totalCountForBro(rowData, "Cr"),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [240, 240, 240],
              textColor: [62, 62, 180],
            },
          },
        ],
        [
          {
            content: 'Difference',
            colSpan: 3,
            styles: {
              fontStyle: "bold",
              fillColor: [242, 243, 165],
              textColor: [0, 0, 0],
            },
          },
          {
            content: (totalCountForBro(rowData, "Cr") - totalCountForBro(rowData, "Dr")),
            styles: {
              halign: "right",
              fontStyle: "bold",
              fillColor: [242, 243, 165],
              textColor: (totalCountForBro(rowData, "Cr") - totalCountForBro(rowData, "Dr")) > 0 ? [62, 62, 180] : [245, 54, 92],
            },
          },
        ],
        ],
        theme: "grid",
        headStyles: dataMain.headStyles,
        showHead: "firstPage",
      });
      if (index != dataMain.dataArray.length - 1) {
      doc.addPage(undefined, "p");
      }
    })
  }
  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i < pageCount + 1; i++) {
    let horizontalPos = pageWidth - 35;
    let verticalPos = pageHeight - 10;
    doc.line(30, pageHeight - 30, 560, pageHeight - 30);
    doc.setPage(i);
    doc.text("Page : " + String(i), 40, verticalPos, {
      align: "left",
    });
    doc.text(
      "Print Date : " + moment().format("dddd DD/MM/YYYY h:mm a"),
      horizontalPos,
      verticalPos,
      {
        align: "right",
      }
    );
  }
  doc.setProperties({
    title: "Golden Money",
  });
  // doc.save(`report_${dateStr}.pdf`);
  // doc.output("dataurlnewwindow", {
  // 	filename: `Golden Money`,
  // 	title: "Golden Money",
  // });
  window.open(doc.output("bloburl"));
};
export const generateLedgerBalance = (dataMain) => {
  const doc = new jsPDF("l", "pt", "a4");
  const date = Date().split(" ");
  const align = { halign: "right" };
  const textBlue = { textColor: [62, 62, 180], ...align };
  const textRed = { textColor: [245, 54, 92], ...align };
  const columnWithoutGroup = dataMain.column;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const topHight = 25;
  doc.setFontSize(15);
  doc.setTextColor(45);
  doc.text("Golden Money", pageWidth / 2, topHight, { align: "center" });
  doc.line(35, topHight + 10, pageWidth - 35, topHight + 10);
  doc.setFontSize(10);
  doc.setTextColor(40);
  dataMain.title && doc.text(dataMain.title, 40, topHight + 25);
  dataMain.date && doc.text(dataMain.date, pageWidth / 2, topHight + 25);
  dataMain.fromTo && doc.text(dataMain.fromTo, pageWidth - 180, topHight + 25);
  (dataMain.title || dataMain.date || dataMain.fromTo) &&
    doc.line(35, topHight + 34, pageWidth - 35, topHight + 34);
  let nameHeader = {};
      let a = dataMain.dataArray.map((item, i) => {
        let finalObj = {};
        let tofixColm = ["debitAmount", "creditAmount", "balance"];
        Object.entries(item).forEach((itemData) => {
          let newObj1 = {};
          if(tofixColm.includes(itemData[0])){
            newObj1 = { content: Math.round(itemData[1]), styles: (itemData[0] === "debitAmount" ? {...align, ...textRed} : 
            itemData[0] === "creditAmount" ? {...align, ...textBlue} : item['crDr'] === "Cr" ? 
            {...align, ...textBlue} :{...align, ...textRed})}
          }
          if(itemData[0] === "particulars"){
            newObj1.content = itemData[1] ? itemData[1] : '-'
          }
          if(itemData[0] === "date"){
            newObj1.content = itemData[1] ? moment(itemData[1]).format("DD-MM-YYYY") : '-'
          }
          if(itemData[0] === "srNo" || itemData[0] === "document"){
            newObj1 = {content:itemData[1] ? itemData[1] : '-', styles:{ align }}
          }
          if(itemData[0] === "crDr"){
            newObj1 = item['crDr'] === "Cr" ? {content:itemData[1], styles:{...textBlue, halign: 'center'}} : {content:itemData[1], styles:{...textRed, halign: 'center'}
          }}
          newObj1.styles = {...newObj1.styles, fontStyle: "bold"}
          finalObj[itemData[0]] = newObj1;
        });
        return finalObj;
      });
      let finalDebitDataRow = [...a];
    doc.autoTable({
      horizontalPageBreak: true,
      theme: dataMain.theme,
      rowPageBreak: true,
      styles: { text: "white" },
      startY: topHight + 25,
      headStyles: dataMain.headStyles,
    });
    doc.autoTable({
      head: [
        {
          date: {
            dataKey: "accountCode",
            title: dataMain.clientName,
            colSpan: dataMain.columnData.length,
            styles: {
              halign: "center",
              fontStyle: "bold",
              textColor: [41, 39, 39],
              fillColor: [168, 181, 255],
            },
          },
        },
        dataMain.columnData,
      ],
      columns: dataMain.columnData,
      styles: { lineWidth: 0.3, lineColor: 10 },
      body: [
        ...finalDebitDataRow,
       [
          {
            content: "Total :",
            colSpan: 3,
            styles: { fillColor: [242, 243, 165], fontStyle: "bold", halign: "center" }
          },
          {
            content: totalCountForBro(finalDebitDataRow, "debitAmount"),
            styles: dataMain.footerColorRed,
          },
          {
            content: totalCountForBro(finalDebitDataRow, "creditAmount"),
            styles:dataMain.footerColorBlue,
          },
          {
            content: '',
            colSpan: 3,
            styles:{ fillColor: [242, 243, 165], fontStyle: "bold" },
          }
        ],
        
      ],
      theme: "grid",
      headStyles: dataMain.headStyles,
      showHead: "firstPage",
    });
  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i < pageCount + 1; i++) {
    let horizontalPos = pageWidth - 35;
    let verticalPos = pageHeight - 10;
    doc.line(30, pageHeight - 30, pageWidth - 35, pageHeight - 30);
    doc.setPage(i);
    doc.text("Page : " + String(i), 40, verticalPos, {
      align: "left",
    });
    doc.text(
      "Print Date : " + moment().format("dddd DD/MM/YYYY h:mm a"),
      horizontalPos,
      verticalPos,
      {
        align: "right",
      }
    );
  }
  doc.setProperties({
    title: "Golden Money",
  });
  window.open(doc.output("bloburl"));
};

export const generateExcel = (
  responseData,
  labelName,
  reportTitle,
  valanLabel,
  fromToLabel,
  accountTypeLabel
) => {
  let tableData = [];
  let dataInfo = {};
  let cellColorRanges = [];
  let greyColorRanges = [];
  let purpleColorRanges = [];
  let yellowColorRanges = [];
  let tBodyRanges = [];
  let cellMergeRanges = [];
  let columnStyles = [];
  let companyTitleRange = undefined;
  let valanItem = undefined;
  let titleRange = undefined;
  if (labelName === "11" || labelName === "13") {
    const blankRow = {
      name: "",
      buy_qty: "",
      buy_avg: "",
      sell_qty: "",
      sell_avg: "",
      pos_qty: "",
      ltp: "",
      m2m: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        name: reportTitle,
        buy_avg: valanLabel,
        pos_qty: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    const nameHeader = labelName === "11" ? "Script Name" : "Client Name";
    responseData?.forEach((x) => {
      let totalBuyQty = 0,
        totalSellQty = 0,
        totalPosQty = 0,
        totalNetAmount = 0,
        posQtys = [],
        mtms = [];
      let scriptTable = [
        { ...blankRow, name: x?.name },
        {
          name: nameHeader,
          buy_qty: "Buy Qty",
          buy_avg: "Buy Avg.",
          sell_qty: "Sell Qty",
          sell_avg: "Sell Avg.",
          pos_qty: "Pos Qty",
          ltp: "LTP",
          m2m: "M2M",
        },
      ];
      x?.data?.forEach((y = {}) => {
        totalBuyQty += y.totalBuyQty;
        totalSellQty += y.totalSellQty;
        totalPosQty += y.netQty;
        totalNetAmount += y.mtm;

        posQtys.push(y.netQty);
        mtms.push(y.mtm);

        scriptTable.push({
          name: labelName === "11" ? y.script : y.clientName,
          buy_qty: y.totalBuyQty,
          buy_avg: y.buyAvgPrice?.toFixed(2),
          sell_qty: y.totalSellQty,
          sell_avg: y.sellAvgPrice?.toFixed(2),
          pos_qty: y.netQty?.toFixed(2),
          ltp: y.ltp?.toFixed(2),
          m2m: y.mtm?.toFixed(2),
        });
      });

      perTableData.push({
        totalItems: x?.data?.length,
        totalNetAmount,
        posQtys,
        mtms,
      });
      scriptTable.push(
        {
          ...blankRow,
          name: totalBuyQty?.toFixed(2),
          buy_avg: totalSellQty?.toFixed(2),
          sell_avg: totalPosQty?.toFixed(2),
          ltp: totalNetAmount?.toFixed(2),
        },
        blankRow
      );
      finalData.push(...scriptTable);
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.buy_qty,
        D: x.buy_avg,
        E: x.sell_qty,
        F: x.sell_avg,
        G: x.pos_qty,
        H: x.ltp,
        I: x.m2m,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script Name" || bData === "Client Name")
        headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 10, hrAlign: "right" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 10, hrAlign: "right" },
      { column: "I", width: 10, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:I2" },
      { val: "B4:C4", align: "left", bold: false },
      { val: "G4:I4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:I${x + 1}`,
        `B${scriptTotalId}:G${scriptTotalId}`
      );
      yellowColorRanges.push({
        range: `H${scriptTotalId}:I${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalNetAmount || 0) < 0 ? "FF0000" : "0000FF",
      });
      purpleColorRanges.push(`B${x}:I${x}`);
      tBodyRanges.push(`B${x}:I${scriptTotalId}`);
      cellMergeRanges.push(
        { val: `B${x - 1}:I${x - 1}`, align: "left" },
        { val: `B${scriptTotalId}:C${scriptTotalId}`, align: "right" },
        { val: `D${scriptTotalId}:E${scriptTotalId}`, align: "right" },
        { val: `F${scriptTotalId}:G${scriptTotalId}`, align: "right" },
        { val: `H${scriptTotalId}:I${scriptTotalId}`, align: "right" }
      );
    });
    perTableData.forEach((x, index) => {
      const headerIndex = headerIndexes[index] + 1;
      for (let i = 0; i < x.totalItems; i++) {
        cellColorRanges.push(
          {
            val: `G${headerIndex + i + 1}`,
            color: x.posQtys[i] < 0 ? "FF0000" : "0000FF",
            isRange: false,
            hAlign: "right",
          },
          {
            val: `I${headerIndex + i + 1}`,
            color: x.mtms[i] < 0 ? "FF0000" : "0000FF",
            isRange: false,
            hAlign: "right",
          }
        );
      }
    });
    companyTitleRange = "B2:I2";
    valanItem = { value: "D4:F4", isRange: true };
    titleRange = "B4:I4";
  } else if (labelName === "12") {
    const blankRow = { name: "", pos_qty: "", be_avg: "", ltp: "", m2m: "" };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      { ...blankRow, name: reportTitle, pos_qty: valanLabel, ltp: fromToLabel },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      let totalNetAmount = 0,
      totalPosQty = 0,
      totalBeAvg = 0,
      totalLtp = 0,
        posQtys = [],
        mtms = [],
        finalValue = 0;
      let scriptTable = [
        { ...blankRow, name: x?.name },
        {
          name: "Script Name",
          pos_qty: "Pos Qty",
          be_avg: "B.E. Avg",
          value: "Value",
          ltp: "LTP",
          m2m: "M2M",
        },
      ];
      x?.data?.forEach((y = {}) => {
        totalNetAmount += y.mtm;
        finalValue = y?.netQty * y?.beAvg;
        posQtys.push(y.netQty);
        mtms.push(y.mtm);
        if (finalValue > 0) {
          totalPosQty += finalValue;
        }
        if (finalValue < 0) {
          totalBeAvg += finalValue;
        }
        totalLtp += finalValue
        scriptTable.push({
          name: y.script,
          pos_qty: y.netQty?.toFixed(2),
          be_avg: y.beAvg?.toFixed(2),
          value: finalValue?.toFixed(2),
          ltp: y.ltp?.toFixed(2),
          m2m: y.mtm?.toFixed(2),
        });
      });
      perTableData.push({
        totalItems: x?.data?.length,
        totalNetAmount,
        totalPosQtyValue: totalPosQty?.toFixed(2),
        totalBeAvgValue: totalBeAvg?.toFixed(2),
        totalValue: (totalPosQty - totalBeAvg)?.toFixed(2),
        totalLtpValue: totalLtp?.toFixed(2),
        posQtys,
        mtms,
      });
      scriptTable.push({ 
        ...blankRow, 
        pos_qty: totalPosQty?.toFixed(2),
        be_avg: totalBeAvg?.toFixed(2),
        value: (totalPosQty - totalBeAvg)?.toFixed(2),
        ltp: totalLtp?.toFixed(2),
        m2m: totalNetAmount?.toFixed(2)   
      }, blankRow);
      finalData.push(...scriptTable);
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.pos_qty,
        D: x.be_avg,
        E: x.value,
        F: x.ltp,
        G: x.m2m,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script Name") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 15, hrAlign: "right" },
      { column: "D", width: 15, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 15, hrAlign: "right" },
      { column: "G", width: 15, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:F2" },
      { val: "B4:B4", align: "left", bold: false },
      { val: "E4:F4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(`B${x + 1}:G${x + 1}`);
      purpleColorRanges.push(`B${x}:G${x}`);
      tBodyRanges.push(`B${x}:G${scriptTotalId}`);
      cellMergeRanges.push({
        val: `B${scriptTotalId}:G${scriptTotalId}`,
        align: "right",
      });
      yellowColorRanges.push({
        range: `B${scriptTotalId}:B${scriptTotalId}`,
      },{
        range: `C${scriptTotalId}:C${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalPosQtyValue || 0) < 0 ? "FF0000" : "0000FF",
      },{
        range: `D${scriptTotalId}:D${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalBeAvgValue || 0) < 0 ? "FF0000" : "0000FF",
      },{
        range: `E${scriptTotalId}:E${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalValue || 0) < 0 ? "FF0000" : "0000FF",
      },{
        range: `F${scriptTotalId}:F${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalLtpValue || 0) < 0 ? "FF0000" : "0000FF",
      },{
        range: `G${scriptTotalId}:F${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalNetAmount || 0) < 0 ? "FF0000" : "0000FF",
      });
    });
    perTableData.forEach((x, index) => {
      const headerIndex = headerIndexes[index] + 1;
      for (let i = 0; i < x.totalItems; i++) {
        cellColorRanges.push(
          {
            val: `C${headerIndex + i + 1}`,
            color: x.posQtys[i] < 0 ? "FF0000" : "0000FF",
            isRange: false,
            hAlign: "right",
          },
          {
            val: `G${headerIndex + i + 1}`,
            color: x.mtms[i] < 0 ? "FF0000" : "0000FF",
            isRange: false,
            hAlign: "right",
          }
        );
      }
    });
    companyTitleRange = "B2:F2";
    valanItem = { value: "C4:D4", isRange: true };
    titleRange = "B4:G4";
  } else if (labelName === "14" || labelName === "15") {
    const blankRow = { name: "", buy: "", sell: "" };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      { ...blankRow, name: reportTitle, buy: valanLabel, sell: fromToLabel },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    if (labelName === "14") {
      responseData?.forEach((x) => {
        let totalBuyAmt = 0,
          totalSellAmt = 0,
          buy = [],
          sell = [];
        let scriptTable = [
          { ...blankRow, name: x?.name },
          { name: "Script Name", buy: "Total Buy", sell: "Total Sell" },
        ];
        x?.data?.forEach((y = {}) => {
          totalBuyAmt += y.totalBuy;
          totalSellAmt += y.totalSell;
          buy.push(y.totalBuy);
          sell.push(y.totalSell);
          scriptTable.push({
            name: y.script,
            buy: y.totalBuy?.toFixed(2),  
            sell: y.totalSell?.toFixed(2),
          });
        });
        const totalNetAmount = totalBuyAmt + totalSellAmt;
        perTableData.push({ totalItems: x?.data?.length, totalNetAmount, buy, sell });
        scriptTable.push({
          ...blankRow,
          name: "Total :",
          buy: totalBuyAmt?.toFixed(2),
          sell: totalSellAmt?.toFixed(2),
        });
        scriptTable.push({ ...blankRow, buy: totalNetAmount?.toFixed(2) }, blankRow);
        finalData.push(...scriptTable);
      });
      excelData.push(...finalData);
      excelData.forEach((x) => {
        tableData.push({
          A: "",
          B: x.name,
          C: x.buy,
          D: x.sell,
        });
      });
      let headerIndexes = [];
      tableData.forEach((data, index) => {
        const bData = String(data["B"] || "");
        if (bData === "Script Name" || bData === "Client Name")
          headerIndexes.push(index);
      });
      columnStyles = [
        { column: "A", width: 2 },
        { column: "B", width: 25, hrAlign: "left" },
        { column: "C", width: 15, hrAlign: "right" },
        { column: "D", width: 15, hrAlign: "right" },
      ];
      cellMergeRanges = [
        { val: "B2:D2" },
        { val: "B4:B4", align: "left", bold: false },
        { val: "E4:E4", align: "right", bold: false },
      ];
      headerIndexes.forEach((x, i) => {
        const totalItems = perTableData?.[i]?.totalItems;
        const scriptTotalId = x + totalItems + 2;
        greyColorRanges.push(
          `B${x + 1}:D${x + 1}`,
          `B${scriptTotalId}:D${scriptTotalId}`
          );
          purpleColorRanges.push(`B${x}:D${x}`);
          tBodyRanges.push(`B${x}:D${scriptTotalId + 1}`);
        yellowColorRanges.push({
          range: `C${scriptTotalId + 1}:D${scriptTotalId + 1}`,
          fontColor:
            Number(perTableData[i].totalNetAmount || 0) < 0 ? "FF0000" : "0000FF",
          hAlign: "right",
        });
      });
      perTableData.forEach((x, index) => {
        const headerIndex = headerIndexes[index] + 1;
        for (let i = 0; i < x.totalItems; i++) {
          cellColorRanges.push(
            {
              val: `C${headerIndex + i + 1}`,
              color: "0000FF",
              isRange: false,
              hAlign: "right",
            },
            {
              val: `D${headerIndex + i + 1}`,
              color: "FF0000",
              isRange: false,
              hAlign: "right",
            }
          );
        }
      });
      companyTitleRange = "B2:D2";
      valanItem = { value: "C4:C4", isRange: true };
      titleRange = "B4:D4";
    } else if (labelName === "15") {
      let totalBuyAmt = 0,
        totalSellAmt = 0,
        buy = [],
        sell = [],
        netHodiyu = [];
      let scriptTable = [
        { ...blankRow, name: "CLIENT" },
        { code: "Client Code", name: "Client Name", buy: "Total Buy", sell: "Total Sell", netHodiyu: "Net Hodiyu" },
      ];
      responseData?.forEach((x) => {
        totalBuyAmt += x.totalBuy;
        totalSellAmt += x.totalSell;
        buy.push(x.totalBuy)
        sell.push(x.totalSell)
        netHodiyu.push(x.totalBuy - x.totalSell)
        scriptTable.push({
          code: x.accountCode,
          name: x.clientName,
          buy: x.totalBuy?.toFixed(2),
          sell: x.totalSell?.toFixed(2),
          netHodiyu: (x.totalBuy - x.totalSell)?.toFixed(2)
        });
      });
      const totalNetAmount = totalBuyAmt + totalSellAmt;
      perTableData.push({ totalItems: responseData?.length, totalNetAmount, buy, sell, netHodiyu });
      scriptTable.push({
        ...blankRow,
        code: "",
        name: "Total :",
        buy: totalBuyAmt?.toFixed(2),
        sell: totalSellAmt?.toFixed(2),
        netHodiyu: (totalBuyAmt - totalSellAmt)?.toFixed(2)
      });
      scriptTable.push({
      code:"",
      name:"Total Hodiyu :",
      buy: (totalBuyAmt + totalSellAmt)?.toFixed(2),
      sell:"",
      netHodiyu:"",
      });
      finalData.push(...scriptTable);
      finalData.push(
        blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow
      );
      excelData.push(...finalData);
      excelData.forEach((x) => {
        tableData.push({
          A: "",
          B: x.code,
          C: x.name,
          D: x.buy,
          E: x.sell,
          F: x.netHodiyu
        });
      });
      let headerIndexes = [];
      tableData.forEach((data, index) => {
        const bData = String(data["B"] || "");
        if (bData === "Script Name" || bData === "Client Code")
          headerIndexes.push(index);
      });
      columnStyles = [
        { column: "A", width: 2 },
        { column: "B", width: 15, hrAlign: "left" },
        { column: "C", width: 35, hrAlign: "left" },
        { column: "D", width: 15, hrAlign: "right" },
        { column: "E", width: 15, hrAlign: "right" },
        { column: "F", width: 15, hrAlign: "right" },
      ];
      cellMergeRanges = [
        { val: "B2:F2" },
        { val: "B4:B4", align: "left", bold: false },
        { val: "E4:F4", align: "right", bold: false },
      ];
      headerIndexes.forEach((x, i) => {
        const totalItems = perTableData?.[i]?.totalItems;
        const scriptTotalId = x + totalItems + 2;
        greyColorRanges.push(
          `B${x + 1}:F${x + 1}`,
          `B${scriptTotalId}:E${scriptTotalId}`
        );
        purpleColorRanges.push(`B${x}:F${x}`);
        tBodyRanges.push(`B${x}:F${scriptTotalId + 1}`);
        yellowColorRanges.push(
          {
            range: `F${scriptTotalId}:F${scriptTotalId}`,
            fontColor:
              Number(totalBuyAmt - totalSellAmt || 0) < 0 ? "FF0000" : "0000FF",
            hAlign: "right",
          },
          {
            val:(totalBuyAmt + totalSellAmt)?.toFixed(2),
            range: `D${scriptTotalId + 1}:E${scriptTotalId + 1}`,
            fontColor:
              Number(totalBuyAmt + totalSellAmt || 0) < 0 ? "FF0000" : "0000FF",
            hAlign: "right",
        });
      });
      perTableData.forEach((x, index) => {
        const headerIndex = headerIndexes[index] + 1;
        for (let i = 0; i < x.totalItems; i++) {
          cellColorRanges.push(
            {
              val: `D${headerIndex + i + 1}`,
              color: "0000FF",
              isRange: false,
              hAlign: "right",
            },
            {
              val: `E${headerIndex + i + 1}`,
              color: "FF0000" ,
              isRange: false,
              hAlign: "right",
            },
            {
              val: `F${headerIndex + i + 1}`,
              color: x.netHodiyu[i] < 0 ? "FF0000" : "0000FF",
              isRange: false,
              hAlign: "right",
            }
          );
        }
      });
      companyTitleRange = "B2:F2";
      valanItem = { value: "C4:C4", isRange: true };
      titleRange = "B4:F4";
    } 
  } else if (
    labelName === "21" ||
    labelName === "23" ||
    labelName === "25" ||
    labelName === "29" ||
    labelName === "210" ||
    labelName === "211" ||
    labelName === "212"
  ) {
    const isLedger =
      labelName === "23" || labelName === "211" || labelName === "212";
    const isBrokerage = labelName === "25";
    const blankRow = {
      date: "",
      type: "",
      lot: "",
      buy_qty: "",
      sell_qty: "",
      rate: "",
      net_rate: "",
      net_amount: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, date: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        date: reportTitle,
        buy_qty: valanLabel,
        net_rate: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    const totalBillAmounts = [];
    const ledgerBalances = [];
    const totalBrokerages = [];
    const totalGrosses = [];
    responseData?.forEach((x, i) => {
      let totalBillAmount = 0,
        ledgerBalance = 0,
        totalBrokerage = 0,
        totalGross = 0;
      x?.data?.forEach((y = {}) => {
        let totalBuyQty = 0,
          totalSellQty = 0,
          totalNetAmount = 0,
          totalBuyBrokerage = 0,
          totalSellBrokerage = 0;
        let scriptTable = [
          { ...blankRow, date: x?.name },
          { ...blankRow, date: y?.name },
          {
            date: "Date",
            type: "Type",
            lot: "Lot",
            buy_qty: "Buy Qty",
            sell_qty: "Sell Qty",
            rate: "Rate",
            net_rate: "Net Rate",
            net_amount: "Net Amount",
          },
        ];
        y?.data?.forEach((z = {}) => {
          totalBuyQty += z.buyQuantity;
          totalSellQty += z.sellQuantity;
          totalNetAmount += z.netAmount;
          ledgerBalance = z.ledgerBalance;
          if (z.orderTypeName === "Buy")
            totalBuyBrokerage += (z.netRate - z.rate) * z.buyQuantity;
          else totalSellBrokerage += (z.rate - z.netRate) * z.sellQuantity;
          scriptTable.push({
            date: moment(z.tradeDate).format("YYYY-MM-DD HH:mm:ss"),
            type: z.tradeOptionName,
            lot: z.lot,
            buy_qty: z.buyQuantity,
            sell_qty: z.sellQuantity,
            rate: Number(z.rate || 0).toFixed(2),
            net_rate: Number(z.netRate || 0).toFixed(2),
            net_amount: Number(z.netAmount || 0).toFixed(2),
          });
        });
        perTableData.push({ totalItems: y?.data?.length, totalNetAmount });
        totalBillAmount += totalNetAmount;
        if (!isBrokerage) {
          scriptTable.push(
            {
              ...blankRow,
              date: "Script wise total :",
              buy_qty: totalBuyQty?.toFixed(2),
              sell_qty: totalSellQty?.toFixed(2),
              rate: totalNetAmount?.toFixed(2),
            },
            blankRow
          );
        } else {
          const brokerage = +Number(
            totalSellBrokerage + totalBuyBrokerage || 0
          ).toFixed(2);
          totalBrokerage += brokerage;
          scriptTable.push(
            {
              ...blankRow,
              date: "Script wise total :",
              buy_qty: totalBuyQty?.toFixed(2),
              sell_qty: totalSellQty?.toFixed(2),
              rate: (brokerage + totalNetAmount)?.toFixed(2),
              net_rate: brokerage?.toFixed(2),
              net_amount: totalNetAmount?.toFixed(2),
            },
            blankRow
          );
        }
        finalData.push(...scriptTable);
      });
      totalBrokerages.push(totalBrokerage);
      totalBillAmounts.push(totalBillAmount);
      ledgerBalances.push(ledgerBalance);
      finalData.push({ ...blankRow, date: x?.name });
      if (!isBrokerage) {
        finalData.push(
          {
            ...blankRow,
            date: `Total bill Amount : ${Number(totalBillAmount || 0).toFixed(
              2
            )}`,
          },
          blankRow
        );
      } else {
        totalGross = mathRound(totalBillAmount - totalBrokerage || 0);
        totalGrosses.push(totalGross);
        finalData.push(
          {
            ...blankRow,
            date: `Total Gross : ${totalGross}`,
            buy_qty: `Total Brok. : ${mathRound(totalBrokerage || 0)}`,
            net_rate: `Net Amount : ${mathRound(totalBillAmount || 0)}`,
          },
          blankRow
        );
      }
      if (isLedger) {
        finalData.push(
          { ...blankRow, date: x?.name },
          { ...blankRow, date: "Ledger" },
          {
            ...blankRow,
            date: "Date",
            type: "Doc No",
            lot: "Particulars",
            sell_qty: "Debit",
            rate: "Credit",
            net_rate: "Balance",
            net_amount: "Description",
          },
          {
            ...blankRow,
            lot: "Opening Balance",
            sell_qty: 0 > ledgerBalances[i] ? ledgerBalances[i] : 0,
            rate: 0 < ledgerBalances[i] ? ledgerBalances[i] : 0,
            net_rate: ledgerBalances[i],
          },
          {
            ...blankRow,
            sell_qty: 0 > ledgerBalances[i] ? ledgerBalances[i] : 0,
            rate: 0 < ledgerBalances[i] ? ledgerBalances[i] : 0,
            net_rate: ledgerBalances[i],
          }
        );
      }
      finalData.push(blankRow);
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.date,
        C: x.type,
        D: x.lot,
        E: x.buy_qty,
        F: x.sell_qty,
        G: x.rate,
        H: x.net_rate,
        I: x.net_amount,
      });
    });
    let headerIndexes = [];
    let ledgerIndexes = [];
    let totalAmountIndexes = [];
    let brokerageIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Date" && data["C"] === "Doc No") ledgerIndexes.push(index);
      else if (bData === "Date") headerIndexes.push(index);
      else if (bData?.startsWith("Total bill Amount"))
        totalAmountIndexes.push(index);
      else if (bData?.startsWith("Total Gross")) brokerageIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 15, hrAlign: "left" },
      { column: "C", width: 10, hrAlign: "right" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 15, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 15, hrAlign: "right" },
      { column: "I", width: 20, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:I2" },
      { val: "B4:D4", align: "left", bold: false },
      { val: "H4:I4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      greyColorRanges.push(
        `B${x + 1}:I${x + 1}`,
        `B${x + totalItems + 2}:F${x + totalItems + 2}`
      );
      yellowColorRanges.push({
        range: `G${x + totalItems + 2}:I${x + totalItems + 2}`,
        fontColor: !isBrokerage
          ? Number(perTableData[i].totalNetAmount || 0) < 0
            ? "FF0000"
            : "0000FF"
          : "",
        merged: false,
        hAlign: "right",
      });
      purpleColorRanges.push(`B${x}:I${x}`);
      tBodyRanges.push(`B${x - 1}:I${x + totalItems + 2}`);
      const scriptWiseTotalAmt = isBrokerage
        ? []
        : [
          {
            val: `G${x + totalItems + 2}:I${x + totalItems + 2}`,
            align: "right",
          },
        ];
      cellMergeRanges.push(
        { val: `B${x - 1}:I${x - 1}`, align: "left" },
        { val: `B${x}:I${x}` },
        { val: `B${x + totalItems + 2}:D${x + totalItems + 2}`, align: "left" },
        ...scriptWiseTotalAmt
      );
      const netAmtCell = isBrokerage
        ? [
          {
            val: `I${x + totalItems + 2}`,
            color:
              Number(perTableData[i].totalNetAmount || 0) < 0
                ? "FF0000"
                : "0000FF",
            isRange: false,
            bold: true,
            hAlign: "right"
          },
        ]
        : [];
      cellColorRanges.push(
        { val: `E${x + 2}:E${x + 1 + totalItems}`, color: "0000FF", hAlign: "right" },
        { val: `F${x + 2}:F${x + 1 + totalItems}`, color: "FF0000", hAlign: "right" },
        ...netAmtCell
      );
    });
    totalAmountIndexes.forEach((x, i) => {
      cellMergeRanges.push(
        { val: `B${x + 1}:I${x + 1}` },
        { val: `B${x}:I${x}`, align: "left" }
      );
      cellColorRanges.push({
        val: `B${x + 1}:I${x + 1}`,
        color: Number(totalBillAmounts[i] || 0) < 0 ? "FF0000" : "0000FF",
        bold: true,
      });
      tBodyRanges.push(`B${x}:I${x + 1}`);
    });
    ledgerIndexes.forEach((x, i) => {
      cellMergeRanges.push(
        { val: `B${x - 1}:I${x - 1}`, align: "left" },
        { val: `B${x}:I${x}` },
        { val: `D${x + 1}:E${x + 1}`, align: "right" },
        { val: `D${x + 2}:E${x + 2}`, align: "right" },
        { val: `B${x + 3}:E${x + 3}` },
        { val: `H${x + 3}:I${x + 3}` }
      );
      cellColorRanges.push({
        val: `H${x + 3}:I${x + 3}`,
        color: Number(ledgerBalances[i] || 0) < 0 ? "FF0000" : "0000FF",
        bold: true,
      });
      tBodyRanges.push(`B${x - 1}:I${x + 3}`);
      yellowColorRanges.push({ range: `H${x + 3}:I${x + 3}` });
      purpleColorRanges.push(`B${x}:I${x}`);
      greyColorRanges.push(`B${x + 1}:I${x + 1}`, `B${x + 3}:G${x + 3}`);
    });
    brokerageIndexes.forEach((x, i) => {
      cellMergeRanges.push(
        { val: `B${x}:I${x}`, align: "left" },
        { val: `B${x + 1}:D${x + 1}` },
        { val: `E${x + 1}:G${x + 1}` },
        { val: `H${x + 1}:I${x + 1}` }
      );
      cellColorRanges.push(
        {
          val: `B${x + 1}:D${x + 1}`,
          color: Number(totalGrosses[i] || 0) < 0 ? "FF0000" : "0000FF",
          bold: true,
        },
        {
          val: `E${x + 1}:G${x + 1}`,
          color: Number(totalBrokerages[i] || 0) < 0 ? "FF0000" : "0000FF",
          bold: true,
        },
        {
          val: `H${x + 1}:I${x + 1}`,
          color: Number(totalBillAmounts[i] || 0) < 0 ? "FF0000" : "0000FF",
          bold: true,
        }
      );
      tBodyRanges.push(`B${x}:I${x + 1}`);
    });
    companyTitleRange = "B2:I2";
    valanItem = { value: "E4:G4", isRange: true };
    titleRange = "B4:I4";
  } else if (labelName === "22" || labelName === "24") {
    const blankRow = {
      type: "",
      lot: "",
      qty: "",
      t_rate: "",
      n_rate: "",
      net_amount: "",
      date: "",
      type_sell: "",
      lot_sell: "",
      qty_sell: "",
      t_rate_sell: "",
      n_rate_sell: "",
      net_amount_sell: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, type: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        type: reportTitle,
        net_amount: valanLabel,
        t_rate_sell: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    const totalBillAmounts = [];
    responseData?.forEach((x) => {
      let totalBillAmount = 0;
      x?.data?.forEach((y = {}) => {
        let totalNetAmount = 0;
        let scriptTable = [
          { ...blankRow, type: x?.name },
          { ...blankRow, type: y?.name },
          { ...blankRow, type: "Buy", date: "Date", type_sell: "Sell" },
          {
            type: "Type",
            lot: "Lot",
            qty: "Qty",
            t_rate: "T. Rate",
            n_rate: "N. Rate",
            net_amount: "Net Amt",
            date: "Date",
            type_sell: "Type",
            lot_sell: "Lot",
            qty_sell: "Qty",
            t_rate_sell: "T. Rate",
            n_rate_sell: "N. Rate",
            net_amount_sell: "Net Amt",
          },
        ];
        let buyRow;
        let sellRow;
        let lotTotal = 0,
          qtyTotal = 0,
          tradeRate = 0,
          netRate = 0,
          netAmount = 0;
        let lotTotal_shell = 0,
          qtyTotal_shell = 0,
          tradeRate_shell = 0,
          netRate_shell = 0,
          netAmount_shell = 0;
        y?.data?.forEach((z = {}) => {
          if (z.orderTypeName === "Buy") {
            buyRow = {
              type: z.tradeOptionName,
              lot: z.lot,
              qty: z.buyQuantity,
              t_rate: z.rate,
              n_rate: mathRound(z.netRate),
              net_amount: z.netAmount,
              date: moment(z.tradeDate).format("YYYY-MM-DD HH:mm:ss"),
            };
            lotTotal += z.lot;
            qtyTotal += z.buyQuantity;
            tradeRate += z.rate;
            netRate += mathRound(z.netRate);
            netAmount += z.netAmount;
            scriptTable.push({ ...blankRow, ...buyRow });
          } else {
            sellRow = {
              type_sell: z.tradeOptionName,
              lot_sell: z.lot,
              qty_sell: z.sellQuantity,
              t_rate_sell: z.rate,
              n_rate_sell: mathRound(z.netRate),
              net_amount_sell: z.netAmount,
              date: moment(z.tradeDate).format("YYYY-MM-DD HH:mm:ss"),
            };
            lotTotal_shell += z.lot;
            qtyTotal_shell += z.sellQuantity;
            tradeRate_shell += z.rate;
            netRate_shell += mathRound(z.netRate);
            netAmount_shell += z.netAmount;
            scriptTable.push({ ...blankRow, ...sellRow });
          }
        });
        totalNetAmount = netAmount_shell + netAmount;
        // scriptTable.push({
        //   ...buyRow,
        //   ...sellRow,
        //   type: "",
        //   type_sell: "",
        //   date: "",
        // });
        scriptTable.push({
          lot: lotTotal,
          qty: qtyTotal,
          t_rate: tradeRate,
          n_rate: netRate,
          net_amount: netAmount,
          lot_sell: lotTotal_shell,
          qty_sell: qtyTotal_shell,
          t_rate_sell: tradeRate_shell,
          n_rate_sell: netRate_shell,
          net_amount_sell: netAmount_shell,
          type: "",
          type_sell: "",
          date: "",
        });
        scriptTable.push({ ...blankRow, type: totalNetAmount });
        perTableData.push({ totalItems: y?.data?.length, totalNetAmount });
        totalBillAmount += totalNetAmount;
        scriptTable.push(blankRow);
        finalData.push(...scriptTable);
      });
      totalBillAmounts.push(totalBillAmount);
      finalData.push(
        { ...blankRow, type: x?.name },
        {
          ...blankRow,
          type: `Total bill Amount : ${Number(totalBillAmount || 0).toFixed(
            2
          )}`,
        },
        blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow
      );
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.type,
        C: x.lot,
        D: x.qty,
        E: x.t_rate,
        F: x.n_rate,
        G: x.net_amount,
        H: x.date,
        I: x.type_sell,
        J: x.lot_sell,
        K: x.qty_sell,
        L: x.t_rate_sell,
        M: x.n_rate_sell,
        N: x.net_amount_sell,
      });
    });
    let headerIndexes = [];
    let totalAmountIndexes = [];
    tableData.forEach((data, index) => {
      if (data["B"] === "Type") headerIndexes.push(index);
      if (String(data["B"] || "")?.startsWith("Total bill Amount"))
        totalAmountIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 8, hrAlign: "right" },
      { column: "C", width: 5, hrAlign: "right" },
      { column: "D", width: 5, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 12, hrAlign: "right" },
      { column: "H", width: 15, hrAlign: "center" },
      { column: "I", width: 8, hrAlign: "right" },
      { column: "J", width: 5, hrAlign: "right" },
      { column: "K", width: 5, hrAlign: "right" },
      { column: "L", width: 10, hrAlign: "right" },
      { column: "M", width: 10, hrAlign: "right" },
      { column: "N", width: 12, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B4:F4", align: "left", bold: false },
      { val: "L4:N4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData[i].totalItems;
      greyColorRanges.push(
        `B${x + 1}:N${x + 1}`,
        `B${x + totalItems + 2}:G${x + totalItems + 2}`,
        `I${x + totalItems + 2}:N${x + totalItems + 2}`
      );
      yellowColorRanges.push({
        range: `B${x + totalItems + 3}:N${x + totalItems + 3}`,
        fontColor:
          Number(perTableData[i].totalNetAmount || 0) < 0 ? "FF0000" : "0000FF",
      });
      purpleColorRanges.push(`B${x - 1}:N${x - 1}`);
      tBodyRanges.push(`B${x - 2}:N${x + totalItems + 3}`);
      cellMergeRanges.push(
        { val: `B${x - 2}:N${x - 2}`, align: "left" },
        { val: `B${x - 1}:N${x - 1}` },
        { val: `B${x}:G${x}` },
        { val: `I${x}:N${x}` }
      );
      cellColorRanges.push(
        { val: `B${x}:H${x}`, color: "0000FF", bold: true },
        { val: `I${x}:N${x}`, color: "FF0000", bold: true },
        { val: `D${x + 2}:D${x + 1 + totalItems}`, color: "0000FF", hAlign: "right" },
        { val: `K${x + 2}:K${x + 1 + totalItems}`, color: "FF0000", hAlign: "right" }
      );
    });
    totalAmountIndexes.forEach((x, i) => {
      cellMergeRanges.push(
        { val: `B${x + 1}:N${x + 1}` },
        { val: `B${x}:N${x}`, align: "left" }
      );
      cellColorRanges.push({
        val: `B${x + 1}:N${x + 1}`,
        color: Number(totalBillAmounts[i] || 0) < 0 ? "FF0000" : "0000FF",
        bold: true,
      });
      tBodyRanges.push(`B${x}:N${x + 1}`);
    });
    companyTitleRange = "B2:N2";
    valanItem = { value: "G4:K4", isRange: true };
    titleRange = "B4:N4";
  } else if (labelName === "26") {
    const blankRow = {
      date: "",
      type: "",
      lot: "",
      buy_qty: "",
      sell_qty: "",
      net_rate: "",
      net_amount: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, date: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        date: reportTitle,
        buy_qty: valanLabel,
        net_rate: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    const totalBillAmounts = [];
    responseData?.forEach((x) => {
      let totalBillAmount = 0;
      x?.data?.forEach((y = {}, index) => {
        let totalBuyQty = 0,
          totalSellQty = 0,
          totalNetAmount = 0;
        const isFirstScript = index === 0;
        const customerAuthRow = isFirstScript
          ? [{ ...blankRow, date: x?.name }]
          : [];
        let scriptTable = [
          ...customerAuthRow,
          { ...blankRow, date: y?.name },
          {
            date: "Date",
            type: "Type",
            lot: "Lot",
            buy_qty: "Buy Qty",
            sell_qty: "Sell Qty",
            rate: "Rate",
            net_rate: "Net Rate",
            net_amount: "Net Amount",
            isFirstScript,
          },
        ];
        y?.data?.forEach((z = {}) => {
          totalBuyQty += z.buyQuantity;
          totalSellQty += z.sellQuantity;
          totalNetAmount += z.netAmount;
          scriptTable.push({
            date: moment(z.tradeDate).format("YYYY-MM-DD HH:mm:ss"),
            type: z.tradeOptionName,
            lot: z.lot,
            buy_qty: z.buyQuantity,
            sell_qty: z.sellQuantity,
            net_rate: Number(z.netRate || 0).toFixed(2),
            net_amount: z.netAmount,
          });
        });
        perTableData.push({ totalItems: y?.data?.length, totalNetAmount });
        totalBillAmount += totalNetAmount;
        scriptTable.push(
          {
            ...blankRow,
            date: "Script wise total :",
            buy_qty: totalBuyQty?.toFixed(2),
            sell_qty: totalSellQty?.toFixed(2),
            net_rate: totalNetAmount?.toFixed(2),
          },
          blankRow
        );
        finalData.push(...scriptTable);
      });
      totalBillAmounts.push(totalBillAmount);
      finalData.push(
        {
          ...blankRow,
          date: `Total bill Amount : ${Number(totalBillAmount || 0).toFixed(
            2
          )}`,
        },
        blankRow
      );
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.date,
        C: x.type,
        D: x.lot,
        E: x.buy_qty,
        F: x.sell_qty,
        G: x.net_rate,
        H: x.net_amount,
        I: x.isFirstScript,
      });
    });
    let headerIndexes = [];
    let totalAmountIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Date")
        headerIndexes.push({ index, isFirstScript: data["I"] });
      else if (bData?.startsWith("Total bill Amount"))
        totalAmountIndexes.push(index);
      delete data["I"];
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 15, hrAlign: "left" },
      { column: "C", width: 10, hrAlign: "right" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 15, hrAlign: "right" },
      { column: "G", width: 15, hrAlign: "right" },
      { column: "H", width: 20, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:H2" },
      { val: "B4:D4", align: "left", bold: false },
      { val: "G4:H4", align: "right", bold: false },
    ];
    headerIndexes.forEach(({ index: x, isFirstScript }, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:H${x + 1}`,
        `B${scriptTotalId}:F${scriptTotalId}`
      );
      yellowColorRanges.push({
        range: `G${scriptTotalId}:H${scriptTotalId}`,
        fontColor:
          Number(perTableData[i].totalNetAmount || 0) < 0 ? "FF0000" : "0000FF",
      });
      purpleColorRanges.push(`B${x}:H${x}`);
      if (isFirstScript) {
        tBodyRanges.push(`B${x - 1}:H${scriptTotalId}`);
      } else tBodyRanges.push(`B${x}:H${scriptTotalId}`);

      const customerAuthRange = isFirstScript
        ? [{ val: `B${x - 1}:H${x - 1}`, align: "left" }]
        : [];
      cellMergeRanges.push(
        ...customerAuthRange,
        { val: `B${x}:H${x}` },
        { val: `B${scriptTotalId}:D${scriptTotalId}`, align: "left" },
        { val: `G${scriptTotalId}:H${scriptTotalId}`, align: "right" }
      );
      cellColorRanges.push(
        { val: `E${x + 2}:E${scriptTotalId - 1}`, color: "0000FF", hAlign: "right" },
        { val: `F${x + 2}:F${scriptTotalId - 1}`, color: "FF0000", hAlign: "right" }
      );
    });
    totalAmountIndexes.forEach((x, i) => {
      cellMergeRanges.push({ val: `B${x + 1}:H${x + 1}` });
      cellColorRanges.push({
        val: `B${x + 1}:I${x + 1}`,
        color: Number(totalBillAmounts[i] || 0) < 0 ? "FF0000" : "0000FF",
        bold: true,
      });
      tBodyRanges.push(`B${x + 1}:H${x + 1}`);
    });
    companyTitleRange = "B2:H2";
    valanItem = { value: "E4:F4", isRange: true };
    titleRange = "B4:H4";
  } else if (labelName === "27" || labelName === "28" || labelName === "213") {
    const excelData = [
      { accountCode: "", client: "", debit: "", credit: "" },
      { accountCode: "Golden Money", client: "", debit: "", credit: "" },
      { accountCode: "", client: "", debit: "", credit: "" },
      {
        accountCode: reportTitle,
        client: valanLabel,
        debit: fromToLabel,
        credit: "",
      },
      { accountCode: accountTypeLabel, client: "", debit: "", credit: "" },
      {
        accountCode: "Account Code",
        client: "Client Name",
        debit: "Debit",
        credit: "Credit",
      },
    ];
    const finalData = [];
    responseData?.forEach((x) => {
      let data = x?.data?.map((y = {}) => {
        let item = {};
        item.accountCode = y.accountCode;
        item.client = y.clientName;
        item.debit = y.debit;
        item.credit = y.credit;
        return item;
      });
      finalData.push(...data);
    });
    const totalDebit = finalData?.reduce(
      (sum, val) => sum + Number(val.debit || 0),
      0
    );
    const totalCredit = finalData?.reduce(
      (sum, val) => sum + Number(val.credit || 0),
      0
    );
    // const closingBalance =
    //   labelName === "28" ? totalCredit - totalDebit : totalDebit - totalCredit;
    const closingBalance = totalCredit - totalDebit

    excelData.push(...finalData);
    excelData.push({
      accountCode: closingBalance ? Math.round(closingBalance) : closingBalance,
      client: "",
      debit: totalDebit,
      credit: totalCredit,
    });
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.accountCode,
        C: x.client,
        D: x.debit > 0 ? Math.round(x.debit) : x.debit, 
        E: x.credit > 0 ? Math.round(x.credit) : x.credit
      });
    });
    var headerIndexes = [];
    tableData.forEach((data, index) =>
      data["B"] === "Account Code" ? headerIndexes.push(index) : null
    );
    const dataLength = finalData?.length;
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 15, hrAlign: "left" },
      { column: "C", width: 45, hrAlign: "left" },
      { column: "D", width: 20, hrAlign: "right" },
      { column: "E", width: 20, hrAlign: "right" },
    ];
    headerIndexes.forEach((x) => {
      greyColorRanges.push(
        `D${x + dataLength + 2}:E${x + dataLength + 2}`,
        `B${x + 1}:E${x + 1}`
      );
      yellowColorRanges.push({
        range: `B${x + dataLength + 2}:C${x + dataLength + 2}`,
        fontColor: Number(closingBalance || 0) < 0 ? "FF0000" : "0000FF",
        hAlign: "right",
      },{
        range: `D${x + dataLength + 2}:D${x + dataLength + 2}`,
        fontColor: "FF0000",
        hAlign: "right",
      },{
        range: `E${x + dataLength + 2}:E${x + dataLength + 2}`,
        fontColor: "0000FF",
        hAlign: "right",
      });
      tBodyRanges.push(`B${x}:E${x + dataLength + 2}`);
    });
    finalData.forEach((x, index) => {
      const headerIndex = headerIndexes[0] + index + 2;
      cellColorRanges.push(
        {
          val: `D${headerIndex}`,
          color: "FF0000",
          hAlign: "right",
          isRange: false,
        },
        {
          val: `E${headerIndex}`,
          color: "0000FF",
          hAlign: "right",
          isRange: false,
        }
      );
    });
    purpleColorRanges = ["B5:E5"];
    cellMergeRanges = [{ val: "D4:E4", align: "right" }];
    companyTitleRange = "B2:E2";
    valanItem = { value: "C4" };
    titleRange = "B4:E4";
  } else if (labelName === "31") {
    const blankRow = {
      authority: "",
      name: "",
      gross_amt: "",
      ci_brok: "",
      bill_amt: "",
      sb_brok: "",
      partner_brok: "",
      self_brok: "",
      sb_amt: "",
      partner_amt: "",
      self_amt: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, authority: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        authority: reportTitle,
        gross_amt: valanLabel,
        sb_amt: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    let amountTotal = 0;
    responseData?.forEach((x) => {
      let grossAmtTotal = 0,
        ciBrokTotal = 0,
        billAmtTotal = 0,
        sbBrokTotal = 0,
        partnerBrokTotal = 0,
        selfBrokTotal = 0,
        sbAmtTotal = 0,
        partnerAmtTotal = 0,
        selfAmtTotal = 0;
      let scriptTable = [
        { ...blankRow, authority: accountTypeLabel },
        {
          authority: "Authority",
          name: "Name",
          gross_amt: "Gross Amt",
          ci_brok: "CI Brok.",
          bill_amt: "Bill Amt.",
          sb_brok: "Sb Brok.",
          partner_brok: "Partner Brok.",
          self_brok: "Self Brok",
          sb_amt: "Sb Amt.",
          partner_amt: "Partner Amt.",
          self_amt: "Self Amt.",
        },
      ];
      x?.data?.forEach((y = {}) => {
        grossAmtTotal += y.grossAmt;
        ciBrokTotal += y.clientBrok;
        billAmtTotal += y.billAmt;
        sbBrokTotal += y.sbBrok;
        partnerBrokTotal += y.partnershipBrok;
        selfBrokTotal += y.selfBrok;
        sbAmtTotal += y.sbAmt;
        partnerAmtTotal += y.partnershipAmt;
        selfAmtTotal += y.selfAmt;
        amountTotal += y.selfAmt;
        scriptTable.push({
          authority: y.authorityName,
          name: y.clientName,
          gross_amt: y.grossAmt,
          ci_brok: y.clientBrok,
          bill_amt: y.billAmt,
          sb_brok: y.sbBrok,
          partner_brok: y.partnershipBrok,
          self_brok: y.selfBrok,
          sb_amt: y.sbAmt,
          partner_amt: y.partnershipAmt,
          self_amt: y.selfAmt,
        });
      });
      perTableData.push({ totalItems: x?.data?.length });
      scriptTable.push(
        {
          ...blankRow,
          name: "Total:",
          gross_amt: grossAmtTotal,
          ci_brok: ciBrokTotal,
          bill_amt: billAmtTotal,
          sb_brok: sbBrokTotal,
          partner_brok: partnerBrokTotal,
          self_brok: selfBrokTotal,
          sb_amt: sbAmtTotal,
          partner_amt: partnerAmtTotal,
          self_amt: selfAmtTotal,
        },
        blankRow
      );
      finalData.push(...scriptTable);
    });
    if (accountTypeLabel === "SELF") {
      finalData.push(
        {
          ...blankRow,
          authority: `Total bill Amount : ${Number(amountTotal || 0).toFixed(
            2
          )}`,
        },
        blankRow
      );
    }

    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.authority,
        C: x.name,
        D: x.gross_amt,
        E: x.ci_brok,
        F: x.bill_amt,
        G: x.sb_brok,
        H: x.partner_brok,
        I: x.self_brok,
        J: x.sb_amt,
        K: x.partner_amt,
        L: x.self_amt,
      });
    });
    let headerIndexes = [];
    let totalAmountIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (String(data["B"] || "")?.startsWith("Total bill Amount"))
        totalAmountIndexes.push(index);
      if (bData === "Authority") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 15, hrAlign: "left" },
      { column: "C", width: 20, hrAlign: "left" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 15, hrAlign: "right" },
      { column: "I", width: 10, hrAlign: "right" },
      { column: "J", width: 10, hrAlign: "right" },
      { column: "K", width: 15, hrAlign: "right" },
      { column: "L", width: 10, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:L2" },
      { val: "B4:C4", align: "left", bold: false },
      { val: "J4:L4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:L${x + 1}`,
        `B${scriptTotalId}:L${scriptTotalId}`
      );
      purpleColorRanges.push(`B${x}:L${x}`);
      tBodyRanges.push(`B${x}:L${scriptTotalId}`);
      cellColorRanges.push({
        isRange: false,
        val: `C${scriptTotalId}`,
        bold: true,
        hAlign: "right",
        color: "FFFFFF",
      });
      // totalAmountIndexes.forEach((x, i) => {
      //   cellMergeRanges.push(
      //     { val: `B${x + 1}:N${x + 1}` },
      //     { val: `B${x}:N${x}`, align: "left" }
      //   );
      totalAmountIndexes.forEach((x, i) => {
        cellMergeRanges.push(
          { val: `B${x + 1}:L${x + 1}` },
          { val: `B${x}:L${x}`, align: "left" }
        );
        cellColorRanges.push({
          val: `B${x + 1}:L${x + 1}`,
          color: Number(amountTotal[i] || 0) < 0 ? "FF0000" : "0000FF",
          bold: true,
        });
        tBodyRanges.push(`B${x + 1}:L${x + 1}`);
      });
    });
    companyTitleRange = "B2:L2";
    valanItem = { value: "D4:I4", isRange: true };
    titleRange = "B4:L4";
  } else if (labelName === "32") {
    const blankRow = {
      authority: "",
      name: "",
      mtm_amt: "",
      bro_amt: "",
      total_amt: "",
      sb_brok: "",
      share_percentage: "",
      br_share: "",
      net_amt: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, authority: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        authority: reportTitle,
        mtm_amt: valanLabel,
        share_percentage: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      let mtmAmtTotal = 0,
        broAmtTotal = 0,
        totalAmtTotal = 0,
        sbBrokTotal = 0,
        sharePercentageTotal = 0,
        brShareTotal = 0,
        netAmtTotal = 0;
      let scriptTable = [
        { ...blankRow, authority: accountTypeLabel },
        {
          authority: "Authority",
          name: "Name",
          mtm_amt: "MTM Amt.",
          bro_amt: "Bro Amt.",
          total_amt: "Total Amt",
          sb_brok: "Sb Bro.",
          share_percentage: "Share %",
          br_share: "Br Share.",
          net_amt: "Net Amt.",
        },
      ];
      x?.data?.forEach((y = {}) => {
        mtmAmtTotal += y.billAmt;
        broAmtTotal += y.clientBrok;
        totalAmtTotal += y.grossAmt;
        sbBrokTotal += y.sbBrok;
        sharePercentageTotal += y.sharingPercentage;
        brShareTotal += y.selfBrok;
        netAmtTotal += y.selfAmt;
        scriptTable.push({
          authority: y.authorityName,
          name: y.clientName,
          mtm_amt: y.billAmt,
          bro_amt: y.clientBrok,
          total_amt: y.grossAmt,
          sb_brok: y.sbBrok,
          share_percentage: y.sharingPercentage,
          br_share: y.selfBrok,
          net_amt: y.selfAmt,
        });
      });
      perTableData.push({ totalItems: x?.data?.length });
      scriptTable.push(
        {
          ...blankRow,
          name: "Total:",
          mtm_amt: mtmAmtTotal,
          bro_amt: broAmtTotal,
          total_amt: totalAmtTotal,
          sb_brok: sbBrokTotal,
          share_percentage: sharePercentageTotal,
          br_share: brShareTotal,
          net_amt: netAmtTotal,
        },
        blankRow
      );
      finalData.push(...scriptTable);
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.authority,
        C: x.name,
        D: x.mtm_amt,
        E: x.bro_amt,
        F: x.total_amt,
        G: x.sb_brok,
        H: x.share_percentage,
        I: x.br_share,
        J: x.net_amt,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Authority") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 15, hrAlign: "left" },
      { column: "C", width: 20, hrAlign: "left" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 15, hrAlign: "right" },
      { column: "I", width: 10, hrAlign: "right" },
      { column: "J", width: 10, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:J2" },
      { val: "B4:C4", align: "left", bold: false },
      { val: "H4:J4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:J${x + 1}`,
        `B${scriptTotalId}:J${scriptTotalId}`
      );
      purpleColorRanges.push(`B${x}:J${x}`);
      tBodyRanges.push(`B${x}:J${scriptTotalId}`);
      cellColorRanges.push({
        isRange: false,
        val: `C${scriptTotalId}`,
        bold: true,
        hAlign: "right",
        color: "FFFFFF",
      });
    });
    companyTitleRange = "B2:J2";
    valanItem = { value: "D4:G4", isRange: true };
    titleRange = "B4:J4";
  } else if (labelName === "41") {
    const blankRow = {
      script: "",
      time: "",
      lot: "",
      buy_qty: "",
      sell_qty: "",
      net_rate: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, script: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        script: reportTitle,
        time: valanLabel,
        sell_qty: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      x?.data?.forEach((y = {}) => {
        let scriptTable = [
          { ...blankRow, script: x?.name },
          { ...blankRow, script: moment(y?.name).format("DD-MM-YYYY") },
          {
            script: "Script",
            time: "Trade Time",
            lot: "Lot",
            buy_qty: "Buy Qty",
            sell_qty: "Sell Qty",
            net_rate: "Net Rate",
          },
        ];
        y?.data?.forEach((z = {}) => {
          scriptTable.push({
            script: z.script,
            time: moment(z.tradeTime).format("DD-MM-YYYY HH:MM:SS"),
            lot: z.lot,
            buy_qty: z.buyQuantity,
            sell_qty: z.sellQuantity,
            net_rate: z.netRate,
          });
        });
        perTableData.push({ totalItems: y?.data?.length });
        finalData.push(...scriptTable, blankRow);
      });
      finalData.push(
        blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow
      );
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.script,
        C: x.time,
        D: x.lot,
        E: x.buy_qty,
        F: x.sell_qty,
        G: x.net_rate,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 20, hrAlign: "right" },
      { column: "D", width: 8, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 15, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B4:B4", align: "left", bold: false },
      { val: "F4:G4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 1;
      greyColorRanges.push(`B${x + 1}:G${x + 1}`);
      purpleColorRanges.push(`B${x}:G${x}`);
      tBodyRanges.push(`B${x - 1}:G${scriptTotalId}`);
      cellMergeRanges.push({ val: `B${x - 1}:G${x - 1}`, align: "left" });
      cellColorRanges.push(
        {
          val: `E${x + 2}:E${scriptTotalId}`,
          color: "0000FF",
          hAlign: "right",
        },
        { val: `F${x + 2}:F${scriptTotalId}`, color: "FF0000", hAlign: "right" }
      );
    });
    companyTitleRange = "B2:G2";
    valanItem = { value: "C4:E4", isRange: true };
    titleRange = "B4:G4";
  } else if (labelName === "42") {
    const blankRow = {
      script: "",
      gross_buy: "",
      gross_sell: "",
      net_buy: "",
      net_sell: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, script: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        script: reportTitle,
        gross_buy: valanLabel,
        net_buy: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      let grossBuyTotal = 0,
        grossSellTotal = 0,
        netBuyTotal = 0,
        netSellTotal = 0;
      let scriptTable = [
        { ...blankRow, script: x?.name },
        {
          script: "Script",
          gross_buy: "Gross Buy",
          gross_sell: "Gross Sell",
          net_buy: "Net Buy",
          net_sell: "Net Sell",
        },
      ];
      x?.data?.forEach((y = {}) => {
        grossBuyTotal += y.grossBuy;
        grossSellTotal += y.grossSell;
        netBuyTotal += y.netBuy;
        netSellTotal += y.netSell;
        scriptTable.push({
          script: y.script,
          gross_buy: y.grossBuy,
          gross_sell: y.grossSell,
          net_buy: y.netBuy,
          net_sell: y.netSell,
        });
      });
      scriptTable.push({
        script: "Total:",
        gross_buy: grossBuyTotal,
        gross_sell: grossSellTotal,
        net_buy: netBuyTotal,
        net_sell: netSellTotal,
      });
      scriptTable.push({
        ...blankRow,
        gross_buy: grossBuyTotal,
        net_buy: netBuyTotal,
      });
      perTableData.push({ totalItems: x?.data?.length });
      finalData.push(...scriptTable);
      finalData.push(
        blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow,
        // blankRow
      );
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.script,
        C: x.gross_buy,
        D: x.gross_sell,
        E: x.net_buy,
        F: x.net_sell,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 15, hrAlign: "right" },
      { column: "D", width: 15, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 15, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B4:B4", align: "left", bold: false },
      { val: "E4:F4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:F${x + 1}`,
        `B${scriptTotalId}:F${scriptTotalId}`
      );
      purpleColorRanges.push(`B${x}:F${x}`);
      tBodyRanges.push(`B${x}:F${scriptTotalId + 1}`);
      yellowColorRanges.push(
        { range: `C${scriptTotalId + 1}:D${scriptTotalId + 1}` },
        { range: `E${scriptTotalId + 1}:F${scriptTotalId + 1}` }
      );
      cellColorRanges.push({
        isRange: false,
        val: `B${scriptTotalId}`,
        bold: true,
        hAlign: "right",
        color: "FFFFFF",
      });
    });
    companyTitleRange = "B2:F2";
    valanItem = { value: "C4:D4", isRange: true };
    titleRange = "B4:F4";
  } else if (labelName === "43") {
    const blankRow = {
      client: "",
      gross_buy: "",
      gross_sell: "",
      gross_total: "",
      net_total: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, client: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        client: reportTitle,
        gross_buy: valanLabel,
        gross_total: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      let scriptTable = [
        { ...blankRow, client: "CLIENT" },
        {
          client: "Client Name",
          gross_buy: "Gross Buy",
          gross_sell: "Gross Sell",
          gross_total: "Gross Total",
          net_total: "Net Total",
        },
      ];
      x?.data?.forEach((y = {}) => {
        scriptTable.push({
          client: y.clientName,
          gross_buy: y.grossBuy,
          gross_sell: y.grossSell,
          gross_total: y.netBuy,
          net_total: y.netSell,
        });
      });
      perTableData.push({ totalItems: x?.data?.length });
      scriptTable.push(blankRow, blankRow);
      finalData.push(...scriptTable);
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.client,
        C: x.gross_buy,
        D: x.gross_sell,
        E: x.gross_total,
        F: x.net_total,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Client Name") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 15, hrAlign: "right" },
      { column: "D", width: 15, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 15, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B4:B4", align: "left", bold: false },
      { val: "E4:F4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:F${x + 1}`,
        `B${scriptTotalId}:F${scriptTotalId}`
      );
      purpleColorRanges.push(`B${x}:F${x}`);
      tBodyRanges.push(`B${x}:F${scriptTotalId}`);
    });
    companyTitleRange = "B2:F2";
    valanItem = { value: "C4:D4", isRange: true };
    titleRange = "B4:F4";
  } else if (labelName === "51" || labelName === "52" || labelName === "53") {
    let isSimple = labelName === "51";
    let isNet = labelName === "52";
    let isSub = labelName === "53";
    const blankRow = {
      name: "",
      brokerage: "",
      sub_brok_brokerage: "",
      final_brokerage: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      {
        name: reportTitle,
        brokerage: valanLabel,
        sub_brok_brokerage: fromToLabel,
        final_brokerage: "",
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    let brokTotal = 0,
      subBrokTotal = 0,
      finalBrokTotal = 0,
      scriptTable = [
        { ...blankRow, name: accountTypeLabel },
        {
          name: "Name",
          brokerage: "Brokerage",
          sub_brok_brokerage: "Sub Broker Brok.",
          final_brokerage: isSub ? "Self Brokerage" : "Final Brokerage",
        },
      ];
    responseData?.forEach((x) => {
      x?.data?.forEach((y = {}) => {
        brokTotal += y.clientBrok;
        subBrokTotal += y.sbBrok;
        finalBrokTotal += y.finalBrok;
        scriptTable.push({
          name: y.clientName,
          brokerage: y.clientBrok,
          sub_brok_brokerage: y.sbBrok,
          final_brokerage: isSub ? y.selfBrok : y.finalBrok,
        });
      });
    });
    perTableData.push({ totalItems: responseData?.length });
    scriptTable.push(
      {
        ...blankRow,
        brokerage: brokTotal,
        sub_brok_brokerage: subBrokTotal,
        final_brokerage: finalBrokTotal,
      },
      blankRow
    );
    finalData.push(...scriptTable);
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.brokerage,
        D: x.sub_brok_brokerage,
        E: x.final_brokerage,
      });
    });
    if (isSimple) tableData.forEach((x) => delete x["E"]);
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Name") headerIndexes.push(index);
    });

    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 20, hrAlign: "right" },
      { column: "D", width: 25, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
    ];
    const lastColumn = isSimple ? "D" : "E";
    cellMergeRanges = [
      { val: "B4:B4", align: "left", bold: false },
      { val: isSimple ? "D4:D4" : `D4:E4`, align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:${lastColumn}${x + 1}`,
        `B${scriptTotalId}:C${scriptTotalId}`
      );
      if (isNet || isSub)
        greyColorRanges.push(`E${scriptTotalId}:E${scriptTotalId}`);
      yellowColorRanges.push({
        range: `D${scriptTotalId}:D${scriptTotalId}`,
        fontColor: Number(subBrokTotal || 0) < 0 ? "FF0000" : "0000FF",
        hAlign: "right",
      });
      purpleColorRanges.push(`B${x}:${lastColumn}${x}`);
      tBodyRanges.push(`B${x}:${lastColumn}${scriptTotalId}`);
    });
    companyTitleRange = `B2:${lastColumn}2`;
    valanItem = { value: "C4:C4", isRange: true };
    titleRange = `B4:${lastColumn}4`;
  } else if (labelName === "54") {
    const blankRow = {
      name: "",
      brokerage: "",
      sub_brok_brokerage: "",
      partner_brokerage: "",
      self_brokerage: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        name: reportTitle,
        brokerage: valanLabel,
        self_brokerage: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    let brokTotal = 0,
      subBrokTotal = 0,
      partnerBrokTotal = 0,
      selfBrokTotal = 0,
      scriptTable = [
        { ...blankRow, name: accountTypeLabel },
        {
          name: "Name",
          brokerage: "Brokerage",
          sub_brok_brokerage: "Sub Broker Brok.",
          partner_brokerage: "Partner Sb.",
          self_brokerage: "Self Brokerage",
        },
      ];
    responseData?.forEach((x) => {
      x?.data?.forEach((y = {}) => {
        brokTotal += y.clientBrok;
        subBrokTotal += y.sbBrok;
        partnerBrokTotal += y.partnershipBrok;
        selfBrokTotal += y.selfBrok;
        scriptTable.push({
          name: y.clientName,
          brokerage: y.clientBrok,
          sub_brok_brokerage: y.sbBrok,
          partner_brokerage: y.partnershipBrok,
          self_brokerage: y.selfBrok,
        });
      });
    });
    perTableData.push({ totalItems: responseData?.length });
    scriptTable.push(
      {
        ...blankRow,
        brokerage: brokTotal,
        sub_brok_brokerage: subBrokTotal,
        partner_brokerage: partnerBrokTotal,
        self_brokerage: selfBrokTotal,
      },
      blankRow
    );
    finalData.push(...scriptTable);
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.brokerage,
        D: x.sub_brok_brokerage,
        E: x.partner_brokerage,
        F: x.self_brokerage,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Name") headerIndexes.push(index);
    });

    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 15, hrAlign: "right" },
      { column: "D", width: 20, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      { column: "F", width: 25, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B4:B4", align: "left", bold: false },
      { val: "F4:F4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(
        `B${x + 1}:F${x + 1}`,
        `B${scriptTotalId}:C${scriptTotalId}`,
        `F${scriptTotalId}:F${scriptTotalId}`
      );
      yellowColorRanges.push(
        {
          range: `D${scriptTotalId}:D${scriptTotalId}`,
          fontColor: Number(subBrokTotal || 0) <= 0 ? "FF0000" : "0000FF",
          hAlign: "right",
        },
        {
          range: `E${scriptTotalId}:E${scriptTotalId}`,
          fontColor: Number(partnerBrokTotal || 0) <= 0 ? "FF0000" : "0000FF",
          hAlign: "right",
        }
      );
      purpleColorRanges.push(`B${x}:F${x}`);
      tBodyRanges.push(`B${x}:F${scriptTotalId}`);
    });
    companyTitleRange = `B2:F2`;
    valanItem = { value: "C4:E4", isRange: true };
    titleRange = `B4:F4`;
  } else if (labelName === "61") {
    const blankRow = {
      name: "",
      buy_qty: "",
      buy_avg: "",
      sell_qty: "",
      sell_avg: "",
      pos_qty: "",
      ltp: "",
      m2m: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        name: reportTitle,
        buy_avg: valanLabel,
        pos_qty: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];

    let ledgerbalance = "";
    responseData?.forEach((x) => {
      ledgerbalance = x?.data[0]?.name;
      let totalNetAmount = 0,
        posQtys = [];

      let scriptTable = [
        {
          ...blankRow,
          name: x?.name,
          sell_avg: `Fatak Balance:${ledgerbalance}`,
        },
        {
          name: "Script Name",
          buy_qty: "Buy Qty",
          buy_avg: "Buy Avg.",
          sell_qty: "Sell Qty",
          sell_avg: "Sell Avg.",
          pos_qty: "Pos Qty",
          ltp: "LTP",
          m2m: "M2M",
        },
      ];
      x?.data.forEach((x1) => {
        x1?.data?.forEach((y = {}) => {
          totalNetAmount += y.mtm;
          posQtys.push(y.netQty);
          scriptTable.push({
            name: y.script,
            buy_qty: y.totalBuyQty,
            buy_avg: y.buyAvgPrice,
            sell_qty: y.totalSellQty,
            sell_avg: y.sellAvgPrice,
            pos_qty: y.netQty,
            ltp: y.ltp,
            m2m: y.mtm,
          });
        });
      });
      perTableData.push({
        totalItems: x?.data[0]?.data?.length,
        totalNetAmount,
        posQtys,
      });
      scriptTable.push(
        {
          ...blankRow,
          name: `${ledgerbalance > 0
              ? ledgerbalance + parseFloat(totalNetAmount)
              : totalNetAmount < 0
                ? (Math.abs(ledgerbalance) + Math.abs(totalNetAmount)) * -1
                : (Math.abs(ledgerbalance) - Math.abs(totalNetAmount)) * -1
            }`,
          sell_avg: totalNetAmount,
        },
        blankRow
      );
      finalData.push(...scriptTable);
    });

    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.buy_qty,
        D: x.buy_avg,
        E: x.sell_qty,
        F: x.sell_avg,
        G: x.pos_qty,
        H: x.ltp,
        I: x.m2m,
      });
    });

    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script Name" || bData === "Client Name")
        headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 10, hrAlign: "right" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 10, hrAlign: "right" },
      { column: "I", width: 10, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:I2" },
      { val: "B4:C4", align: "left", bold: false },
      { val: "G4:I4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;

      const scriptTotalId = x + totalItems + 2;

      greyColorRanges.push(
        `B${x + 1}:I${x + 1}`,
        `F${scriptTotalId}:I${scriptTotalId}`
      );
      yellowColorRanges.push({
        range: `B${scriptTotalId}:E${scriptTotalId}`,
      });
      purpleColorRanges.push(`B${x}:E${x}`, `F${x}:I${x}`);
      tBodyRanges.push(`B${x}:I${scriptTotalId}`);
      cellMergeRanges.push({
        val: `F${scriptTotalId}:I${scriptTotalId}`,
        align: "center",
      });
    });

    perTableData.forEach((x, index) => {
      const headerIndex = headerIndexes[index] + 1;
      for (let i = 0; i < x.totalItems; i++) {
        cellColorRanges.push({
          val: `G${headerIndex + i + 1}`,
          color: x.posQtys[i] < 0 ? "FF0000" : "0000FF",
          isRange: false,
        });
      }
    });
    companyTitleRange = "B2:I2";
    valanItem = { value: "D4:F4", isRange: true };
    titleRange = "B4:I4";
  } else if (labelName === "62") {
    const blankRow = {
      name: "",
      pos_qty: "",
      buy_avg: "",
      value: "",
      ltp: "",
      m2m: "",
    };
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      {
        ...blankRow,
        name: reportTitle,
        pos_qty: valanLabel,
        value: fromToLabel,
      },
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    let totalValue = 0,
      finalValue = 0,
      beAvgTotal = 0,
      PosQtyTotal = 0,
      totalNetAmount = 0;
    let uniqueData = [];
    let dummyData = [];
    if (labelName === "62" && accountTypeLabel === "SELF") {
      let posQtys = [],
        mtms = [];

      let scriptTable = [
        {
          ...blankRow,
          name: accountTypeLabel,
        },
        {
          name: "Script Name",
          pos_qty: "Pos Qty",
          buy_avg: "B.E. Avg.",
          value: "Value",
          ltp: "LTP",
          m2m: "M2M",
        },
      ];
      responseData?.forEach((x) => {
        x?.data.forEach((x1) => {
          dummyData = [...dummyData, ...x1?.data];
        });
      });
      dummyData?.map((item) => {
        if (uniqueData.length === 0) {
          uniqueData.push(item);
        } else {
          const index = uniqueData.findIndex(
            (item2) => item2.script === item.script
          );
          if (index !== -1) {
            uniqueData = uniqueData.map((record) => {
              if (record.script === item.script) {
                return {
                  ...record,
                  netQty: record.netQty + item.netQty,
                  beAvg: record.beAvg + item.beAvg,
                  // value: record.value + item.value,
                  ltp: record.ltp + item.ltp,
                  mtm: record.mtm + item.mtm,
                  netLot: record.netLot + item.netLot,
                };
              }
              return record;
            });
          } else {
            uniqueData.push(item);
          }
        }
      });
      let netQty = 0,
        beAvg = 0;
      uniqueData?.forEach((y = {}) => {
        totalNetAmount += y.mtm;
        posQtys.push(y.netQty);
        mtms.push(y.mtm);
        netQty = y.netQty;
        beAvg = y.beAvg;

        finalValue = netQty * beAvg;
        if (finalValue > 0) {
          PosQtyTotal = PosQtyTotal + finalValue;
        }
        if (finalValue < 0) {
          beAvgTotal = beAvgTotal + finalValue;
        }
        totalValue = totalValue + finalValue;

        scriptTable.push({
          name: y.script,
          pos_qty: y.netQty,
          buy_avg: y.beAvg,
          value: finalValue,
          ltp: y.ltp,
          m2m: y.mtm,
        });
      });
      perTableData.push({
        totalItems: uniqueData?.length,
        totalNetAmount,
        posQtys,
        mtms,
      });
      scriptTable.push(
        {
          ...blankRow,
          name: "",
          pos_qty: PosQtyTotal,
          buy_avg: beAvgTotal,
          value: PosQtyTotal - beAvgTotal,
          ltp: totalValue,
          m2m: totalNetAmount,
        },

        blankRow
      );

      finalData.push(...scriptTable);
    } else {
      responseData?.forEach((x) => {
        let posQtys = [],
          mtms = [];

        let scriptTable = [
          {
            ...blankRow,
            name: x?.name,
          },
          {
            name: "Script Name",
            pos_qty: "Pos Qty",
            buy_avg: "B.E. Avg.",
            value: "Value",
            ltp: "LTP",
            m2m: "M2M",
          },
        ];
        x?.data.forEach((x1) => {
          let netQty = 0,
            beAvg = 0;

          x1?.data?.forEach((y = {}) => {
            totalNetAmount += y.mtm;
            posQtys.push(y.netQty);
            mtms.push(y.mtm);
            netQty = y.netQty;
            beAvg = y.beAvg;

            finalValue = netQty * beAvg;
            if (finalValue > 0) {
              PosQtyTotal = PosQtyTotal + finalValue;
            }
            if (finalValue < 0) {
              beAvgTotal = beAvgTotal + finalValue;
            }
            totalValue = totalValue + finalValue;
            scriptTable.push({
              name: y.script,
              pos_qty: y.netQty,
              buy_avg: y.beAvg,
              value: finalValue,
              ltp: y.ltp,
              m2m: y.mtm,
            });
          });
        });

        perTableData.push({
          totalItems: x?.data[0]?.data?.length,
          totalNetAmount,
          posQtys,
          mtms,
        });
        scriptTable.push(
          {
            ...blankRow,
            name: "",
            pos_qty: PosQtyTotal,
            buy_avg: beAvgTotal,
            value: PosQtyTotal - beAvgTotal,
            ltp: totalValue,
            m2m: totalNetAmount,
          },

          blankRow
        );
        finalData.push(...scriptTable);
      });
    }
    // responseData?.forEach((x) => {
    //   let posQtys = [],
    //     mtms = [];

    //   let scriptTable = [
    //     {
    //       ...blankRow,
    //       name: x?.name,
    //     },
    //     {
    //       name: "Script Name",
    //       pos_qty: "Pos Qty",
    //       buy_avg: "B.E. Avg.",
    //       value: "Value",
    //       ltp: "LTP",
    //       m2m: "M2M",
    //     },
    //   ];
    //   x?.data.forEach((x1) => {
    //     let netQty = 0,
    //       beAvg = 0;

    //     x1?.data?.forEach((y = {}) => {
    //       totalNetAmount += y.mtm;
    //       posQtys.push(y.netQty);
    //       mtms.push(y.mtm);
    //       netQty = y.netQty;
    //       beAvg = y.beAvg;

    //       finalValue = netQty * beAvg;
    //       if (finalValue > 0) {
    //         PosQtyTotal = PosQtyTotal + finalValue;
    //       }
    //       if (finalValue < 0) {
    //         beAvgTotal = beAvgTotal + finalValue;
    //       }
    //       totalValue = totalValue + finalValue;
    //       scriptTable.push({
    //         name: y.script,
    //         pos_qty: y.netQty,
    //         buy_avg: y.beAvg,
    //         value: totalValue,
    //         ltp: y.ltp,
    //         m2m: y.mtm,
    //       });
    //     });
    //   });
    //   perTableData.push({
    //     totalItems: x?.data[0]?.data?.length,
    //     totalNetAmount,
    //     posQtys,
    //     mtms,
    //   });
    //   scriptTable.push(
    //     {
    //       ...blankRow,
    //       name: "",
    //       pos_qty: PosQtyTotal,
    //       buy_avg: beAvgTotal,
    //       value: PosQtyTotal - beAvgTotal,
    //       ltp: totalValue,
    //       m2m: totalNetAmount,
    //     },

    //     blankRow
    //   );
    //   finalData.push(...scriptTable);
    // });

    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.pos_qty,
        D: x.buy_avg,
        E: x.value,
        F: x.ltp,
        G: x.m2m,
      });
    });

    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Script Name" || bData === "Client Name")
        headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 10, hrAlign: "right" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:G2" },
      { val: "B4:B4", align: "left", bold: false },
      { val: "E4:G4", align: "right", bold: false },
    ];

    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;

      greyColorRanges.push(`B${x + 1}:G${x + 1}`);

      yellowColorRanges.push(
        {
          range: `B${scriptTotalId}:B${scriptTotalId}`,
          merge: false,
        },
        {
          range: `C${scriptTotalId}:C${scriptTotalId}`,
          fontColor: Number(PosQtyTotal) <= 0 ? "FF0000" : "0000FF",
          merge: false,
          hAlign: "right",
        },
        {
          range: `D${scriptTotalId}:D${scriptTotalId}`,
          fontColor: Number(beAvgTotal) <= 0 ? "FF0000" : "0000FF",
          merge: false,
          hAlign: "right",
        },
        {
          range: `E${scriptTotalId}:E${scriptTotalId}`,
          fontColor:
            Number(PosQtyTotal - beAvgTotal) <= 0 ? "FF0000" : "0000FF",
          merge: false,
          hAlign: "right",
        },
        {
          range: `F${scriptTotalId}:F${scriptTotalId}`,
          fontColor: Number(totalValue) <= 0 ? "FF0000" : "0000FF",
          merge: false,
          hAlign: "right",
        },
        {
          range: `G${scriptTotalId}:G${scriptTotalId}`,
          fontColor: Number(totalNetAmount) <= 0 ? "FF0000" : "0000FF",
          merge: false,
          hAlign: "right",
        }
      );
      purpleColorRanges.push(`B${x}:G${x}`);
      tBodyRanges.push(`B${x}:G${scriptTotalId}`);
      cellMergeRanges.push({
        val: `B${scriptTotalId}:B${scriptTotalId}`,
        align: "left",
      });
    });

    perTableData.forEach((x, index) => {
      const headerIndex = headerIndexes[index] + 1;
      for (let i = 0; i < x.totalItems; i++) {
        cellColorRanges.push({
          val: `C${headerIndex + i + 1}`,
          color: x.posQtys[i] < 0 ? "FF0000" : "0000FF",
          isRange: false,
          hAlign: "right",
        });
        cellColorRanges.push({
          val: `G${headerIndex + i + 1}`,
          color: x.mtms[i] < 0 ? "FF0000" : "0000FF",
          isRange: false,
          hAlign: "right",
        });
      }
    });
    companyTitleRange = "B2:G2";
    valanItem = { value: "C4:D4", isRange: true };
    titleRange = "B4:G4";
  }
  dataInfo = {
    cellColorRanges,
    columnStyles,
    greyColorRanges,
    purpleColorRanges,
    yellowColorRanges,
    tBodyRanges,
    cellMergeRanges,
    companyTitleRange,
    valanItem,
    titleRange,
  };
  exportToExcel2({
    apiData: tableData,
    fileName: reportTitle,
    dataInfo,
  });
};
export const generateExcelTrialBalance = ({
  responseData
}) => {
  let tableData = [];
  let dataInfo = {};
  let cellColorRanges = [];
  let greyColorRanges = [];
  let purpleColorRanges = [];
  let yellowColorRanges = [];
  let tBodyRanges = [];
  let cellMergeRanges = [];
  let columnStyles = [];
  let companyTitleRange = undefined;
  let valanItem = undefined;
  let titleRange = undefined;
  const blankRow = { name: "", userName: "", Dr: "", Cr: ""};
    const excelData = [
      blankRow,
      { ...blankRow, name: "Golden Money" },
      blankRow,
      { ...blankRow, name: "Trial Balance"},
      blankRow,
    ];
    const finalData = [];
    const perTableData = [];
    responseData?.forEach((x) => {
      let totalDebitAmount = 0, totalCreditAmount = 0, totalRecords = 0, debitAmount = [], creditAmount = [];
      let scriptTable = [
        { ...blankRow, name: x?.name },
        {
          name: "Client Code",
          // userCode: "Client Code",
          userName: "Particulars",
          Dr: "Debit",
          Cr: "Credit",
        },
      ];
      x?.data?.forEach((y = {}) => {
        // totalNetAmount += y.mtm;
        totalRecords += 1
        totalDebitAmount += y.crDr === "Dr" ? y.balance : 0
        totalCreditAmount += y.crDr === "Cr" ? y.balance : 0
        debitAmount.push(y.crDr === "Dr" ? y.balance : 0)
        creditAmount.push(y.crDr === "Cr" ? y.balance : 0)
        scriptTable.push({
          name: y.userCode,
          userName: y.userName,
          Dr: y.crDr === "Dr" ? y.balance : 0,
          Cr: y.crDr === "Cr" ? y.balance : 0,
        });
      });
      perTableData.push({
        totalItems: x?.data?.length,
        totalDebitAmount,
        totalCreditAmount,
        debitAmount,
        creditAmount
      });
      scriptTable.push({ ...blankRow, name:`Total:${totalRecords}`, Dr:totalDebitAmount, Cr:totalCreditAmount }, blankRow);
      // scriptTable.push({ ...blankRow}, blankRow);
      finalData.push(...scriptTable);
      finalData.push(
        blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow,
      //   blankRow
      );
    });
    excelData.push(...finalData);
    excelData.forEach((x) => {
      tableData.push({
        A: "",
        B: x.name,
        C: x.userName,
        D: x.Dr,
        E: x.Cr,
      });
    });
    let headerIndexes = [];
    tableData.forEach((data, index) => {
      const bData = String(data["B"] || "");
      if (bData === "Client Code") headerIndexes.push(index);
    });
    columnStyles = [
      { column: "A", width: 2 },
      { column: "B", width: 25, hrAlign: "left" },
      { column: "C", width: 15, hrAlign: "left" },
      { column: "D", width: 15, hrAlign: "right" },
      { column: "E", width: 15, hrAlign: "right" },
      // { column: "F", width: 15, hrAlign: "right" },
    ];
    cellMergeRanges = [
      { val: "B2:E2" },
      { val: "B4:B4", align: "left", bold: false },
      { val: "E4:F4", align: "right", bold: false },
    ];
    headerIndexes.forEach((x, i) => {
      const totalItems = perTableData?.[i]?.totalItems;
      const scriptTotalId = x + totalItems + 2;
      greyColorRanges.push(`B${x + 1}:E${x + 1}`);
      purpleColorRanges.push(`B${x}:E${x}`);
      tBodyRanges.push(`B${x}:E${scriptTotalId}`);
      cellMergeRanges.push({
        val: `D${scriptTotalId}:D${scriptTotalId}`,
        align: "right",
      },
      {
        val: `E${scriptTotalId}:E${scriptTotalId}`,
        align: "right",
      });
      yellowColorRanges.push({
      range: `B${scriptTotalId}:C${scriptTotalId}`,
      // fontColor: "FF0000",
      },
      {
        range: `D${scriptTotalId}:D${scriptTotalId}`,
        fontColor: "FF0000",
      },
      {
        range: `E${scriptTotalId}:E${scriptTotalId}`,
        fontColor: "0000FF",
      });
    });
    perTableData.forEach((x, index) => {
      const headerIndex = headerIndexes[index] + 1;
      for (let i = 0; i < x.totalItems; i++) {
        cellColorRanges.push(
          {
            val: `D${headerIndex + i + 1}`,
            color: "FF0000",
            isRange: false,
          },
          {
            val: `E${headerIndex + i + 1}`,
            color: "0000FF",
            isRange: false,
          }
        );
      }
    });
    companyTitleRange = "B2:E2";
    valanItem = { value: "C4:D4", isRange: true };
    titleRange = "B4:E4";
    dataInfo = {
      cellColorRanges,
      cellMergeRanges,
      columnStyles,
      greyColorRanges,
      purpleColorRanges,
      yellowColorRanges,
      tBodyRanges,
      companyTitleRange,
      valanItem,
      titleRange,
    };
    exportToExcel2({
      apiData: tableData,
      fileName: "Trial Balance",
      dataInfo,
    });
}

export const exportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);

  //create a 8 bit integer array
  const view = new Uint8Array(buf);

  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};

const workbook2blob = (workbook) => {
  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(workbook, wopts);

  // The application/octet-stream MIME type is used for unknown binary files.
  // It preserves the file contents, but requires the receiver to determine file type,
  // for example, from the filename extension.
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  return blob;
};

export const exportToExcel2 = ({ apiData = [], fileName = "", dataInfo }) => {
  if (apiData.length === 0) return;
  //create a new workbook
  const wb = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(apiData, {
    skipHeader: true,
  });
  XLSX.utils.book_append_sheet(wb, sheet, fileName.substring(0, 30));
  // binary large object
  // Since blobs can store binary data, they can be used to store images or other multimedia files.
  const workbookBlob = workbook2blob(wb);

  addStyle(workbookBlob, dataInfo, apiData?.length).then((blob) => {
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
  });
};

const addStyle = (workbookBlob, dataInfo = {}, dataLength) => {
  const {
    columnStyles = [],
    greyColorRanges = [],
    purpleColorRanges = [],
    yellowColorRanges = [],
    tBodyRanges = [],
    cellMergeRanges = [],
    cellColorRanges = [],
    titleRange,
    companyTitleRange,
    valanItem,
  } = dataInfo;

  return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
    workbook.sheets().forEach((sheet) => {
      sheet.gridLinesVisible(false);
      sheet.usedRange().style({
        fontFamily: "Arial",
        verticalAlignment: "center",
      });

      for (let i = 1; i <= dataLength; i++) sheet.row(i).height(19);

      columnStyles.forEach(({ column, width, hrAlign } = {}) => {
        if (column) sheet.column(column).width(width);
        if (hrAlign) {
          sheet.column(column).style({
            horizontalAlignment: hrAlign,
          });
        }
      });

      purpleColorRanges.forEach((x) => {
        if (x) {
          sheet.range(x).merged(true).style({
            fill: "CBC3E3",
            verticalAlignment: "center",
            horizontalAlignment: "center",
          });
        }
      });

      greyColorRanges.forEach((x) => {
        if (x) {
          sheet.range(x).style({
            bold: true,
            fill: "808080",
            fontColor: "FFFFFF",
          });
        }
      });

      if (titleRange) {
        sheet.range(titleRange).style({
          topBorder: true,
          bottomBorder: true,
          fontSize: 10,
          verticalAlignment: "center",
        });
      }

      if (companyTitleRange) {
        sheet.range(companyTitleRange).merged(true).style({
          bold: true,
          verticalAlignment: "center",
          horizontalAlignment: "center",
          fontSize: 14,
        });
      }

      if (valanItem) {
        if (valanItem.isRange) {
          sheet.range(valanItem.value).merged(true).style({
            horizontalAlignment: "center",
          });
        } else {
          sheet.cell(valanItem.value).style({
            horizontalAlignment: "center",
          });
        }
      }

      yellowColorRanges.forEach(
        ({ range, merged = true, fontColor = "000000", hAlign = "center" }) => {
          if (range) {
            sheet.range(range).merged(merged).style({
              bold: true,
              fill: "FEFF9E",
              fontColor,
              verticalAlignment: "center",
              horizontalAlignment: hAlign,
            });
          }
        }
      );

      cellMergeRanges.forEach((x) => {
        if (x && x.val)
          sheet
            .range(x.val)
            .merged(true)
            .style({
              bold: x.bold !== undefined ? x.bold : true,
              horizontalAlignment: x.align || "center",
            });
      });

      cellColorRanges.forEach(
        ({
          isRange = true,
          val,
          color: fontColor,
          bold = false,
          hAlign = "center",
        } = {}) => {
          if (isRange)
            sheet
              .range(val)
              .style({ fontColor, bold, horizontalAlignment: hAlign });
          else
            sheet
              .cell(val)
              .style({ fontColor, bold, horizontalAlignment: hAlign });
        }
      );

      tBodyRanges.forEach((x) => {
        if (x) {
          sheet.range(x).style({
            border: true,
            fontSize: 10,
          });
        }
      });
    });

    return workbook.outputAsync().then((workbookBlob) => workbookBlob);
  });
};
export const generateExcelLedgerBalance = ({
  responseData,
  labelName,
  reportTitle,
  fromToLabel,
  accountTypeLabel
}) => {
  let tableData = [];
  let dataInfo = {};
  let cellColorRanges = [];
  let greyColorRanges = [];
  let purpleColorRanges = [];
  let yellowColorRanges = [];
  let tBodyRanges = [];
  let cellMergeRanges = [];
  let columnStyles = [];
  let companyTitleRange = undefined;
  let valanItem = undefined;
  let titleRange = undefined;

  const finalData = [];

  const blankRow = [];
  // Push headers
  const titleRow = ["Golden Money"];
  const subtitleRow = ["Ledger Balance","","","","","","",fromToLabel];
  const user_type=[accountTypeLabel]
  const headerRow = [
    "Date", "Sr. No.", "Particulars", "Debit", "Credit", "Balance", "Dr/Cr", "Description"
  ];
  finalData.push(blankRow);
  finalData.push(titleRow);
  finalData.push(blankRow);
  finalData.push(subtitleRow);
  finalData.push(blankRow);
  finalData.push(user_type);
  finalData.push(headerRow);
  let totalDebit = 0;
  let totalCredit = 0;
  responseData.forEach(item => {
    const row = [
      moment(item.date).format('DD-MM-YYYY'), // Format the date to local format
      item.srNo,
      item.particulars,
      item.debitAmount,
      item.creditAmount,
      item.balance,
      item.crDr,
      item.document
    ];
    finalData.push(row);
    totalDebit += item.debitAmount;
    totalCredit += item.creditAmount;
  });
  finalData.push([
    "Total",               
    "",                     
    "",                    
    totalDebit.toFixed(2),   
    totalCredit.toFixed(2), 
    "",                     
    "",                    
    ""             
  ]);
  columnStyles = [
      { column: "A", width: 10 },
      { column: "B", width: 10, hrAlign: "left" },
      { column: "C", width: 20, hrAlign: "left" },
      { column: "D", width: 10, hrAlign: "right" },
      { column: "E", width: 10, hrAlign: "right" },
      { column: "F", width: 10, hrAlign: "right" },
      { column: "G", width: 10, hrAlign: "right" },
      { column: "H", width: 40, hrAlign: "left" },
  ];
  cellMergeRanges.push({ val: "A2:H2", align: "center", bold: true });
  cellMergeRanges = [
     { val: "A2:H2", align: "center", bold: true },
     { val: "A4:G4", align: "left", bold: false },
     { val: "A6:H6", align: "center", bold: true }
    ];
  purpleColorRanges.push(`A6:H6`);
  greyColorRanges.push(`A7:H7`);
  yellowColorRanges.push({range:`A${finalData.length}:C${finalData.length}`});
  yellowColorRanges.push({range:`D${finalData.length}:D${finalData.length}`});
  yellowColorRanges.push({range:`E${finalData.length}:E${finalData.length}`});
  yellowColorRanges.push({range:`F${finalData.length}:H${finalData.length}`});
  tBodyRanges.push(`A6:H${finalData.length}`);
  dataInfo = {
      cellColorRanges,
      cellMergeRanges,
      columnStyles,
      greyColorRanges,
      purpleColorRanges,
      yellowColorRanges,
      tBodyRanges,
      companyTitleRange,
      valanItem,
      titleRange,
    };
  exportToExcel2({
    apiData: finalData,
    fileName: reportTitle,
    dataInfo,
  });
}
