import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import "../../assets/scss/mobile/newStyleMobile.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSummayList } from "store/reducers/home.slice";
import { Button, Input, Modal, Spinner } from "reactstrap";
import { getSegmentOptionList, getSymbolList, showMessage } from "store/reducers/common.slice";
import { getValansList, setValansList } from "store/reducers/valan.slice";
import {
  setPositionReportSearchText,
  setStartPagePositionReport,
  setClosePositonSelected,
  getPositionReportList,
  setPositionCloseConfirmDialog,
  setPositionReportFilterValue,
  setIsClosePosition,
  setPositionReportDetail,
  rollOverPosition,
  saveAllClosePosition,
  setPositionCloseData,
  closePosition
} from "store/reducers/positionReport.slice";
import { getSessionData } from "utils/common";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { scriptWithDot, scriptWithDotAccept, scriptWithoutDot } from "configs/routesConfig";
import infoIcon from '../../assets/img/icons/info.svg'
import { setPositionReportList } from "store/reducers/positionReport.slice";

const IndeterminateCheckbox = React.forwardRef(
  ({ id, indeterminate, value, row, checkBoxHandler, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <div className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            type="checkbox"
            id={`checbox_${id}`}
            ref={resolvedRef}
            checked={value}
            {...rest}
            onClick={() => checkBoxHandler(row)}
            readOnly
          />
          <label
            className="custom-control-label"
            htmlFor={`checbox_${id}`}
          ></label>
        </div>
      </>
    );
  }
);

export default function MobileNewPositionReport() {
  const refDeleteButton = useRef(null);
  const dispatch = useDispatch()
  const history = useHistory()
  const { summaryList, summaryLoading } = useSelector(({ home }) => home);
  const {
    positionReportLoading,
    positionReportFilterValue,
    positionReportList = new Map(),
    isPositionGet,
    closePositonSelected,
    isClosePosition,
    positionCloseConfirmDialog,
    positionCloseData,
    isPositionCloseLoading
  } = useSelector(({ positionReport }) => positionReport);
  const { segmentsList } = useSelector(({ common }) => common);
  let localData = getSessionData();

  let userId,
    userName = "";
  if (localData && Object.keys(localData).length > 0) {
    userId = Object.keys(localData).length > 0 && localData.userId;
    userName = Object.keys(localData).length > 0 && localData.userName;
  }
  const initialValues = {
    clientcode: { label: userName, value: userId },
    crtype: "net",
    disptype: "all",
    exdate: new Date(),
    reportfrom: "positionreport",
    reporttype: "client",
    subbrokercode: "",
    segment: "",
    valan: "",
    symbol: "",
    searchText: "",
    pageNo: 1,
    pageSize: 10000,
    appendData: false,
  };
  const initialStateClosePositon = {
    marketType: "",
    lot: 0,
    qty: 0,
    price: 0,
    segment: "",
  };
  const [closePositionInitialState, setClosePositionInitialState] = useState(
    initialStateClosePositon
  );
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [initialGetPosition, setInitialGetPosition] = useState(initialValues);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [segmentListData, setSegmentListData] = useState([]);
  const [positionReportListData, setPositionReportListData] = useState([]);
  const [oneQtySize, setOneQtySize] = useState(1);

  const { valansList } = useSelector(({ valan }) => valan);

  const isCheckBoxChecked = useMemo(() => {
    let isCheckBoxShow = false;
    _.map([...positionReportListData?.values()], (item) => {
      if (item.netQty !== 0 && item.isCheck) {
        isCheckBoxShow = true;
      }
    });
    return isCheckBoxShow;
  }, [positionReportListData]);

  const totalValue = useMemo(
    () => {
      const positionReportData = [...positionReportList?.values()] || []
      let totalBuyQty = parseInt(positionReportData.reduce((sum, row) => sum + (row.netQty < 0 ? row.netQty : 0), 0))
      let totalSellQty = parseInt(positionReportData.reduce((sum, row) => sum + (row.netQty > 0 ? row.netQty : 0), 0))
      let totalNetQty = parseInt(positionReportData.reduce((sum, row) => sum + row.netQty, 0))
      let totalMTM = positionReportData.reduce((sum, row) => sum + row.mtm, 0)
      return { totalBuyQty, totalSellQty, totalMTM, totalNetQty }
    },
    [positionReportList]
  );
  useEffect(() => {
    if (positionCloseData && positionCloseConfirmDialog) {
      setOneQtySize(Math.abs(positionCloseData.qty));
      setClosePositionInitialState((prev) => ({
        ...prev,
        lot:
          Math.abs(positionCloseData.netLot),
        netQty: Math.abs(positionCloseData.netQty),
        qty: Math.abs(positionCloseData.netQty),
        price: positionCloseData.ltp,
        segment: positionCloseData.segment,
        marketType: positionCloseData.marketType,
      }));
    }
  }, [positionCloseData, positionCloseConfirmDialog]);

  useEffect(() => {
    dispatch(getSegmentOptionList());
    dispatch(getValansList());
    dispatch(getSymbolList({ id: 2 }));
    return () => {
      dispatch(setPositionReportSearchText(""));
      dispatch(setStartPagePositionReport(1));
      dispatch(setClosePositonSelected([]));
      dispatch(setPositionReportList([]));
      dispatch(setValansList([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isClosePosition) {
      dispatch(getPositionReportList(initialGetPosition));
      dispatch(setPositionCloseConfirmDialog(false));
      dispatch(setIsClosePosition(false));
      dispatch(setClosePositonSelected([]));
    }
  }, [dispatch, initialGetPosition, isClosePosition]);

  useEffect(() => {
    if (positionReportList && positionReportList?.size > 0) {
      setPositionReportListData(positionReportList);
    } else {
      setPositionReportListData([]);
    }
  }, [positionReportList]);

  useEffect(() => {
    if (segmentsList && segmentsList.length > 0) {
      setSegmentListData([{ label: "DEFAULT", value: "" }, ...segmentsList]);
      setInitialGetPosition({
        ...initialGetPosition,
        segment: { label: "DEFAULT", value: "" },
      });
    }
  }, [segmentsList]);

  useEffect(() => {
    if (isInitialRender && valansList && valansList.length > 0) {
      let finalValanList = valansList
        ?.filter((item2) => {
          if (item2.status === 1) {
            return item2;
          }
        })
        .map((item2) => {
          return { label: item2.name, value: item2 };
        });
      if (isPositionGet) {
        dispatch(
          setPositionReportFilterValue({
            ...positionReportFilterValue,
            valan: finalValanList[0],
          })
        );
      } else {
        dispatch(
          setPositionReportFilterValue({
            ...initialValues,
            valan: finalValanList[0],
          })
        );
      }
      setInitialGetPosition({
        ...initialGetPosition,
        valan: finalValanList[0],
      });
      dispatch(
        getPositionReportList({
          ...initialGetPosition,
          valan: finalValanList[0],
        })
      );
      setIsInitialRender(false);
    }
  }, [dispatch, valansList, isInitialRender]);

  const positionReportValidationSchema = Yup.object().shape({
    lot: Yup.number().max(Math.abs(positionCloseData.netLot), `Lot maximum value only ${Math.abs(positionCloseData.netLot)}`).required("*Lot is required field"),
    qty: Yup.number().min(1, 'Qty minimum value 1 required.').max(Math.abs(positionCloseData.netQty), `Qty maximum value only ${Math.abs(positionCloseData.netQty)}`).required("*Qty is required field"),
    price: Yup.number().required("*Price is required field"),
  });

  const summaryViewHandler = useCallback(
    () => {
      dispatch(
        getSummayList({
          pageNo: 1,
          pageSize: 10,
          searchText: "",
        })
      );
      setOpenSummaryModal(true);
    },
    [dispatch],
  )
  const checkBoxHandler = useCallback(
    (row) => {
      const newPositionReportList = new Map(positionReportListData);
      newPositionReportList.set(row.rowId, { ...row, isCheck: !row.isCheck });
      const selectPositionReport = _.filter(
        [...newPositionReportList.values() || []],
        (item) => {
          if (item.isCheck) {
            return item;
          }
        }
      );
      dispatch(setClosePositonSelected(selectPositionReport));
      setPositionReportListData(newPositionReportList)
    },
    [dispatch, positionReportListData]
  );
  const allCheckBoxHandler = useCallback(
    (e) => {
      let newFilteredPositionReportList = [
        ...positionReportListData?.values(),
      ].map((item) => {
        if (item.netQty !== 0) {
          return { ...item, isCheck: e.target.checked };
        }
        return item;
      });
      let map = new Map();
      newFilteredPositionReportList.forEach((x) => map.set(x?.rowId, x));
      const selectPositionReport = _.filter(
        newFilteredPositionReportList,
        (item) => {
          if (item.isCheck) {
            return item;
          }
        }
      );
      dispatch(dispatch(setClosePositonSelected(selectPositionReport)));
      setPositionReportListData(map);
    },
    [dispatch, positionReportListData]
  );
  const handleClickClosePostion = useCallback(
    (position) => {
      if (position?.netQty !== 0 && initialGetPosition.crtype == "net") {
        dispatch(setPositionCloseConfirmDialog(true));
        dispatch(setPositionCloseData({ ...position, marketType: "market" }));
      }
    },
    [dispatch, initialGetPosition],
  )

  return (
    <div className="mobileapp_main_wrap">
      <header>
        <div className="position_header_wrap">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-white fw_bold">Positions</h2>
            <div className="positon_header_icon_wrap">
              <span
                className="material-symbols-outlined up_down_icon text-white mr-2 d-inline-block"
                onClick={summaryViewHandler}
              >
                swap_vert
              </span>
              <span className="material-symbols-outlined plus_icon text-white"
                onClick={() => setOpenReportModal(true)}
              >
                note_add
              </span>
            </div>
          </div>
          <div className="position_quentity_wrap">
            <ul>
              <li>S.qty : {totalValue?.totalBuyQty?.toFixed(2)}</li>
              <li>B.qty : {totalValue?.totalSellQty?.toFixed(2)}</li>
              <li>N.qty : {totalValue?.totalNetQty?.toFixed(2)}</li>
            </ul>
          </div>
          <div className="position_total_wrap">
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li>
                  <button className="btn_orange_border w-100"
                    disabled={closePositonSelected?.length > 0 ? false : true}
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: `Are you sure rollover position?`,
                        icon: "question",
                        showCancelButton: true,
                        width: 400,
                        height: 400,
                        confirmButtonColor: "#885b24",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Rollover it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          dispatch(rollOverPosition());
                        }
                      })
                    }>RollOver</button>
                </li>
                <li>
                  <button className="btn_orange_border w-100"
                    disabled={closePositonSelected?.length > 0 ? false : true}
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: `Are you sure close ${closePositonSelected.length} position?`,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#885b24",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Close it!",
                        width: 400,
                        height: 400,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await dispatch(saveAllClosePosition(closePositonSelected));
                        }
                      });
                    }}
                  >Close All</button>
                </li>
              </ul>
              <h6 className="pl-2 text-white m-0 total_mtm">
                Total:<span className={totalValue.totalMTM > 0 ? "text_green pl-1" : totalValue.totalMTM < 0 ? "text_red pl-1" : ""}
                >{totalValue?.totalMTM?.toFixed(2)}</span>
              </h6>
            </div>
          </div>
        </div>
        <div className="position_radio_wrap">
          <div className="radio-buttons_wrap d-flex justify-content-between align-items-center">
            <ul>
              <li>
                <input
                  className="custom-control-input"
                  id="all"
                  name="disptype"
                  type="radio"
                  checked={initialGetPosition.disptype == "all"}
                  value="all"
                  onChange={(e) => {
                    setInitialGetPosition({
                      ...initialGetPosition,
                      disptype: "all",
                    });
                    dispatch(
                      getPositionReportList({
                        ...initialGetPosition,
                        disptype: "all",
                      })
                    );
                  }}
                />
                <label htmlFor="all">ALL</label>
              </li>
              <li>
                <input
                  className="custom-control-input"
                  id="outstanding"
                  name="disptype"
                  type="radio"
                  value="outstanding"
                  checked={initialGetPosition.disptype == "outstanding"}
                  onChange={(e) => {
                    setInitialGetPosition({
                      ...initialGetPosition,
                      disptype: "outstanding",
                    });
                    dispatch(
                      getPositionReportList({
                        ...initialGetPosition,
                        disptype: "outstanding",
                      })
                    );
                  }}
                />
                <label htmlFor="outstanding">OUTSTANDING</label>
              </li>
            </ul>
            <button type="button" className="btn_orange small" onClick={() => {
              dispatch(
                getPositionReportList({
                  ...positionReportFilterValue
                })
              );
            }}>
              Refresh
            </button>
          </div>
        </div>
        <div className="morder_tab_wrap position_tab_wrap">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item w-50 text-center">
              <button
                className={
                  initialGetPosition.crtype == "net"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="pills-Pending-tab"
                data-toggle="pill"
                data-target="#pills-Pending"
                type="button"
                role="tab"
                aria-controls="pills-Pending"
                aria-selected="true"
                value="net"
                checked={initialGetPosition.crtype == "net"}
                onClick={(e) => {
                  if (initialGetPosition.crtype !== "net") {
                    setInitialGetPosition({
                      ...initialGetPosition,
                      crtype: "net",
                    });
                    dispatch(
                      getPositionReportList({
                        ...initialGetPosition,
                        crtype: "net",
                      })
                    );
                  }
                }}
              >
                Net Wise
              </button>
            </li>
            <li className="nav-item w-50 text-center">
              <button
                className={
                  initialGetPosition.crtype == "day"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="pills-Completed-tab"
                data-toggle="pill"
                data-target="#pills-Completed"
                type="button"
                role="tab"
                aria-controls="pills-Completed"
                aria-selected="false"
                value="day"
                checked={initialGetPosition.crtype == "day"}
                onClick={(e) => {
                  if (initialGetPosition.crtype !== "day") {
                    setInitialGetPosition({
                      ...initialGetPosition,
                      crtype: "day",
                    });
                    dispatch(
                      getPositionReportList({
                        ...initialGetPosition,
                        crtype: "day",
                      })
                    );
                  }
                }}
              >
                Day Wise
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="orderPending"
              role="tabpanel"
              aria-labelledby="orderPending-tab"
            >
              <div className="morder_tab_top position_tab_top chackLebleInput">
                <ul>
                  {segmentListData.map((segment) => {
                    return (
                      <li
                        className={
                          initialGetPosition.segment?.label === segment.label &&
                          "active"
                        }
                        onClick={(e) => {
                          if (
                            initialGetPosition.segment?.label !== segment.label
                          ) {
                            setInitialGetPosition({
                              ...initialGetPosition,
                              segment: segment,
                            });
                            dispatch(
                              getPositionReportList({
                                ...initialGetPosition,
                                segment: segment,
                              })
                            );
                          }
                        }}
                      >{initialGetPosition.segment?.label === segment.label && initialGetPosition.crtype == "net" &&
                        <input
                          className="styled-checkbox"
                          id="styled-checkbox-1"
                          type="checkbox"
                          value={isCheckBoxChecked}
                          onClick={(e) => allCheckBoxHandler(e)}
                          checked={
                            [...positionReportListData.values()].length > 0 && [...positionReportListData.values()].filter((a) => a.netQty !== 0).length > 0
                              ? [...positionReportListData.values()]
                                .filter((a) => a.netQty !== 0)
                                .every((a) => a.isCheck === true)
                              : false
                          }
                        />}
                        <label htmlFor="styled-checkbox-1"></label>
                        <span>{segment.label}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="position_main_wrap">
          {[...positionReportListData?.values()].map((position, index) => {
            return (
              <div className="marketview_row" >
                <div className="row justify-content-between g-1" onClick={() => handleClickClosePostion(position)}>
                  <div className="col-6">
                    {position?.netQty > 0 ? <div className="d-flex pl-4"><span className="bedge bedge_blue">BUY</span></div> :
                      position?.netQty < 0 && <div className="d-flex pl-4"><span className="bedge bedge_red">SELL</span></div>
                    }
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-end align-items-center">
                      <span className="text_small text_blue">
                        LTP.<span className="fw_bold px-2">{position?.ltp}</span>
                      </span>

                      <img className="info_img" src={infoIcon} onClick={() => {
                        dispatch(
                          setPositionReportDetail({
                            ...position,
                            startDate:
                              initialGetPosition.valan?.value.startDate,
                            endDate:
                              initialGetPosition.valan?.value.endDate,
                          })
                        );
                        history.push("/app/position-detail");
                      }} alt="pdficon" />
                    </div>
                  </div>
                  <div className="col-6">
                    <span className="text_mediam text_blue fw_bold">
                      {position?.script}
                    </span>
                  </div>
                  <div className="col-6">
                    <p className="text_small text-right">
                      MTM.<span className="text_red fw_bold pl-1">{position?.mtm}</span>
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="text_grey">
                      Avg.<span className="fw_bold pl-1 text_blue">{position?.netQty > 0 ? position?.buyAvgPrice :
                        position?.netQty < 0 ? position?.sellAvgPrice : 0
                      }</span>
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="text_grey text-right">
                      Net Qty.<span className="fw_bold pl-1 text_blue">{position?.netQty}</span>
                    </p>
                  </div>
                </div>
                <div className="marketview_checkbox">
                  {position?.netQty !== 0 && initialGetPosition.crtype == "net" &&
                    <IndeterminateCheckbox
                      id={index}
                      value={position.isCheck}
                      row={position}
                      checkBoxHandler={checkBoxHandler}
                    />
                  }
                </div>
              </div>
            )
          })
          }
          {!positionReportLoading && positionReportListData?.length === 0 && <h4 className="d-flex justify-content-center text-danger mt-2">
            Data Not Found
          </h4>}
        </div>
      </main>
      <Modal
        className="modal-dialog-centered filter_modal"
        centered
        style={{ zIndex: 999999 }}
        isOpen={openReportModal}
        toggle={() => setOpenReportModal(false)}
      >
        <div className="ModalBody">
          <h4 className="d-flex align-items-center font-weight-700">
            <span className="material-symbols-outlined plus_icon mr-1 font-weight-700"
              onClick={() => setOpenReportModal(true)}
            >
              note_add
            </span>REPORTS</h4>
          <Link to="/app/trade-report" className="m-2 d-inline-block text-dark">
            Trade Report
          </Link>
          <br />
          <Link to="/app/ledger-report" className="m-2 d-inline-block text-dark">
            Ledger Report
          </Link>
          <div className="close_btn" onClick={() => setOpenReportModal(false)}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered filter_modal"
        centered
        style={{ zIndex: 999999 }}
        isOpen={summaryList?.length > 0 && openSummaryModal}
        toggle={() => setOpenSummaryModal(false)}
      >
        <div className="ModalBody">
          {summaryList?.map((item) => {
            return (
              <ul className="position_summary">
                <li>
                  Margin
                  <span>{item.margin?.toFixed(2)}</span>
                </li>
                <li>
                  Used Margin
                  <span>{item.usedMargin?.toFixed(2)}</span>
                </li>
                <li>
                  Balance Margin
                  <span>{item.balanceMargin?.toFixed(2)}</span>
                </li>
                <li>
                  Fatak Margin
                  <span>{item.ledgerBalance?.toFixed(2)}</span>
                </li>
                <li>
                  Live M2M
                  <span>{item.liveM2M?.toFixed(2)}</span>
                </li>
              </ul>
            );
          })}
          <div className="close_btn" onClick={() => setOpenSummaryModal(false)}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered filter_modal order_model "
        centered
        style={{ zIndex: 999999 }}
        isOpen={positionCloseConfirmDialog}
        toggle={() =>
          dispatch(setPositionCloseConfirmDialog(!positionCloseConfirmDialog))
        }
      >
        <Formik
          enableReinitialize={true}
          initialValues={closePositionInitialState}
          validationSchema={positionReportValidationSchema}
          innerRef={refDeleteButton}
          onSubmit={(valuesData) => {
            let isSubmit = true;
            let price = parseFloat(valuesData.price);
            let priceMinLimit = 0;
            let priceMaxLimit = 0;
            if (positionCloseData.segmentId !== 3) {
              priceMinLimit = price - (price * 10) / 100;
              priceMaxLimit = price + (price * 10) / 100;
            } else {
              priceMinLimit = price - (price * 150) / 100;
              priceMaxLimit = price + (price * 150) / 100;
              if (priceMinLimit < 0) {
                priceMinLimit = 0;
              }
            }
            if (
              priceMinLimit > positionCloseData.ltp ||
              priceMaxLimit < positionCloseData.ltp
            ) {
              dispatch(
                showMessage({
                  message: "Limit oder must be placed within circuit levels",
                })
              );
              isSubmit = false;
            } else if (
              positionCloseData.segmentId === 1 ||
              positionCloseData.segmentId === 3 ||
              // positionCloseData.segmentId === 4 ||
              (positionCloseData.segmentId === 2 &&
                scriptWithDot.includes(positionCloseData.symbol))
            ) {
              let decimals = valuesData.price.toString().split(".");
              decimals[1] = decimals[1]
                ? decimals[1].length === 1
                  ? decimals[1] + "0"
                  : decimals[1]
                : "00";
               if (!scriptWithDotAccept.includes(decimals[1]) && (positionCloseData.segmentId === 1 && valuesData.price>=250)) {
                dispatch(
                  showMessage({
                    message: "Ticksize must be : 0.05",
                  })
                );
                isSubmit = false;
              }
            }
            if (isSubmit) {
              Swal.fire({
                title: "Are you sure?",
                text: `Lot : ${valuesData.lot}, Qty: ${valuesData.qty
                  }, Price : ${valuesData.price}`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#885b24",
                width: 400,
                height: 400,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Close it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(
                    closePosition({
                      ...positionCloseData,
                      ...valuesData,
                      oneQtySize,
                      oldNetQty: positionCloseData.netQty,
                    })
                  );
                }
              });
            }
          }}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            getFieldProps,
            setFieldValue
          }) => {
            return (
              <>
                <Form onSubmit={handleSubmit}>
                  <div className="modal-header blueHeader">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Close Position
                      <div className="position_radio_wrap modal_radio_wrapper">
                        <div className="radio-buttons_wrap d-flex justify-content-between align-items-center">
                          <ul>
                            <li>
                              <input
                                className="custom-control-input"
                                id="marketRadio1"
                                name="marketType"
                                type="radio"
                                checked={values.marketType === "market"}
                                onChange={(e) => {
                                  handleChange("marketType")("market");
                                  const ltpPrice = positionCloseData.ltp;
                                  handleChange("price")(ltpPrice.toString());
                                  dispatch(
                                    setPositionCloseData({
                                      ...positionCloseData,
                                      netLot: values.lot,
                                      netQty: values.lot,
                                      marketType: "market",
                                    })
                                  );
                                }}
                              />
                              <label htmlFor="marketRadio1">MARKET</label>
                            </li>
                            <li>
                              <input
                                className="custom-control-input"
                                id="marketRadio2"
                                name="marketType"
                                type="radio"
                                checked={values.marketType === "limit"}
                                onChange={(e) => {
                                  handleChange("marketType")("limit");
                                  dispatch(
                                    setPositionCloseData({
                                      ...positionCloseData,
                                      marketType: "limit",
                                    })
                                  );
                                }}
                              />
                              <label htmlFor="marketRadio2">LIMIT/STOP</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </h5>
                    <button className="close" type="button" onClick={() => dispatch(setPositionCloseConfirmDialog(!positionCloseConfirmDialog))}>
                      <i className="material-icons-outlined">close</i>
                    </button>
                  </div>
                  <div className="ModalBody p-3">
                    <div className='order_top_box p-2 mb-3'>
                      <h5><span>Client Name: </span>{positionCloseData?.clientName}</h5>
                      <h5 className="m-0"><span>Script Name: </span>{positionCloseData?.script}</h5>
                    </div>
                    <ul className='mb-3 row'>
                      <li className='col-6 py-0 px-2 mb-3'>
                        <div className="deletOrderInput">
                          <label className="form-control-label">
                            Lot:
                          </label>
                          <Input
                            type="number"
                            className="form-control pl-5"
                            onKeyPress={(event) => {
                              if (
                                event.key === "-" ||
                                event.key === "+" ||
                                event.key === "*" ||
                                event.key === "/"
                              ) {
                                event.preventDefault();
                              } else if (
                                event.key === "." &&
                                (positionCloseData.segmentId === 2 || positionCloseData.segmentId === 3 || positionCloseData.segmentId === 4)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            {...getFieldProps("lot")}
                            onBlur={() => {
                              if (positionCloseData.segmentId !== 2 || positionCloseData.segmentId !== 3 || positionCloseData.segmentId !== 4) {
                                setFieldValue("lot", values.lot.slice(0, (values.lot.indexOf(".") !== -1 ? (values.lot.indexOf(".") + 3) : values.lot.length)));
                                setFieldValue("qty", Math.round(
                                  Number(values.lot.slice(0, (values.lot.indexOf(".") !== -1 ? (values.lot.indexOf(".") + 3) : values.lot.length))) * positionCloseData.qty
                                ));
                              }
                            }}
                            onChange={(e) => {
                              const newQty = Math.round(
                                Number(e.target.value) * positionCloseData.qty
                              );
                              handleChange('qty')(newQty.toString())
                              handleChange('lot')(e.target.value)
                            }}
                          />
                          <span className="text-error text_small">
                            <ErrorMessage name="lot" />
                          </span>
                        </div>
                      </li>
                      <li className='col-6 py-0 px-2 mb-3'>
                        <div className="deletOrderInput">
                          <label className="form-control-label">
                            Qty:
                          </label>
                          <Input
                            type="number"
                            className="form-control pl-5"
                            placeholder="Qty"
                            onKeyDown={(event) => {
                              if (
                                event.key === "." ||
                                event.key === "-" ||
                                event.key === "+" ||
                                event.key === "*"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            disabled={positionCloseData.segmentId === 2 || positionCloseData.segmentId === 3 || positionCloseData.segmentId === 4}
                            min={1}
                            value={values.qty}
                            onBlur={() => {
                              handleBlur({ target: { name: "qty" } });
                            }}
                            onChange={(e) => {
                              if (positionCloseData.segmentId === 2 || positionCloseData.segmentId === 3 || positionCloseData.segmentId === 6) {
                                handleChange("qty")(e.target.value);
                              } else {
                                const newLot = (Number(e.target.value) / positionCloseData.qty).toString();
                                handleChange("qty")(e.target.value);
                                handleChange("lot")(newLot.slice(0, (newLot.indexOf(".") !== -1 ? (newLot.indexOf(".") + 3) : newLot.length)));
                              }
                            }}
                          />
                        </div>
                        <span className="text-error text_small">
                          <ErrorMessage name="qty" />
                        </span>
                      </li>
                      <li className='col-12 py-0 px-2'>
                        <div className="deletOrderInput">
                          <label className=''>Price: </label>
                          <Input
                            type="number"
                            onKeyDown={(event) => {
                              if (
                                (scriptWithoutDot.includes(
                                  positionCloseData.symbol
                                ) &&
                                  event.key === ".") ||
                                event.key === "-" ||
                                event.key === "+" ||
                                event.key === "*"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              values.marketType && values.marketType === "market"
                            }
                            {...getFieldProps("price")}
                          />
                        </div>
                        <span className="text-error text_small">
                          <ErrorMessage name="Price" />
                        </span>
                      </li>
                    </ul>
                    <Button className='btn_orange w-100'>Submit</Button>
                  </div>
                </Form >
              </>)
          }}
        </Formik>
      </Modal>
      {(positionReportLoading || summaryLoading || isPositionCloseLoading) && <div className="spinner-box">
        <Spinner className="ml-2" color="primary" />
      </div>}
    </div>
  );
}
